import React, { useState } from 'react';

import ZipCodeValue from '../../../../../components/ZipCodeValue';
import { useAuth } from '../../../../../contexts/Auth';
import ZipCodeDialog from '../../Dialogs/ZipCodeDialog';
import LabelValue from '../../LabelValue';

function ZipCodeField() {
  const {
    user: {
      selectedUserStore: {
        store: {
          address: { zipCode },
        },
      },
    },
  } = useAuth();

  const [editValue, setEditValue] = useState(false);

  return (
    <>
      <LabelValue label="CEP" editable onEdit={() => setEditValue(true)}>
        <ZipCodeValue>{zipCode}</ZipCodeValue>
      </LabelValue>
      <ZipCodeDialog
        onConfirm={(phone) => {
          setEditValue(false);
        }}
        open={editValue}
        onCancel={() => setEditValue(false)}
      ></ZipCodeDialog>
    </>
  );
}

export default ZipCodeField;

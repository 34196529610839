import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { Form } from '@unform/web';

import DialogContainer from '../../../../../components/DialogContainer';
import Input from '../../../../../components/Forms/Input';
import { useAuth } from '../../../../../contexts/Auth';
import { useToast } from '../../../../../contexts/toast';
import useFormValidation from '../../../../../hooks/useFormValidation';
import { updateStore } from '../../../../../services/Store';
import Yup from '../../../../../validators/Yup';

interface TextFieldDialogProps {
  open: boolean;
  onCancel(): void;
  onConfirm(data: any): void;
  successMessage: string;
  title: string;
  input: {
    name: string;
    label: string;
  };
  previousValue?: string;
  validationSchema: Yup.ObjectSchema;
  payloadHandler?(data: any): any;
  children?: ReactNode;
}

function TextFieldDialog({
  onConfirm,
  successMessage,
  previousValue,
  title,
  input,
  validationSchema,
  children,
  payloadHandler,
  ...rest
}: TextFieldDialogProps) {
  const {
    user: {
      selectedUserStore: { store },
    },
    updateSelectedStore,
  } = useAuth();

  const { showError, showSuccess } = useToast();
  const {
    formRef,
    handleBlur,
    validateForm,
    handleValidationErrors,
  } = useFormValidation(validationSchema);

  const [updating, setUpdating] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(previousValue);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setUpdating(true);
        setDirty(false);

        await validateForm(data, { abortEarly: false });

        const payload = payloadHandler ? payloadHandler(data) : data;

        const updatedStore = await updateStore(store.id, payload);

        updateSelectedStore(updatedStore);

        onConfirm(data);
        showSuccess(successMessage);
      } catch (err) {
        setDirty(true);
        handleValidationErrors(err);
        if (err.response) {
          showError(err.response.data.message);
        }
      } finally {
        setUpdating(false);
      }
    },
    [
      handleValidationErrors,
      onConfirm,
      payloadHandler,
      showError,
      showSuccess,
      store.id,
      successMessage,
      updateSelectedStore,
      validateForm,
    ],
  );

  useEffect(() => {
    setDirty(previousValue !== updatedValue);
  }, [previousValue, updatedValue]);

  return (
    <DialogContainer
      {...rest}
      onConfirm={() => formRef.current?.submitForm()}
      disabledConfirmBtn={!dirty}
      isLoading={updating}
      title={title}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          defaultValue={updatedValue}
          name={input.name}
          type="text"
          value={updatedValue}
          label={input.label}
          onBlur={() => handleBlur(input.name)}
          onChange={(e) => {
            setUpdatedValue(e.target.value);
          }}
        />
      </Form>
      <div>{children}</div>
    </DialogContainer>
  );
}

export default TextFieldDialog;

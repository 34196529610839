import React from 'react';

import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import { MdEdit } from 'react-icons/md';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      backgroundColor: '#F5F5F5',
      borderRadius: '6px',
      display: 'flex',
      padding: `${theme.spacing(2)}px`,
      marginBottom: `${theme.spacing(2)}px`,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fieldCard: {
      display: 'flex',
      flexDirection: 'column',
    },
    fieldLabel: {
      fontSize: '16px',
      color: '#757575',
      textAlign: 'left',
      letterSpacing: 0,
      fontWeight: 400,
    },
    fieldValue: {
      fontSize: '20px',
      color: '#212121',
      textAlign: 'left',
      fontWeight: 500,
    },
  }),
);

interface LabelValueProps {
  label: string;
  children: string | React.ReactNode;
  editable?: boolean;
  onEdit?(): void;
}

function LabelValue({
  label,
  children,
  editable = false,
  onEdit,
}: LabelValueProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.fieldCard}>
        <span className={classes.fieldLabel}>{label}</span>
        <span className={classes.fieldValue}>{children}</span>
      </div>
      {editable && (
        <IconButton onClick={onEdit}>
          <MdEdit size={24} />
        </IconButton>
      )}
    </div>
  );
}
export default LabelValue;

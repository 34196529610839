import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customIconButton: {
      borderRadius: 1,
    },
    customBadge: {
      top: 5,
      right: 3
    },
    root: {
      width: '100%',
      maxWidth: '360ch',
      minWidth: '360px',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
    },
    inline: {
      display: 'inline',
    },
    paper: {
      marginTop: 12,
      marginLeft: 4,
      overflow: 'inherit',
      boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 20px 40px -4px rgb(145 158 171 / 24%)',
      border: 'solid 1px rgba(145, 158, 171, 0.08)'
    },
    arrow: {
      [theme.breakpoints.up('sm')]: {
        top: -6,
        zIndex: 1,
        width: 12,
        right: 28,
        height: 12,
        content: "''",
        position: 'absolute',
        borderRadius: '0 0 4px 0',
        transform: 'rotate(-135deg)',
        background: theme.palette.background.paper,
        borderRight: 'solid 1px rgba(145, 158, 171, 0.12)',
        borderBottom: 'solid 1px rgba(145, 158, 171, 0.12)'
      }
    }
  }),
);
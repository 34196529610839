import { SnackbarProvider } from 'notistack';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { ThemeProvider } from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider, IconButton } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AuthProvider } from './contexts/Auth';
import { ConfirmDialogProvider } from './contexts/confirm-dialog';
import { LoadingProvider } from './contexts/loading';
import { SignUpStoreProvider } from './contexts/store-signup';
import Routes from './routes';
import theme from './theme';
import { MdClose } from 'react-icons/md';
import { ToastProvider } from './contexts/toast';

function App() {
  const notistackRef = React.createRef<any>();

  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <AuthProvider>
            <ConfirmDialogProvider>
              <SignUpStoreProvider>
                <StylesProvider injectFirst>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <LoadingProvider>
                      <SnackbarProvider
                        maxSnack={5}
                        transitionDuration={400}
                        autoHideDuration={3000}
                        ref={notistackRef}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        action={(key: any) => (
                          <IconButton
                            aria-label="delete"
                            onClick={onClickDismiss(key)}
                          >
                            <MdClose size={24} color="#FFF" />
                          </IconButton>
                        )}
                      >
                        <ToastProvider>
                          <Routes />
                        </ToastProvider>
                      </SnackbarProvider>
                    </LoadingProvider>
                  </MuiPickersUtilsProvider>
                </StylesProvider>
              </SignUpStoreProvider>
            </ConfirmDialogProvider>
          </AuthProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;

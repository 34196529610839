import { PageableApiResponse } from '../../types/api-response';
import {
  Charity,
  CharityDonation,
  CharityPaginatedResponse,
} from '../../types/charity';
import { File } from '../../types/file';
import { IAvailableDate } from '../../types/transactions';
import { FileTypeEnum } from '../../types/user';
import api from '../api';

export interface bankType {
  id?: number;
  code?: string;
  description?: string;
}

export const getCharities = async (
  page?: number,
  limit?: number,
  name?: string,
): Promise<CharityPaginatedResponse> => {
  const { data } = await api.get<CharityPaginatedResponse>(`/charity`, {
    params: {
      page,
      limit,
      name,
    },
  });

  return data;
};

export const removeCharity = async (
  id: number,
): Promise<{ message: string; data: Charity }> => {
  const { data } = await api.delete<{ message: string; data: Charity }>(
    `/charity/${id}`,
  );

  return data;
};

export const updateCharity = async (
  id: number,
  charity: Charity,
): Promise<{ message: string; data: Charity }> => {
  const { data } = await api.put<{ message: string; data: Charity }>(
    `/charity/${id}`,
    charity,
  );

  return data;
};

export const getCharityById = async (
  id: number,
): Promise<{ message: string; data: Charity }> => {
  const { data } = await api.get<{ message: string; data: Charity }>(
    `/charity/${id}`,
  );

  return data;
};

export const getCharityDonationsAvailableDates = async (
  charity: Charity,
): Promise<IAvailableDate[]> => {
  const { data } = await api.get<IAvailableDate[]>(
    `/charity/${charity.id}/donations/available-dates`,
  );

  return data;
};

interface CharityDonationsParams {
  charityId: number;
  year: number;
  month: number;
  page: number;
}

export const getCharityDonations = async (
  params: CharityDonationsParams,
): Promise<PageableApiResponse<CharityDonation, { totalDonated: number }>> => {
  const { charityId } = params;

  const { data } = await api.get<
    PageableApiResponse<CharityDonation, { totalDonated: number }>
  >(`/charity/${charityId}/donations`, {
    params,
  });

  return data;
};

export const getBanks = async (): Promise<bankType> => {
  const { data } = await api.get<bankType>(`/bank`);

  return data;
};

export const createCharity = async (
  body: CharityPaginatedResponse,
): Promise<CharityPaginatedResponse> => {
  const { data } = await api.post<CharityPaginatedResponse>('/charity', body);

  return data;
};

export const saveImageFile = async (body: any): Promise<File> => {
  const formData = new FormData();
  formData.append('file', body);
  formData.append('type', FileTypeEnum.AVATAR_STORE);

  const { data } = await api.post<{ data: File }>('/file', formData);

  return data.data;
};

import styled from 'styled-components';

export const PasswordStrengthContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  margin: 16px 0;
`;

export const PasswordStrengthItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
`;

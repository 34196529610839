import React from 'react';
import clsx from 'clsx';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import BaseTableCell, {
  TableCellProps as BaseTableCellProps,
} from '@material-ui/core/TableCell';

const tableCellStyles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
      color: '#323C3C',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    hideOnMobile: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  });

interface TableCellProps
  extends WithStyles<typeof tableCellStyles>,
    Omit<BaseTableCellProps, 'classes'> {
  th?: boolean;
  hideOnMobile?: boolean;
}

const TableCell = withStyles(tableCellStyles)(
  ({
    classes,
    children,
    th = false,
    hideOnMobile = false,
    className,
    ...rest
  }: TableCellProps) => (
    <BaseTableCell
      style={th ? { fontWeight: 'bold' } : {}}
      {...rest}
      className={clsx(
        className,
        classes.root,
        hideOnMobile ? classes.hideOnMobile : {},
      )}
    >
      {children}
    </BaseTableCell>
  ),
);

export default TableCell;

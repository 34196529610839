import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import MaskedInput from 'react-text-mask';
import { useField } from '@unform/core';

type InputProps = OutlinedInputProps & {
  name: string;
  label?: string;
  showError?: boolean;
  hasMask?: boolean;
  mask?: any;
  fullWidth?: boolean;
};

function TextMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
      mask={props.mask}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

const MaskInput: React.FC<InputProps> = ({
  name,
  defaultValue,
  id,
  label,
  showError = true,
  mask,
  fullWidth = true,
  onBlur,
  onChange,
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    fieldName,
    defaultValue: defaultFieldValue,
    registerField,
    error,
  } = useField(name);
  const defaultInputValue = defaultValue ?? defaultFieldValue;
  const [shrink, setShrink] = useState<boolean>(!!defaultInputValue);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        clearValue(ref, resetValue: string) {
          const newValue = resetValue ?? defaultInputValue ?? '';
          ref.value = newValue;
          setShrink(!!newValue);
        },
        setValue(ref: HTMLInputElement, value: string) {
          if (ref) {
            const newValue = value ?? '';
            ref.value = newValue;
            setShrink(!!newValue);
          }
        },
      });
    }
  }, [fieldName, registerField, defaultInputValue]);

  const inputRefValue = inputRef.current?.value;
  useEffect(() => {
    setShrink(!!inputRef.current?.value);
  }, [inputRefValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.value) {
      setShrink(true);
    } else {
      setShrink(false);
    }

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      style={{ marginBottom: !!error && showError ? '8px' : '16px' }}
    >
      <InputLabel htmlFor={id} error={!!error && showError} shrink={shrink}>
        {label}
      </InputLabel>
      <OutlinedInput
        defaultValue={defaultInputValue}
        inputRef={inputRef}
        name={name}
        notched={shrink}
        label={label}
        {...restProps}
        error={!!error && showError}
        inputComponent={TextMaskCustom}
        inputProps={{
          mask,
        }}
        onChange={handleChange}
        onFocus={() => setShrink(true)}
        onBlur={(e) => {
          if (!inputRef.current?.value) {
            setShrink(false);
          }
          if (onBlur) {
            onBlur(e);
          }
        }}
      />
      {!!error && showError && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(MaskInput);

import { format } from 'date-fns';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { MdPerson, MdSearch } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Container, SearchAndAddUserContainer } from './styles';
import { DeleteUser, PatchUser } from '../../../services/User';

import * as cpf from '@fnando/cpf';
import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Button,
  withStyles,
} from '@material-ui/core';

import { useLoading } from '../../../contexts/loading';
import { listUsers } from '../../../services/User';
import { User } from '../../../types/user';
import MenuOptions from './MenuOptions';
import { PageableApiResponse } from '../../../types/api-response';

import AddUserDialog from '../../AddUserDialog';
import EditUserDialog from '../../EditUserDialog';
import { Invite } from '../../../types/invite';
import DialogContainer from '../../../components/DialogContainer';
import { useToast } from '../../../contexts/toast';

const columns = [
  { id: 'name', label: 'Cliente' },
  { id: 'cpf', label: 'CPF' },
  { id: 'formattedCreatedAt', label: 'Cadastrado' },
  { id: 'status', label: 'Status' },
  { id: 'confirmed', label: 'Confirmado' },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '980px',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '95%',
      },
    },
    headContainer: {
      backgroundColor: theme.palette.background.paper,
      marginBottom: '24px',
    },
    head: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '24px',
      paddingBottom: '30px',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '24px',
      },
    },
    tableRoot: {
      width: '100%',
    },
    tableContainer: {
      maxHeight: 440,
    },
    columnTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    username: {
      textTransform: 'capitalize',
    },
    statusActive: {
      color: '#66B423',
    },
    statusDisabled: {
      color: '#ab003c',
    },
    table: {
      backgroundColor: theme.palette.background.paper,
      padding: '16px',
      borderRadius: '6px',
      boxShadow: '0px 2px 2px #00000029',
    },
    labelOffset: {
      marginLeft: '24px',
    },
    textField: {
      marginBottom: theme.spacing(2),
      width: '380px',
    },
  }),
);

const availableRoles = [
  {
    id: 5,
    name: 'Atendente Poppy',
    description:
      'Tem acesso à funcionalidades necessárias para um atendente do poppy',
  },
  {
    id: 6,
    name: 'Financeiro Poppy',
    description:
      'Tem acesso à funcionalidades necessárias para um financeiro do poppy',
  },
];

const ButtonStyle = withStyles({
  root: {
    boxShadow: 'none',
  },
})(Button);

const ListUsers: React.FC = () => {
  const { setLoading } = useLoading();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState<PageableApiResponse<User>>();
  const [name, setName] = useState();
  const [shrink, setShrink] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [disableUserModalOpen, setDisableUserModalOpen] = useState(false);
  const [enableUserModalOpen, setEnableUserModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserToEdit, setSelectedUserToEdit] =
    useState<User | undefined>();
  const [selectedUserToDelete, setSelectedUserToDelete] = useState<User>();
  const [selectedUserToDisable, setSelectedUserToDisable] = useState<User>();
  const [selectedUserToEnable, setSelectedUserToEnable] = useState<User>();

  const history = useHistory();
  const { showError, showSuccess } = useToast();

  const handleOpenModal = useCallback(() => {
    setAddUserModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setAddUserModalOpen(false);
  }, []);

  const handleOpenModalEditUser = useCallback(() => {
    setEditUserModalOpen(true);
  }, []);

  const handleCloseModalEditUser = useCallback(() => {
    setEditUserModalOpen(false);
  }, []);

  const handleOpenModalDeleteUser = useCallback(() => {
    setDeleteUserModalOpen(true);
  }, []);

  const handleCloseModalDeleteUser = useCallback(() => {
    setDeleteUserModalOpen(false);
  }, []);

  const handleOpenModalDisableUser = useCallback(() => {
    setDisableUserModalOpen(true);
  }, []);

  const handleCloseModalDisableUser = useCallback(() => {
    setDisableUserModalOpen(false);
  }, []);

  const handleOpenModalEnableUser = useCallback(() => {
    setEnableUserModalOpen(true);
  }, []);

  const handleCloseModalEnableUser = useCallback(() => {
    setEnableUserModalOpen(false);
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddUser = useCallback(
    (invite: Invite) => {
      handleCloseModal();
    },
    [handleCloseModal],
  );

  const handleEditUser = useCallback(
    (user: User) => {
      let dataUser = data?.items.find((u) => u.id === user.id);
      dataUser = user;
      handleCloseModalEditUser();
    },
    [handleCloseModalEditUser],
  );

  const search = debounce((text) => {
    setPage(0);
    setName(text);
  }, 500);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const data = await listUsers(page + 1, rowsPerPage, name);
      data.items = data.items.map((item: User) => {
        return {
          ...item,
          cpf: cpf.format(item.cpf),
          formattedCreatedAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
        };
      });
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, name, rowsPerPage, setLoading]);

  const handleSeeProfileClick = useCallback(
    (user: User) => {
      history.push(`/admin/usuarios/${user.cpf}`);
    },
    [history],
  );

  const handleEditUserData = useCallback(
    (user: User) => {
      setSelectedUserToEdit(user);
      handleOpenModalEditUser();
    },
    [history],
  );

  const handleAfterDeleteUser = async () => {
    setIsLoading(true);
    try {
      const response = await DeleteUser(selectedUserToDelete?.id as number);

      showSuccess(response.message as string);
      (data as PageableApiResponse<User, any>).items = data?.items.filter(
        (d) => d.id !== selectedUserToDelete?.id,
      ) as User[];
      fetchUsers();
      handleCloseModalDeleteUser();
    } catch (err) {
      if (err.response) {
        showError(err.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBeforeDeleteUser = useCallback(
    (user: User) => {
      setSelectedUserToDelete(user);
      handleOpenModalDeleteUser();
    },
    [history],
  );

  const handleAfterDisableUser = async () => {
    setIsLoading(true);
    try {
      await PatchUser({
        id: selectedUserToDisable?.id,
        isDisabled: true,
      } as User);
      (selectedUserToDisable as User).isDisabled = true;
      showSuccess('Usuário desativado com sucesso!');
      handleCloseModalDisableUser();
    } catch (err) {
      if (err.response) {
        showError(err.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBeforeDisableUser = useCallback(
    (user: User) => {
      setSelectedUserToDisable(user);
      handleOpenModalDisableUser();
    },
    [history],
  );

  const handleAfterEnableUser = async () => {
    setIsLoading(true);
    try {
      await PatchUser({
        id: selectedUserToEnable?.id,
        isDisabled: false,
      } as User);
      (selectedUserToDisable as User).isDisabled = false;
      showSuccess('Usuário ativado com sucesso!');
      handleCloseModalEnableUser();
    } catch (err) {
      if (err.response) {
        showError(err.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBeforeEnableUser = useCallback(
    (user: User) => {
      setSelectedUserToEnable(user);
      handleOpenModalEnableUser();
    },
    [history],
  );

  return (
    <div>
      <div className={classes.headContainer}>
        <div className={`${classes.container} ${classes.head}`}>
          <MdPerson size={32} />
          <h2>Usuários</h2>
        </div>
      </div>
      <div className={`${classes.container} ${classes.table}`}>
        <Container>
          <SearchAndAddUserContainer
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 24,
            }}
          >
            <TextField
              variant="outlined"
              label="Buscar usuário"
              size="small"
              onChange={(e) => search(e.target.value)}
              className={classes.textField}
              onFocus={() => setShrink(true)}
              onBlur={(e) =>
                !!e.target.value ? setShrink(true) : setShrink(false)
              }
              InputLabelProps={{
                classes: { root: `${!shrink ? classes.labelOffset : ''}` },
                shrink,
              }}
              InputProps={{
                style: {
                  height: '40px',
                },
                startAdornment: <MdSearch size={24} />,
              }}
            />
            <ButtonStyle
              fullWidth
              onClick={handleOpenModal}
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: '25%', height: 50, boxShadow: 'none' }}
            >
              Adicionar Usuários
            </ButtonStyle>
          </SearchAndAddUserContainer>
        </Container>
        {data && (
          <Paper elevation={0} className={classes.tableRoot}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center">
                        <span className={classes.columnTitle}>
                          {column.label}
                        </span>
                      </TableCell>
                    ))}
                    <TableCell width={8} align="right">
                      {''}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.items.map((row: any) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === 'name') {
                            return (
                              <TableCell key={column.id} align="center">
                                <span className={classes.username}>
                                  {row.firstName} {row.surname}
                                </span>
                              </TableCell>
                            );
                          }
                          if (column.id === 'status') {
                            return (
                              <TableCell key={column.id} align="center">
                                <span
                                  className={`${
                                    row.isDisabled
                                      ? classes.statusDisabled
                                      : classes.statusActive
                                  }`}
                                >
                                  {row.isDisabled
                                    ? 'Desabilitado'
                                    : 'Habilitado'}
                                </span>
                              </TableCell>
                            );
                          }
                          if (column.id === 'confirmed') {
                            return (
                              <TableCell key={column.id} align="center">
                                <span
                                  className={`${
                                    row.isActive
                                      ? classes.statusActive
                                      : classes.statusDisabled
                                  }`}
                                >
                                  {row.isActive
                                    ? 'Confirmado'
                                    : 'Não Confirmado'}
                                </span>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align="center">
                              {value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="right">
                          <MenuOptions
                            user={row}
                            onSeeProfileClick={handleSeeProfileClick}
                            onDeleteClick={handleBeforeDeleteUser}
                            onEditUserDataClick={handleEditUserData}
                            onEnableClick={handleBeforeEnableUser}
                            onDisableClick={handleBeforeDisableUser}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={data?.meta.totalItems}
              rowsPerPage={rowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        )}

        <AddUserDialog
          open={addUserModalOpen}
          onAddUser={handleAddUser}
          onClose={handleCloseModal}
          roles={availableRoles}
        ></AddUserDialog>

        <EditUserDialog
          open={editUserModalOpen}
          onUpdateUser={handleEditUser}
          onClose={handleCloseModalEditUser}
          user={selectedUserToEdit}
        ></EditUserDialog>

        <DialogContainer
          title="Deletar usuário"
          open={deleteUserModalOpen}
          isLoading={isLoading}
          onCancel={handleCloseModalDeleteUser}
          onConfirm={handleAfterDeleteUser}
        ></DialogContainer>

        <DialogContainer
          title="Desativar usuário"
          open={disableUserModalOpen}
          isLoading={isLoading}
          onCancel={handleCloseModalDisableUser}
          onConfirm={handleAfterDisableUser}
        ></DialogContainer>

        <DialogContainer
          title="Ativar usuário"
          open={enableUserModalOpen}
          isLoading={isLoading}
          onCancel={handleCloseModalEnableUser}
          onConfirm={handleAfterEnableUser}
        ></DialogContainer>
      </div>
    </div>
  );
};

export default ListUsers;

import clsx from 'clsx';
import React from 'react';

import {
  createStyles,
  makeStyles,
  Paper,
  PaperProps,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      height: '132px',
      width: '100%',
      justifyContent: 'center',
    },
  }),
);

const InfoCard: React.FC<PaperProps> = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Paper
      {...rest}
      elevation={2}
      className={clsx(classes.container, className)}
    >
      {children}
    </Paper>
  );
};

export default InfoCard;

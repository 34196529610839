import { RefObject, useCallback, useRef } from 'react';
import getValidationErrors, { Errors } from '../utils/getValidationErrors';

import { FormHandles } from '@unform/core';
import Yup from '../validators/Yup';

export default function useFormValidation(
  validationSchema: Yup.ObjectSchema,
): {
  formRef: RefObject<FormHandles>;
  validateField(field: string): Promise<boolean>;
  validateForm(
    values: any,
    options?: Yup.ValidateOptions,
    schema?: Yup.ObjectSchema,
  ): Promise<object | undefined>;
  handleValidationErrors(err: any): Errors | undefined;
  handleBlur(field: string): void;
  handleChangeText(field: string): void;
} {
  const formRef = useRef<FormHandles>(null);

  const validateField = useCallback(
    async (field: string) => {
      try {
        const value = formRef.current?.getFieldValue(field);

        await Yup.reach(validationSchema, field).validate(value);

        formRef.current?.setErrors({
          ...formRef.current.getErrors(),
          [field]: undefined,
        });

        return false;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = err as Yup.ValidationError;
          formRef.current?.setFieldError(field, error.message);

          return true;
        }

        return true;
      }
    },
    [validationSchema],
  );

  async function validateForm(
    values: any,
    options?: Yup.ValidateOptions,
  ): Promise<object | undefined> {
    return validationSchema.validate(values, options);
  }

  function handleValidationErrors(err: any): Errors | undefined {
    if (err instanceof Yup.ValidationError) {
      const errors = getValidationErrors(err);

      formRef.current?.setErrors(errors);

      return errors;
    }
  }

  const handleBlur = useCallback(
    async (field: string) => {
      validateField(field);
    },
    [validateField],
  );

  const handleChangeText = useCallback(
    async (field: string) => {
      validateField(field);
    },
    [validateField],
  );

  return {
    formRef,
    validateField,
    validateForm,
    handleValidationErrors,
    handleBlur,
    handleChangeText,
  };
}

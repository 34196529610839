import React, { ReactNode, useEffect, useRef, useState } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioProps,
} from '@material-ui/core';
import { useField } from '@unform/core';

type RadioInputProps = RadioProps & {
  name: string;
  label?: (item: any) => string | ReactNode;
  showError?: boolean;
  items: { id: any; description: string }[] | null;
};

const RadioInput: React.FC<RadioInputProps> = ({
  name,
  defaultChecked,
  defaultValue,
  items,
  label,
  children,
  ...restProps
}) => {
  const inputRefs = useRef<any[]>([]);
  const { fieldName, registerField, error } = useField(name);

  const [value, setValue] = useState();

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRefs.current,
        // path: 'value',
        setValue(refs, value) {
          const item = refs.find((ref: any) => {
            return ref.value === value;
          });

          if (item) {
            setValue(item.value);
          }
        },
        getValue(refs) {
          const checked = refs.find((ref: any) => ref.checked);

          return checked ? checked.value : null;
        },
      });
    }
  }, [fieldName, registerField]);

  return (
    <FormControl component="fieldset">
      {children}
      <RadioGroup name={name} defaultValue={defaultValue}>
        {items?.map((item, index) => (
          <FormControlLabel
            key={item.id}
            name={name}
            value={value}
            control={
              <Radio
                color="primary"
                inputRef={(elRef) => (inputRefs.current[index] = elRef)}
                checked={item.id === (value ?? defaultValue)}
                value={item.id}
                onChange={(e) => {
                  setValue(item.id);
                }}
              />
            }
            label={(label && label(item)) ?? item.description}
            style={{ fontSize: '14px', ...restProps.style }}
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default RadioInput;

import React from 'react';

import { createStyles, makeStyles, PaperProps, Theme } from '@material-ui/core';

import InfoCard from '../InfoCard';
import CurrencyValue from '../CurrencyValue';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.spacing(3),
      color: '#0000008A',
      lineHeight: 1,
      marginBottom: theme.spacing(2),
    },
    value: {
      fontSize: theme.spacing(5),
      color: '#000000DE',
      fontWeight: 400,
      lineHeight: 1,
    },
  }),
);

interface TotalChangeProps extends PaperProps {
  totalChange: number;
  title?: string;
}

const TotalChangeCard: React.FC<TotalChangeProps> = ({
  totalChange,
  title = 'Total de Troco',
  ...props
}) => {
  const classes = useStyles();
  return (
    <InfoCard {...props}>
      <span className={classes.label}>{title}</span>
      <span className={classes.value}>
        <CurrencyValue>{totalChange}</CurrencyValue>
      </span>
    </InfoCard>
  );
};

export default TotalChangeCard;

import React, { createContext, useCallback, useContext, useState } from 'react';
import { StoreInvite } from '../types/store';

interface StoreSignUpData {
  setStep(step: number): void;
  setStoreData(data: any): void;
  currentStep: number;
  store: any;
  signUpToken: any;
  setSignUpTokenData(data: string | null): void;
  user: any;
  setUserData(data: any): void;
  storeInvite: StoreInvite | undefined;
  setStoreInvite(storeInvite: StoreInvite): void;
  placeValue: any;
  setPlace(value: any): void;
  clearSignUpData(): void;
}

const StoreSignUp = createContext<StoreSignUpData>({} as StoreSignUpData);

const SignUpStoreProvider: React.FC = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [signUpToken, setSignUpToken] = useState('');
  const [store, setStore] = useState({});
  const [user, setUser] = useState(null);
  const [storeInvite, setStoreInvite] = useState<StoreInvite | undefined>();
  const [placeValue, setPlaceValue] = useState({});

  const setStep = useCallback((step: number) => {
    setCurrentStep(step);
  }, []);

  const setStoreData = useCallback((data: any) => {
    if (data) {
      setStore(data);
    }
  }, []);

  const setUserData = useCallback((data: any) => {
    if (data) {
      setUser(data);
    }
  }, []);

  const setPlace = useCallback((value: any) => {
    if (value) {
      setPlaceValue(value);
    }
  }, []);

  const setSignUpTokenData = useCallback((data: string | null) => {
    if (data) {
      setSignUpToken(data);
    }
  }, []);

  const clearSignUpData = useCallback(() => {
    setUserData(null);
    setStoreData(null);
    setSignUpTokenData(null);
    setPlace(null);
  }, [setPlace, setSignUpTokenData, setStoreData, setUserData]);

  return (
    <StoreSignUp.Provider
      value={{
        currentStep,
        setStep,
        store,
        setStoreData,
        signUpToken,
        setSignUpTokenData,
        user,
        setUserData,
        storeInvite,
        setStoreInvite,
        placeValue,
        setPlace,
        clearSignUpData,
      }}
    >
      {children}
    </StoreSignUp.Provider>
  );
};

function useSignUpStore(): StoreSignUpData {
  const context = useContext(StoreSignUp);

  if (!context) {
    throw new Error('useToast must be used within an SignUpStoreProvider');
  }

  return context;
}

export { SignUpStoreProvider, useSignUpStore };

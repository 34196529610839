import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar } from '@material-ui/core';

export const Container = styled.div`
  display: grid;
  grid-template-columns: calc(20%) calc(80%);
`;

export const IconLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  text-decoration: none;
`;

export const IconLink = styled(Link)`
  color: var(--white);
  text-decoration: none;
  margin-bottom: 16px;
`;

export const StoreAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
  font-size: 1.5rem;

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
`;

export const DrawerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

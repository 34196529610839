import React from 'react';
import { Route } from 'react-router-dom';

export function RouteWrapper({ page: Page, layout: Layout, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Page {...props} />
        </Layout>
      )}
    />
  );
}

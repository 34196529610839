import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
  @media (max-width: 767px) {
    margin-top: 42px;
    align-items: flex-start;
  }
`;

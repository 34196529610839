import { getMonth, getYear } from 'date-fns';
import { setYear } from 'date-fns/esm';
import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Event from '@material-ui/icons/Event';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { IAvailableDate } from '../../types/transactions';

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputProps: {
      fontSize: '1.5rem',
      width: '120px',
      color: '#0000008A',
    },
  }),
);

const tabs = [
  { label: 'Janeiro', value: 0 },
  { label: 'Fevereiro', value: 1 },
  { label: 'Março', value: 2 },
  { label: 'Abril', value: 3 },
  { label: 'Maio', value: 4 },
  { label: 'Junho', value: 5 },
  { label: 'Julho', value: 6 },
  { label: 'Agosto', value: 7 },
  { label: 'Setembro', value: 8 },
  { label: 'Outubro', value: 9 },
  { label: 'Novembro', value: 10 },
  { label: 'Dezembro', value: 11 },
];

interface HeaderDatePickerProps {
  availableDates: IAvailableDate[];
  selectedYear: Date;
  selectedMonth: Number;
  handleYearChange(date: Date | null): void;
  handleMonthChange(event: React.ChangeEvent<{}>, value: any): void;
}

const HeaderDatePicker: React.FC<HeaderDatePickerProps> = ({
  availableDates,
  handleMonthChange,
  handleYearChange,
  selectedMonth,
  selectedYear,
}) => {
  const classes = useStyles();
  const [minMaxYear, setMinMaxYear] = useState({
    min: new Date(),
    max: new Date(),
  });
  const [availableTabs, setAvailableTabs] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    const years = uniqBy(availableDates, 'year')
      .map((data) => data.year)
      .sort();

    setMinMaxYear({
      min: setYear(new Date(), years[0]),
      max:
        years.length > 1
          ? setYear(new Date(), years[years.length - 1])
          : setYear(new Date(), years[0]),
    });

    const months = availableDates.filter(
      (dates) => dates.year === getYear(selectedYear),
    );

    if (!months.length) {
      const today = new Date();

      // Add actual month if has no one month available
      months.push({
        month: getMonth(today) + 1,
        year: getMonth(today),
      });
    }

    const newTabs = tabs
      .filter((tab) => months.some((month) => month.month === tab.value + 1))
      .reverse();

    setAvailableTabs(newTabs);
  }, [availableDates, selectedYear]);

  return (
    <div
      style={{
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '24px',
      }}
    >
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disableFuture
            openTo="year"
            format="yyyy"
            views={['year']}
            value={selectedYear}
            minDate={minMaxYear.min}
            maxDate={minMaxYear.max}
            onChange={handleYearChange}
            style={{
              width: '120px',
              marginBottom: 24,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Event style={{ color: '#0000008A' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ExpandMore style={{ color: '#0000008A' }} />
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
        {!!availableTabs.length && (
          <Tabs
            value={selectedMonth}
            onChange={handleMonthChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            style={{ color: '#000' }}
          >
            {availableTabs.map((tab) => (
              <Tab
                key={tab.label}
                label={tab.label}
                value={tab.value}
                selected={tab.value === selectedMonth}
                {...a11yProps(tab.value)}
              />
            ))}
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default HeaderDatePicker;

import React from 'react';
import logo from '../../assets/images/logo.png';

import { Container } from './styles';
import { Link } from 'react-router-dom';
import UserMenu from '../UserMenu';
import { useAuth } from '../../contexts/Auth';

const Header: React.FC = () => {
  const { signed } = useAuth();

  return (
    <Container
      style={{
        justifyContent: 'space-between',
      }}
    >
      <Link to="/">
        <img src={logo} alt="" />
      </Link>
      {signed && <UserMenu hideMyAccount hideProfile />}
    </Container>
  );
};

export default Header;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { width: '100%' },
    title: {
      fontSize: '24px',
      fontWeight: 400,
    },
  }),
);

interface FieldsetProps {
  title: string;
}

const Fieldset: React.FC<FieldsetProps> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{title}</h2>
      {children}
    </div>
  );
};

export default Fieldset;

import React from 'react';
import {
  CircularProgress,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: '100vh',
      minWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const FallbackSpinner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress size={80} />
    </div>
  );
};

export default FallbackSpinner;

import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  height: 100%;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0px 2px 2px #00000029;
`;

export const SearchAndAddUserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '980px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('md')]: {
        width: '95%',
      },
    },
    headContainer: {
      backgroundColor: theme.palette.background.paper,
      marginBottom: '24px',
    },
    head: {
      display: 'flex',
      paddingTop: '24px',
    },
    headTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    tableRoot: {
      width: '100%',
    },
    tableContainer: {
      maxHeight: 440,
    },
    columnTitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    username: {
      textTransform: 'capitalize',
    },
    statusActive: {
      color: '#66B423',
    },
    statusDisabled: {
      color: '#ab003c',
    },
    table: {
      backgroundColor: theme.palette.background.paper,
      padding: '16px',
      borderRadius: '6px',
      boxShadow: '0px 2px 2px #00000029',
    },
    labelOffset: {
      marginLeft: '24px',
    },
    textField: {
      marginBottom: theme.spacing(2),
      width: '380px',
      marginRight: '16px',
    },
    formControl: {
      minWidth: 120,
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    nameContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    cnpj: {
      fontWeight: 'normal',
      color: theme.palette.grey[500],
    },
  }),
);

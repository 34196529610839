import { Pagination } from '../../../types/api-response';
import { Store } from '../../../types/store';
import { Transaction } from '../../../types/transactions';
import { User } from '../../../types/user';
import api from '../../api';

interface AddChangeData {
  store: Store;
  client: User;
  change: number;
  paidValue: number;
}
interface EditChangeData {
  change: number;
  paidValue: number;
}

export const AddChange = async (body: AddChangeData): Promise<Transaction> => {
  const { client, store, change, paidValue } = body;

  const { data } = await api.post<Transaction>(
    `/transaction/store/${store.id}`,
    {
      user: { cpf: client.cpf.replace(/\D/g, '') },
      change,
      paidValue,
    },
  );

  return data;
};
export const EditChange = async (
  storeId: number,
  transaction: Transaction,
  body: EditChangeData,
): Promise<Transaction> => {
  const { data } = await api.put<Transaction>(
    `/transaction/${transaction.id}/store/${storeId}`,
    body,
  );

  return data;
};

interface ClientTransactionsResponse {
  items: Transaction[];
  meta: Pagination;
  totalChange: number;
}

export interface ClientTransactionsData {
  clientId: number;
  storeId: number;
  month: number;
  year: number;
  page?: number;
  limit?: number;
}

export const getClientTransactions = async (
  body: ClientTransactionsData,
): Promise<ClientTransactionsResponse> => {
  const { storeId, ...params } = body;

  const { data } = await api.get<ClientTransactionsResponse>(
    `/transaction/store/${storeId}`,
    {
      params,
    },
  );

  return data;
};

import React from 'react';

import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

interface MenuOptionsBtnProps {
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
}

const MenuOptionsBtn: React.FC<MenuOptionsBtnProps> = ({ onClick }) => {
  return (
    <IconButton style={{ padding: 0, margin: 0 }} onClick={onClick}>
      <MoreVert />
    </IconButton>
  );
};

export default MenuOptionsBtn;

import React, { useCallback, useState } from 'react';

import {
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { StoreInvite } from '../../../../types/store';

const useStyles = makeStyles(() =>
  createStyles({
    btn: {
      padding: 0,
      minWidth: 0,
    },
  }),
);

interface ListPendingStoresMenuOptionsProps {
  onSeeProfileClick(storeInvite: StoreInvite): void;
  onResendEmail(storeInvite: StoreInvite): void;
  storeInvite: StoreInvite;
}

function ListPendingStoresMenuOptions({
  onSeeProfileClick,
  storeInvite,
  onResendEmail,
}: ListPendingStoresMenuOptionsProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openOptions = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const closeOptions = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton className={classes.btn} onClick={openOptions}>
        <MoreVert />
      </IconButton>
      <Menu
        id={`admin-list-pending-stores-options-menu-${storeInvite.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeOptions}
      >
        {/* <MenuItem onClick={() => onSeeProfileClick(storeInvite)}>Ver perfil</MenuItem> */}
        <MenuItem
          onClick={() => {
            closeOptions();
            onResendEmail(storeInvite);
          }}
        >
          Reenviar e-mail de cadastro
        </MenuItem>
      </Menu>
    </>
  );
}

export default ListPendingStoresMenuOptions;

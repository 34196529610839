import React, { useState } from 'react';

import { useAuth } from '../../../../../contexts/Auth';
import Yup from '../../../../../validators/Yup';
import TextFieldDialog from '../../Dialogs/TextFieldDialog';
import LabelValue from '../../LabelValue';

const schema = Yup.object().shape({
  fantasyName: Yup.string().required('O campo é obrigatório'),
});

function FantasyNameField() {
  const {
    user: {
      selectedUserStore: {
        store: { fantasyName },
      },
    },
  } = useAuth();

  const [editValue, setEditValue] = useState(false);

  return (
    <>
      <LabelValue
        label="Nome do conveniado"
        editable
        onEdit={() => setEditValue(true)}
      >
        {fantasyName}
      </LabelValue>
      <TextFieldDialog
        onConfirm={() => {
          setEditValue(false);
        }}
        open={editValue}
        onCancel={() => setEditValue(false)}
        input={{
          label: 'Nome do conveniado',
          name: 'fantasyName',
        }}
        previousValue={fantasyName}
        title="Alterar nome do conveniado"
        successMessage="Nome do conveniado alterado com sucesso!"
        validationSchema={schema}
      ></TextFieldDialog>
    </>
  );
}

export default FantasyNameField;

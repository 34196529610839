import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  opacity: 1;
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;

  a {
    display: flex;
    align-items: center;
    img {
      height: 32px;
      @media (max-width: 600px) {
        height: 24px;
      }
    }
  }
`;

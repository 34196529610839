import React, { createContext, useCallback, useContext, useState } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingContextData {
  setLoading(isLoading: boolean): void;
  isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData,
);

const LoadingProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = useCallback((isLoading: boolean) => {
    setIsLoading(isLoading);
  }, []);

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading }}>
      {isLoading && (
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="primary" size={60} />
        </Backdrop>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be used within an LoadingProvider');
  }

  return context;
}

export { LoadingProvider, useLoading };

import React, { useState } from 'react';

import { Menu, MenuItem } from '@material-ui/core';

import { Transaction } from '../../../types/transactions';
import MenuOptionsBtn from '../../Table/MenuOptionsBtn';
import { useAuth } from '../../../contexts/Auth';
import { RolesEnum } from '../../../types/user';
import { differenceInMinutes } from 'date-fns';

interface TableTransactionsMenuOptionsProps {
  transaction: Transaction;
  onSeeProfileClick(transaction: Transaction): void;
  onChangeTransaction(transaction: Transaction): void;
}

const MenuOptions: React.FC<TableTransactionsMenuOptionsProps> = ({
  transaction,
  onSeeProfileClick,
  onChangeTransaction,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user } = useAuth();

  const openOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeOptions = () => {
    setAnchorEl(null);
  };

  const minutes = differenceInMinutes(
    new Date(),
    new Date(transaction.createdAt),
  );

  return (
    <div>
      <MenuOptionsBtn onClick={openOptions} />
      <Menu
        id={`table-transactions-menu-options-${transaction.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeOptions}
      >
        <MenuItem
          onClick={() => {
            onSeeProfileClick(transaction);
          }}
        >
          Ver perfil
        </MenuItem>
        {user.selectedUserStore.role.id === RolesEnum.ADMIN_STORE && (
          <MenuItem
            onClick={() => {
              onChangeTransaction(transaction);
            }}
            disabled={minutes > 30}
          >
            Alterar Troco
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MenuOptions;

import clsx from 'clsx';
import React from 'react';

import {
  Breadcrumbs as BreadcrumbsMaterial,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import ArrowBack from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export interface BreadCrumbsType
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  goBackRoute?: string;
  data: Breadcrumbs[];
}

export interface Breadcrumbs {
  name: string;
  route?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 0 0 0',
    },
  }),
);

const BreadCrumbs: React.FC<BreadCrumbsType> = ({
  data,
  goBackRoute = data[0].route,
  className,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className)}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Link
          color="inherit"
          href={goBackRoute}
          style={{
            color: '#0000008A',
            marginRight: 16,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <ArrowBack style={{ fontSize: 24 }} />
        </Link>
        <BreadcrumbsMaterial
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {data.map((bread) =>
            bread.route ? (
              <Link key={bread.name} color="inherit" href={bread.route}>
                {bread.name}
              </Link>
            ) : (
              <Typography key={bread.name} color="inherit">
                {bread.name}
              </Typography>
            ),
          )}
        </BreadcrumbsMaterial>
      </div>
    </div>
  );
};

export default BreadCrumbs;

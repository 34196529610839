import {
  ApiResponse,
  PageableApiResponse,
  Pagination,
} from '../../types/api-response';
import { Store, StoreInvite } from '../../types/store';
import { IAvailableDate, Transaction } from '../../types/transactions';
import api from '../api';

export const listStores = async (
  page?: number,
  limit?: number,
  name?: string,
  uf?: string,
  partialCnpj?: string
): Promise<any> => {
  const { data } = await api.get<Store[]>(`/store`, {
    params: {
      page,
      limit,
      name,
      uf,
      partialCnpj
    },
  });

  return data;
};

export const listPendingStores = async (
  page?: number,
  limit?: number,
  name?: string,
): Promise<PageableApiResponse<StoreInvite>> => {
  const { data } = await api.get<PageableApiResponse<StoreInvite>>(`/invite`, {
    params: {
      page,
      limit,
      name,
      listPending: true,
    },
  });

  return data;
};
export const countPendingStores = async (): Promise<any> => {
  const { data } = await api.get<StoreInvite[]>(`/invite/count-pending`);

  return data;
};

interface StoreTransactionsParams {
  storeId: number;
  month: number;
  year: number;
  name?: string;
  page?: number;
  limit?: number;
}

interface StoreTransactionResponse {
  items: Transaction[];
  meta: Pagination;
  otherData: {
    unpaidTransactionsBalance: number;
  };
}

export const getStoreTransactions = async (
  params: StoreTransactionsParams,
): Promise<StoreTransactionResponse> => {
  const { storeId, month, year, name, limit, page } = params;
  const { data } = await api.get<StoreTransactionResponse>(
    `/transaction/store/${storeId}`,
    {
      params: {
        page,
        limit,
        name,
        month,
        year,
      },
    },
  );

  return data;
};

export const getAvailableDates = async (
  storeId: number,
): Promise<IAvailableDate[]> => {
  const { data } = await api.get<IAvailableDate[]>(
    `/transaction/store/${storeId}/available-dates`,
  );

  return data;
};

export const getUserTransactionsAvailableDates = async (
  storeId: number,
  clientId: number,
): Promise<IAvailableDate[]> => {
  const { data } = await api.get<IAvailableDate[]>(
    `/store/${storeId}/transactions/${clientId}/available-dates`,
  );

  return data;
};

export const updateStore = async (
  id: number,
  params: Partial<Store>,
): Promise<Store> => {
  const { data } = await api.patch<Store>(`/store/${id}`, {
    ...params,
  });

  return data;
};

export const resendEmail = async (
  storeInvite: StoreInvite,
  email: string,
): Promise<ApiResponse<StoreInvite>> => {
  const { data } = await api.patch<ApiResponse<StoreInvite>>(
    `/invite/${storeInvite.id}`,
    {
      email,
    },
  );

  return data;
};

export const storeExists = async (cnpj: string): Promise<boolean> => {
  const { data } = await api.get<ApiResponse<boolean>>(`/store/exists/${cnpj}`);

  return data.data;
};

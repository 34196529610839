import React, { useState } from 'react';
import { MdLiveHelp } from 'react-icons/md';
import { useStyles, Container } from './styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { IGroupFaq, IQuestionFaq } from '../../../types/faq';

const Faq = () => {
  const classes = useStyles();
  const groupsFaq: IGroupFaq[] = [
    { id: 1, name: 'Cliente', description: 'Cliente' },
    { id: 2, name: 'Estabelecimento', description: 'Estabelecimento' },
    { id: 3, name: 'Dúvidas Gerais', description: 'Dúvidas Gerais' }
  ];
  const questionsFaq: IQuestionFaq[] = [
    { id: 1, name: 'Como faço a instalação do aplicativo Mobile?', description: 'A instalação do aplicativo mobile pode ser visualizado através do link http://studiolocal.com.br/studio/poppy/voce.html.', groupFaqId: 1 },
    { id: 2, name: 'Como visualizo os lançamentos efetuados na minha conta?', description: 'Você precisará fazer o login no aplicativo mobile, informando usuário e senha e poderá visualizar os lançamentos dos trocos efetuados em seus cofrinhos on-line.', groupFaqId: 1 },
    { id: 3, name: 'Como solicito a transferência do valor de crédito acumulado?', description: '&lt;pendente&gt;', groupFaqId: 1 },
    { id: 4, name: 'Quantos cofrinhos digitais eu posso ter?', description: 'Você pode ter vários cofrinhos on-line, cada um sendo direcionado a um determinado objetivo.', groupFaqId: 1 },
    { id: 5, name: 'Como efetuo a doação do valor de crédito acumulado?', description: '&lt;pendente&gt;', groupFaqId: 1 },
    { id: 6, name: 'Onde informo os dados bancários para efetuar a transferência do valor do crédito acumulado?', description: ' &lt;pendente&gt;', groupFaqId: 1 },
    { id: 7, name: 'Onde visualizo as instituições credenciadas pelo Poppy?', description: '<p>As instituições credenciadas pelo Poppy podem ser visualizadas tanto no aplicativo mobile quanto no site poppy.app.br.</p><p>No ato da transferência ou doação, você poderá selecionar as instituições de caridade credenciadas pelo Poppy. Ao selecionar, você informará o valor que deseja transferir e ao confirmar, o seu cofrinho on-line ficará com este valor transferido reduzido e a entidade receberá o valor transferido.</p>', groupFaqId: 1 },
    { id: 8, name: 'Como posso ser um parceiro do Poppy?', description: `<p>Na página principal, abaixo dos dados de acesso, existe um link “Seja Parceiro”. Ao clicar neste link, será aberta uma tela para que sejam informados os dados de Nome, E-mail, Nr do Celular e Nome da Loja.</p><img src="/static/faq/view-seja-parceiro.png" alt="Visão de página seja um parceiro" width="100%"><p>Ao clicar em “Enviar”, estas informações seguirão para análise pelo time Poppy e, estando Ok, um Link será enviado para o endereço de e-mail informado para continuar com o preenchimento dos dados. Ao preencher os demais dados de cadastro, será solicitado informar uma senha de acesso.</p>`, groupFaqId: 2 },
    { id: 9, name: 'Como é efetuado o lançamento do troco no estabelecimento?', description: `<p>O lançamento do troco ocorre no acesso ao perfil estabelecimento, com o usuário e senha informados. Na tela principal da aplicação, aparecerá uma opção para adicionar o troco.</p><img src="/static/faq/view-painel-estabelecimento.png" alt="Visão de painel de estabelecimento" width="100%"><p>Ao clicar neste botão, deverá ser informado o CPF do cliente, o valor da compra e o valor do troco.</p><img src="/static/faq/view-painel-estabelecimento-adicionar-troco.png" alt="Visão de painel de estabelecimento para adicionar troco" width="100%"><p>Ao informar estes dados, deverá ser clicado no botão “Adicionar”.</p><p>Na sequência, o troco será registrado podendo ser visualizado no aplicativo mobile e no site.</p><img src="/static/faq/view-painel-estabelecimento-adicionar-troco-sucesso.png" alt="Visão de painel de estabelecimento, troco adicionado com sucesso" width="100%">`, groupFaqId: 2 },
    { id: 10, name: 'Como acompanho as operações financeiras efetuadas no meu estabelecimento?', description: '<p>No cadastro do Estabelecimento, selecionar opção “Colaboradores”.</p><img src="/static/faq/view-painel-estabelecimento-colaboradores.png" alt="Visão de painel de estabelecimento colaboradores" width="100%"><p>Clicar em “Adicionar Colaboradores”.</p><img src="/static/faq/view-painel-estabelecimento-adicionar-colaboradores.png" alt="Visão de painel de estabelecimento para adicionar colaborador" width="100%"><p>Informar Nome e endereço de email.</p><img src="/static/faq/view-painel-estabelecimento-selecionar-colaboradores.png" alt="Visão de painel de estabelecimento para selecionar colaborador" width="100%"><p>Selecionar “Colaborador” e clicar em “Enviar”.</p><p>Na sua caixa de E-mail irá aparecer uma mensagem do Poppy.</p><img src="/static/faq/view-painel-estabelecimento-confirmacao-email.png" alt="Visão de confirmação de recebimento de e-mail" width="100%"><p>Clicar em Continuar que a aplicação pedirá para que sejam preenchidas mais algumas informações, como dados pessoais e senha.</p>', groupFaqId: 2 },
    { id: 11, name: 'Como adiciono colaboradores ao meu estabelecimento?', description: '<p>As operações financeiras são visualizadas através do Menu Transações.</p><img src="/static/faq/view-painel-estabelecimento-transacoes.png" alt="Visão de painel de estabelecimento para visualizar transações financeiras" width="100%"><p>Neste menu, podemos visualizar todas as transações financeiras realizadas neste estabelecimento.</p>', groupFaqId: 2 },
    { id: 12, name: 'Versão Mínima do Android para execução do aplicativo Mobile', description: 'Versão API 23, a partir da Marshmallow', groupFaqId: 3 },
  ];

  const [expanded, setExpanded] = useState<string | false>(false);
  const [groupFaqSelected, setGroupFaqSelected] = useState(1);
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className={classes.headContainer}>
        <div className={`${classes.container} ${classes.head}`}>
          <MdLiveHelp size={32} style={{ marginRight: '8px' }} />
          <h2> FAQ - Perguntas Mais Frequentes</h2>
        </div>
      </div>
      <Container>
        <Grid container spacing={3}>
          <Grid item sm={3} xs={12} xl={12} md={3} lg={3}>
            <MenuList>
              {groupsFaq.map((group, index) =>
                <MenuItem
                  key={`menu-item-${index}`}
                  classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}
                  onClick={() => { setGroupFaqSelected(group.id) }}
                  selected={groupFaqSelected === group.id ? true : false}
                >
                  {group.name}
                </MenuItem>
              )}
            </MenuList>
          </Grid>
          <Grid item sm={9} xs={12} xl={12} md={9} lg={9}>
            {questionsFaq.filter(opt => opt.groupFaqId === groupFaqSelected).map((question, index) =>
              <Accordion
                key={`accordion-${index}`}
                expanded={expanded === `panel-cliente-${question.id}`}
                onChange={handleChange(`panel-cliente-${question.id}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.expand_icon} />}
                  aria-controls={`panel${question.id}bh-content`}
                  id={`panel${question.id}bh-header`}
                >
                  <Typography className={classes.heading}>{question.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'span'}>
                    <div dangerouslySetInnerHTML={{ __html: question.description }} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Faq;

import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';

import {
  Avatar,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';

import { useLoading } from '../../../contexts/loading';
import { listPendingStores } from '../../../services/Store';
import ListPendingStoresMenuOptions from './MenuOptions';
import { PageableApiResponse } from '../../../types/api-response';
import { StoreInvite } from '../../../types/store';
import ResendEmailDialog from './ResendEmailDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRoot: {
      width: '100%',
    },

    username: {
      textTransform: 'capitalize',
    },
    pendingStatus: {
      color: '#596CF9',
    },
    labelOffset: {
      marginLeft: '24px',
    },
    textField: {
      marginBottom: theme.spacing(2),
      width: '380px',
      marginRight: '16px',
    },
    formControl: {
      minWidth: 120,
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    nameContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    cnpj: {
      fontWeight: 'normal',
      color: theme.palette.grey[500],
    },
  }),
);

const ListPendingStores: React.FC = () => {
  const classes = useStyles();
  const { setLoading } = useLoading();
  const [page, setPage] = useState(0);
  const [data, setData] = useState<PageableApiResponse<StoreInvite>>();
  const [name, setName] = useState();
  const [shrink, setShrink] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [resendEmailDialogFor, setResendEmailDialogFor] = useState<
    StoreInvite
  >();

  useEffect(() => {
    async function fetchUsers() {
      try {
        setLoading(true);
        const data = await listPendingStores(page + 1, rowsPerPage, name);
        setData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchUsers();
  }, [page, name, rowsPerPage, setLoading]);

  const search = debounce((text) => {
    setPage(0);
    setName(text);
  }, 500);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleResentEmail = useCallback((updatedStoreInvite: StoreInvite) => {
    setData((prev) => {
      if (!prev) {
        return undefined;
      }

      return {
        ...prev,
        items: prev.items.map((storeInvite) =>
          storeInvite.id === updatedStoreInvite.id
            ? updatedStoreInvite
            : storeInvite,
        ),
      };
    });
    setResendEmailDialogFor(undefined);
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <TextField
          variant="outlined"
          label="Buscar conveniado"
          size="small"
          onChange={(e) => search(e.target.value)}
          className={classes.textField}
          onFocus={() => setShrink(true)}
          onBlur={(e) =>
            !!e.target.value ? setShrink(true) : setShrink(false)
          }
          InputLabelProps={{
            classes: { root: `${!shrink ? classes.labelOffset : ''}` },
            shrink,
          }}
          InputProps={{
            style: {
              height: '40px',
            },
            startAdornment: <MdSearch size={24} />,
          }}
        />
      </div>
      {data && (
        <Paper elevation={0} className={classes.tableRoot}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableBody>
                {data.items.map((storeInvite) => {
                  return (
                    <TableRow hover tabIndex={-1} key={storeInvite.id}>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            alt={storeInvite.companyName}
                            className={classes.avatar}
                            src="/static/images/avatar/1.jpg"
                          />
                          <span className={classes.username}>
                            {storeInvite.companyName}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell align="center">
                        <span className={classes.pendingStatus}>Pendente</span>
                      </TableCell>
                      <TableCell align="right">
                        <ListPendingStoresMenuOptions
                          storeInvite={storeInvite}
                          onSeeProfileClick={() => {}}
                          onResendEmail={(storeInvite) =>
                            setResendEmailDialogFor(storeInvite)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            count={data?.meta?.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {!!resendEmailDialogFor && (
        <ResendEmailDialog
          storeInvite={resendEmailDialogFor}
          onCancel={() => setResendEmailDialogFor(undefined)}
          onResentEmail={handleResentEmail}
        ></ResendEmailDialog>
      )}
    </div>
  );
};

export default ListPendingStores;

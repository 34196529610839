import React, { useCallback, useEffect, useState } from 'react';

import { Form } from '@unform/web';

import DialogContainer from '../../../../../components/DialogContainer';
import MaskedInput from '../../../../../components/Forms/MaskedInput';
import { useAuth } from '../../../../../contexts/Auth';
import { useToast } from '../../../../../contexts/toast';
import useFormValidation from '../../../../../hooks/useFormValidation';
import { updateStore } from '../../../../../services/Store';
import { Phone } from '../../../../../types/user';
import { PhoneMask } from '../../../../../utils/Masks/Phone';
import { parseMaskedPhone } from '../../../../../utils/parseMaskedPhone';
import Yup from '../../../../../validators/Yup';

interface FormData {
  phone: string;
}

interface PhoneDialogProps {
  open: boolean;
  onCancel(): void;
  onConfirm(phone: Phone): void;
}

const schema = Yup.object().shape({
  phone: Yup.string()
    // Counting min and max with mask. E.g.: (61) 3331-3519
    .min(14, 'Número inválido.')
    .max(15, 'Número inválido.')
    .required('O telefone é obrigatório.'),
});

function PhoneDialog({ onConfirm, ...rest }: PhoneDialogProps) {
  const {
    user: {
      selectedUserStore: { store },
    },
    updateSelectedStore,
  } = useAuth();

  const { showError, showSuccess } = useToast();
  const {
    formRef,
    handleBlur,
    validateForm,
    handleValidationErrors,
  } = useFormValidation(schema);

  const [updating, setUpdating] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(
    store.phone.areaCode + store.phone.number,
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setUpdating(true);
        setDirty(false);

        await validateForm(data, { abortEarly: false });

        const phone = parseMaskedPhone(data.phone) as Phone;

        const updatedStore = await updateStore(store.id, {
          phone,
        });

        updateSelectedStore(updatedStore);

        onConfirm(phone);
        showSuccess('Telefone atualizado com sucesso!');
      } catch (err) {
        const hasErrors = handleValidationErrors(err);
        setDirty(true);
        console.log('Err', hasErrors);
        if (err.response) {
          showError(err.response.data.message);
        }
      } finally {
        setUpdating(false);
      }
    },
    [
      handleValidationErrors,
      onConfirm,
      showError,
      showSuccess,
      store.id,
      updateSelectedStore,
      validateForm,
    ],
  );

  useEffect(() => {
    const parsed = parseMaskedPhone(updatedValue);

    if (parsed.areaCode === null || parsed.number === null) {
      return;
    }

    setDirty(
      parsed.number !== store.phone.number ||
        parsed.areaCode !== store.phone.areaCode,
    );
  }, [store.phone, store.phone.areaCode, store.phone.number, updatedValue]);

  return (
    <DialogContainer
      {...rest}
      onConfirm={() => formRef.current?.submitForm()}
      disabledConfirmBtn={!dirty}
      isLoading={updating}
      title="Alterar telefone"
    >
      <Form
        ref={formRef}
        initialData={{ phone: store.phone.areaCode + store.phone.number }}
        onSubmit={handleSubmit}
      >
        <MaskedInput
          name="phone"
          mask={PhoneMask}
          label="Telefone"
          onBlur={() => handleBlur('phone')}
          onChange={(e) => {
            setUpdatedValue(e.target.value);
          }}
        />
      </Form>
    </DialogContainer>
  );
}

export default PhoneDialog;

import React, { ChangeEvent, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Form } from '@unform/web';

import Input from '../../components/Forms/Input';
import MaskedInput from '../../components/Forms/MaskedInput';
import PasswordInput from '../../components/Forms/PasswordInput';
import { useToast } from '../../contexts/toast';
import useFormValidation from '../../hooks/useFormValidation';
import {
  CreateClerk,
  FetchInvite,
} from '../../services/Signup';
import { CPFMask } from '../../utils/Masks/CPF';
import { PhoneMask } from '../../utils/Masks/Phone';
import Yup from '../../validators/Yup';
import SuccessCard from '../../components/SuccessCard';
import { useLoading } from '../../contexts/loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    cardContainer: {
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0px 1px 10px #00000014',
      width: '500px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        margin: '8px',
      },
    },
    clerk: {
      marginBottom: '16px',
    },
  }),
);

const schema = Yup.object().shape({
  firstName: Yup.string().required('O campo é obrigatório'),
  surname: Yup.string().required('O campo é obrigatório'),
  cpf: Yup.string().isCPF('CPF inválido').required('O campo é obrigatório'),
  phone: Yup.string().required('O campo é obrigatório'),
  password: Yup.string()
    .required('O campo é obrigatório')
    .min(8, 'A senha deve ter no minimo 8 caracteres.')
    .matches(/^(?=.*[A-Z]).*$/, 'Senha deve ter pelo menos 1 letra maiúscula.'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O campo é obrigatório'),
});

const ClerkPoppySignup: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setLoading } = useLoading();
  const params = useParams<{ token: string }>();
  const { showError, showSuccess } = useToast();
  const {
    formRef,
    validateForm,
    handleValidationErrors,
    handleBlur,
  } = useFormValidation(schema);
  const [userCreated, setUserCreated] = useState(false);
  const [validPassword, setValidPassword] = useState({
    min: false,
    upper: false,
  });

  useEffect(() => {
    async function fetchData(token: string) {
      try {
        setLoading(true);
        const data = await FetchInvite(token);
        if (!data) {
          return;
        }

        formRef.current?.setFieldValue(
          'firstName',
          data.name.split(' ')[0] || '',
        );
        formRef.current?.setFieldValue(
          'surname',
          data.name.split(' ')[2] || '',
        );
        formRef.current?.setFieldValue('email', data.email);
      } catch (error) {
        console.log(error);
        if (error.response?.data) {
          showError(error?.response?.data?.message);
          history.push('/');
        }
      } finally {
        setLoading(false);
      }
    }
    fetchData(params.token);
  }, [formRef, history, params.token, setLoading, showError]);

  const handleBackClick = () => {
    history.push('/');
  };

  const handleChangePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setValidPassword({
      min: value.length >= 8,
      upper: !!value.match(/^(?=.*[A-Z]).*$/),
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      await validateForm(data, {
        abortEarly: false,
      });
      data.token = params.token;
      const createdUser = await CreateClerk(data);
      if (createdUser) {
        showSuccess('Conta criada com sucesso!');
        setUserCreated(true);
      }
    } catch (error) {
      handleValidationErrors(error);
      if (error?.response?.data) {
        showError(error.response.data.message);
      }
    }
  };

  return (
    <div className={classes.container}>
      {!userCreated && (
        <Card className={classes.cardContainer}>
          <Typography variant="h5" className={classes.clerk}>
            Colaborador
          </Typography>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  name="firstName"
                  type="text"
                  label="Nome"
                  onBlur={() => handleBlur('firstName')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="surname"
                  type="text"
                  label="Sobrenome"
                  onBlur={() => handleBlur('surname')}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="email"
                  type="text"
                  label="E-mail"
                  disabled
                  onBlur={() => handleBlur('email')}
                />
              </Grid>
              <Grid item xs={12}>
                <MaskedInput
                  name="phone"
                  mask={PhoneMask}
                  label="Telefone"
                  onBlur={() => handleBlur('phone')}
                />
              </Grid>
              <Grid item xs={12}>
                <MaskedInput
                  name="cpf"
                  mask={CPFMask}
                  label="CPF"
                  onBlur={() => handleBlur('cpf')}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  className="login-field"
                  name="password"
                  onBlur={() => handleBlur('password')}
                  label="Senha"
                  showError={true}
                  onChange={handleChangePasswordChange}
                  valid={validPassword}
                  showPasswordStrength={true}
                />
              </Grid>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
              >
                Criar Conta
              </Button>
            </Grid>
          </Form>
        </Card>
      )}
      {userCreated && (
        <SuccessCard
          backButtonText="Voltar para tela de login"
          message="Conta criada com sucesso"
          onBackButtonClick={handleBackClick}
        />
      )}
    </div>
  );
};

export default ClerkPoppySignup;

import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import { useAuth } from '../../contexts/Auth';
import { useSignUpStore } from '../../contexts/store-signup';
import { useToast } from '../../contexts/toast';

export enum UserRoles {
  USER = 'User',
  ADMIN = 'Admin',
  CONTRIBUTOR = 'Contributor',
  STORE_ADMIN = 'Store Admin',
  CLERK_POPPY = 'Clerk',
  FINANCIAL_POPPY = 'Financial'
}

interface Props extends RouteProps {
  /**
   * An array of roles to compare with user.roles.
   * @default [] An empty array
   */
  roles?: string[];
  /**
   * The component to render if user is signed, active and with roles
   */
  page: any;
  layout: any;
  /**
   * Override the conditions to check if user has roles
   */
  canActivate?: boolean;
  /**
   * A url to redirect if user has no roles or canActivate returns false.
   * @default / Login route
   */
  redirectUrl?: string;
}

export const AuthenticatedRouteWrapper: React.FC<Props> = ({
  page: Page,
  layout: Layout,
  roles = [],
  canActivate,
  redirectUrl = '/',
  ...rest
}) => {
  const { signed, user, loading } = useAuth();
  const { setUserData } = useSignUpStore();
  const { showError } = useToast();
  const location = useLocation();

  const hasRoles =
    location.pathname.match(/selecionar-conveniado/) ||
    user?.roles?.some((role) => roles.includes(role)) ||
    !!canActivate;

  useEffect(() => {
    if (user && !user?.isActive) {
      showError('Por favor ative sua conta antes de logar.');
    }

    if (user && !hasRoles && !redirectUrl) {
      showError('Página não encontrada');
    }
  }, [user, hasRoles, showError, setUserData]);

  if (loading) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!signed) {
          return <Redirect to="/" />;
        }
        if (!user?.isActive) {
          return <Redirect to="/registro-concluido" />;
        }
        if (!hasRoles) {
          return <Redirect to={redirectUrl} />;
        }
        return (
          <Layout {...props}>
            <Page {...props} />
          </Layout>
        );
      }}
    />
  );
};

import * as Yup from 'yup';
import { isValid, strip } from '@fnando/cpf';
import { isValid as isValidCNPJ, strip as stripCNPJ } from '@fnando/cnpj';

// tslint:disable-next-line: only-arrow-functions

Yup.addMethod(Yup.string, 'isCPF', function (message) {
  return Yup.mixed().test('test-cpf', message, function (value: any) {
    const { createError } = this;
    return isValid(strip(value)) || createError(message);
  });
});

Yup.addMethod(Yup.string, 'isCNPJ', function (message) {
  return Yup.mixed().test('test-cnpj', message, function (value: any) {
    const { createError } = this;
    return isValidCNPJ(stripCNPJ(value)) || createError(message);
  });
});

export default Yup;

import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useToast } from '../contexts/toast';
import { SearchUserByCpf } from '../services/User/index';
import { User } from '../types/user';
import { addCpfMask } from '../utils/addCpfMask';

interface Options {
  cpf?: string;
  onError?(error: any): void;
  onNotFoundClient?(): void;
  redirectUrl?: string;
  notFoundMessage?: string;
}

interface RouteParams {
  cpf: string;
}

export default function useClient(options?: Options) {
  const [client, setClient] = useState<User | undefined>();
  const { showError } = useToast();

  const history = useHistory();

  // Defaults
  const { cpf: routeCpf } = useParams<RouteParams>();
  const handleLoadContributorError = useCallback(
    (error) => {
      if (error.response?.data) {
        showError(error?.response?.data?.message);
      }
    },
    [showError],
  );

  const cpf = options?.cpf ?? routeCpf;
  const onError = options?.onError ?? handleLoadContributorError;
  const redirectUrl =
    options?.redirectUrl ?? '/painel-estabelecimento/transacoes';
  const notFoundMessage = options?.notFoundMessage ?? 'Cliente não encontrado!';

  useEffect(() => {
    async function fetchData(cpf: string) {
      try {
        const data = await SearchUserByCpf(cpf);

        if (!data) {
          showError(notFoundMessage);

          history.push(redirectUrl);

          return;
        }

        data.cpf = addCpfMask(data.cpf);

        setClient(data);
      } catch (error) {
        if (onError) {
          onError(error);
        }
      }
    }
    if (cpf) {
      fetchData(cpf);
    }
  }, [cpf, history, notFoundMessage, onError, redirectUrl, showError]);

  return client;
}

import React from 'react';
import * as cnpj from '@fnando/cnpj';

interface CnpjValueProps {
  children: string;
}

const CnpjValue: React.FC<CnpjValueProps> = ({ children }) => (
  <>{cnpj.format(children)}</>
);

export default CnpjValue;

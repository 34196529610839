import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';

import * as cnpj from '@fnando/cnpj';
import {
  Avatar,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';

import { useLoading } from '../../../contexts/loading';
import { listStores } from '../../../services/Store';
import { Store } from '../../../types/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRoot: {
      width: '100%',
    },
    username: {
      textTransform: 'capitalize',
    },
    statusActive: {
      color: '#66B423',
    },
    statusDisabled: {
      color: '#ab003c',
    },
    labelOffset: {
      marginLeft: '24px',
    },
    textField: {
      marginBottom: theme.spacing(2),
      width: '380px',
      marginRight: '16px',
    },
    formControl: {
      minWidth: 120,
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    nameContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    cnpj: {
      fontWeight: 'normal',
      color: theme.palette.grey[500],
    },
  }),
);

const states = [
  { description: 'Todos', uf: 'ALL' },
  { description: 'Acre', uf: 'AC' },
  { description: 'Alagoas', uf: 'AL' },
  { description: 'Amapá', uf: 'AP' },
  { description: 'Amazonas', uf: 'AM' },
  { description: 'Bahia', uf: 'BA' },
  { description: 'Ceará', uf: 'CE' },
  { description: 'Distrito Federal', uf: 'DF' },
  { description: 'Espírito Santo', uf: 'ES' },
  { description: 'Goiás', uf: 'GO' },
  { description: 'Maranhão', uf: 'MA' },
  { description: 'Mato Grosso', uf: 'MT' },
  { description: 'Mato Grosso do Sul', uf: 'MS' },
  { description: 'Minas Gerais', uf: 'MG' },
  { description: 'Pará', uf: 'PA' },
  { description: 'Paraíba', uf: 'PB' },
  { description: 'Paraná', uf: 'PR' },
  { description: 'Pernambuco', uf: 'PE' },
  { description: 'Piauí', uf: 'PI' },
  { description: 'Rio de Janeiro', uf: 'RJ' },
  { description: 'Rio Grande do Norte', uf: 'RN' },
  { description: 'Rio Grande do Sul', uf: 'RS' },
  { description: 'Rondônia', uf: 'RO' },
  { description: 'Roraima', uf: 'RR' },
  { description: 'Santa Catarina', uf: 'SC' },
  { description: 'São Paulo', uf: 'SP' },
  { description: 'Sergipe', uf: 'SE' },
  { description: 'Tocantins', uf: 'TO' },
];

const ListAvailableStores: React.FC = () => {
  const classes = useStyles();
  const { setLoading } = useLoading();
  const [page, setPage] = useState(0);
  const [data, setData] = useState<any>();
  const [name, setName] = useState();
  const [uf, setUF] = useState();
  const [shrink, setShrink] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    async function fetchUsers() {
      try {
        setLoading(true);
        const data = await listStores(page + 1, rowsPerPage, name, uf);
        data.items = data.items.map((item: Store) => {
          return {
            ...item,
            cnpj: cnpj.format(item.cnpj),
          };
        });
        setData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchUsers();
  }, [page, name, uf, rowsPerPage, setLoading]);

  const search = debounce((text) => {
    setPage(0);
    setName(text);
  }, 500);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleUfChange = (e: any) => {
    if (e.target.value === 'ALL') {
      return setUF(undefined);
    }
    return setUF(e.target.value);
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <TextField
          variant="outlined"
          label="Buscar conveniado"
          size="small"
          onChange={(e) => search(e.target.value)}
          className={classes.textField}
          onFocus={() => setShrink(true)}
          onBlur={(e) =>
            !!e.target.value ? setShrink(true) : setShrink(false)
          }
          InputLabelProps={{
            classes: { root: `${!shrink ? classes.labelOffset : ''}` },
            shrink,
          }}
          InputProps={{
            style: {
              height: '40px',
            },
            startAdornment: <MdSearch size={24} />,
          }}
        />
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
        >
          <InputLabel id="state-select">Estado</InputLabel>
          <Select
            labelId="state-select"
            value={uf}
            onChange={handleUfChange}
            label="Estado"
            inputProps={{
              name: 'state',
              id: 'state-select',
            }}
          >
            {states.map((state) => (
              <MenuItem key={state.uf} value={state.uf}>
                {state.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {data && (
        <Paper elevation={0} className={classes.tableRoot}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableBody>
                {data.items.map((row: any) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            alt={row.fantasyName}
                            className={classes.avatar}
                            src="/static/images/avatar/1.jpg"
                          />
                          <span className={classes.username}>
                            {row.fantasyName}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <span className={classes.cnpj}>{row.cnpj}</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className={classes.statusActive}>Habilitado</span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={data?.meta?.itemCount}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
};

export default ListAvailableStores;

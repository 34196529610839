import React, { useEffect, useState } from 'react';
import {
  MdDashboard,
  MdGroup,
  MdHelpOutline,
  MdMonetizationOn,
  MdPerson,
  MdStoreMallDirectory,
  MdLocalFlorist,
  MdPanTool,
} from 'react-icons/md';
import { useLocation, Link } from 'react-router-dom';
import { Hidden, IconButton, isWidthUp, withWidth } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import logo from '../../assets/images/logo.png';
import Header from '../../components/Admin/Header';
import MenuItem from '../../components/Admin/MenuItem';
import { useAuth } from '../../contexts/Auth';
import { UserRoles } from '../../routes/wrappers/AuthenticatedRouteWrapper';
import {
  DrawerContainer,
  IconLink,
  IconLinkContainer,
  StoreAvatar,
} from './styles';
import { downloadFileUrl } from '../../services/File';

const drawerWidth = 160;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    logo: {
      margin: '16px',
      alignSelf: 'center',
      maxWidth: '126px',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    centerList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    drawerPaper: {
      backgroundColor: '#323C3C',
      color: '#FFF',
      width: drawerWidth,
      justifyContent: 'space-between',
    },
    toolbar: {
      ...theme.mixins.toolbar,
      height: '64px',
    },
    content: {
      flexGrow: 1,
    },
  }),
);

interface IMenu {
  label: string;
  icon: any;
  route: string;
}

const AdminLayout: React.FC<{ width: Breakpoint }> = ({ width, children }) => {
  const classes = useStyles();
  const { user, isUserStoreAdmin } = useAuth();
  let location = useLocation();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuRoutes, setMenuRoutes] = useState<IMenu[]>([]);

  const { selectedUserStore } = user;

  useEffect(() => {
    if (user.roles.includes(UserRoles.ADMIN) || user.roles.includes(UserRoles.FINANCIAL_POPPY)) {
      setMenuRoutes([
        {
          label: 'Dashboard',
          icon: <MdDashboard size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin',
        },
        {
          label: 'Transações',
          icon: <MdMonetizationOn size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/transacoes',
        },
        {
          label: 'Contatos',
          icon: <MdGroup size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/contatos',
        },
        {
          label: 'Usuários',
          icon: <MdPerson size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/usuarios',
        },
        {
          label: 'Conveniados',
          icon: (
            <MdStoreMallDirectory size={isWidthUp('sm', width) ? 32 : 24} />
          ),
          route: '/admin/conveniados',
        },
        {
          label: 'Instituições',
          icon: <MdLocalFlorist size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/instituicoes',
        },
        {
          label: 'Política de Privacidade',
          icon: <MdPanTool size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/politica-privacidade',
        },
        {
          label: 'Termos de uso',
          icon: <MdPanTool size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/termos-uso',
        },
      ]);
    } else if (user.roles.includes(UserRoles.CLERK_POPPY)) {
      setMenuRoutes([
        {
          label: 'Transações',
          icon: <MdMonetizationOn size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/transacoes',
        },
        {
          label: 'Contatos',
          icon: <MdGroup size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/contatos',
        },
        {
          label: 'Usuários',
          icon: <MdPerson size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/usuarios',
        },
        {
          label: 'Conveniados',
          icon: (
            <MdStoreMallDirectory size={isWidthUp('sm', width) ? 32 : 24} />
          ),
          route: '/admin/conveniados',
        },
        {
          label: 'Instituições',
          icon: <MdLocalFlorist size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/admin/instituicoes',
        }
      ]);
    }

    if (selectedUserStore) {
      let routes = [
        {
          label: 'Transações',
          icon: <MdMonetizationOn size={isWidthUp('sm', width) ? 32 : 24} />,
          route: '/painel-estabelecimento/transacoes',
        },
      ];

      if (isUserStoreAdmin()) {
        routes = [
          ...routes,
          {
            label: 'Colaboradores',
            icon: <MdGroup size={isWidthUp('sm', width) ? 32 : 24} />,
            route: '/painel-estabelecimento/colaboradores',
          },
        ];
      }

      setMenuRoutes(routes);
    }
  }, [isUserStoreAdmin, selectedUserStore, user, width]);

  const bottomMenuItems = [
    {
      icon: <MdHelpOutline size={isWidthUp('sm', width) ? 32 : 24} />,
      route: '/admin/faq',
    },
  ];

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <DrawerContainer>
      <img className={classes.logo} src={logo} alt="" />
      <List className={classes.centerList}>
        {menuRoutes.map((menu) => (
          <MenuItem
            key={menu.label}
            label={menu.label}
            isActive={location.pathname === menu.route}
            to={menu.route}
          >
            {menu.icon}
          </MenuItem>
        ))}
      </List>
      <IconLinkContainer>
        {bottomMenuItems.map((item) => (
          <IconLink key={item.route} to={item.route}>
            {item.icon}
          </IconLink>
        ))}

        {!!user.selectedUserStore && (
          <Link to="/painel-estabelecimento" style={{ textDecoration: 'none' }}>
            <IconButton>
              <StoreAvatar
                alt={user.selectedUserStore.store.fantasyName}
                src={
                  user.selectedUserStore.store.avatar
                    ? downloadFileUrl(user.selectedUserStore.store.avatar)
                    : undefined
                }
                style={{}}
              />
            </IconButton>
          </Link>
        )}
      </IconLinkContainer>
    </DrawerContainer>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={isWidthUp('sm', width) ? drawerWidth : 0}
      />
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          {drawer}
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default withWidth()(AdminLayout);

import React from 'react';
import CenterCard from '../CenterCard';
import Logo from '../Logo';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import successImg from '../../assets/images/success.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxSizing: 'border-box',
      alignItems: 'center',
      paddingTop: theme.spacing(6),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
    },
    image: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    message: {
      fontSize: '20px',
      fontWeight: 500,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      paddingBottom: theme.spacing(9),
    },
    backButton: {
      width: '100%',
    },
  }),
);

interface SuccessCardProps {
  message: string;
  backButtonText: string;
  onBackButtonClick(): void;
}

const SuccessCard: React.FC<SuccessCardProps> = ({
  message,
  backButtonText,
  onBackButtonClick,
}) => {
  const classes = useStyles();

  return (
    <CenterCard>
      <div className={classes.content}>
        <Logo color="black" />
        <img className={classes.image} src={successImg} alt="Sucesso" />
        <h1 className={classes.message}>{message}</h1>
        <Button
          variant="contained"
          color="primary"
          className={classes.backButton}
          onClick={onBackButtonClick}
        >
          {backButtonText}
        </Button>
      </div>
    </CenterCard>
  );
};

export default SuccessCard;

import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        fontWeight: 600,
        padding: '12px 0',
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: { main: '#2EC625', contrastText: '#FFF' },
    secondary: { main: '#FF8264', contrastText: '#FFF' },
  },
});

export default theme;

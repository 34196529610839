import React from 'react';

import { IAvailableDate } from '../../types/transactions';
import BreadCrumbs, { Breadcrumbs } from '../BreadCrumbs';
import HeaderDatePicker from '../HeaderDatePicker';
import useStyles, { Container } from './styles';

interface FiltrableTransactionsProps {
  availableDates: IAvailableDate[];
  selectedDate: Date;
  selectedMonth: number;
  onYearChange(date: Date | null): void;
  onMonthChange(event: React.ChangeEvent<{}>, value: any): void;
  infoCards: React.ReactNode;
  breadcrumbsOptions?: {
    data: Breadcrumbs[];
  };
}

const FiltrableTransactions: React.FC<FiltrableTransactionsProps> = ({
  availableDates,
  selectedDate,
  selectedMonth,
  onMonthChange,
  onYearChange,
  infoCards,
  breadcrumbsOptions,
  children,
}) => {
  const classes = useStyles();

  return (
    <Container>
      <div className={classes.headerContainer}>
        <div className={classes.headerContent}>
          <HeaderDatePicker
            availableDates={availableDates}
            selectedYear={selectedDate}
            selectedMonth={selectedMonth}
            handleMonthChange={onMonthChange}
            handleYearChange={onYearChange}
          />
        </div>
      </div>
      <div className={classes.contentContainer}>
        {!!breadcrumbsOptions && (
          <BreadCrumbs
            className={classes.breadcrumbs}
            {...breadcrumbsOptions}
          />
        )}
        <div className={classes.infoCards}>{infoCards}</div>
        {children}
      </div>
    </Container>
  );
};

export default FiltrableTransactions;

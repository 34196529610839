import React, { createContext, useCallback, useContext, useState } from 'react';

import { ConfirmationDialog } from '../components/ConfirmDialog';

interface ConfirmDialogContextData {
  open: boolean;
  handleClose: any;
  openDialog: (options: IModalOptions) => Promise<boolean>;
}

export interface IModalOptions {
  title: string;
  confirmTitle: string;
  confirmDescription: string;
  variant: string;
}

const ConfirmDialogContext = createContext<ConfirmDialogContextData>(
  {} as ConfirmDialogContextData,
);
let resolve: any;
const ConfirmDialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);

  const [modalOptions, setModalOptions] = useState<IModalOptions>();

  const handleClose = useCallback((value: boolean) => {
    setOpen(false);
    resolve(value);
  }, []);

  const openDialog = useCallback((modalOptions?: IModalOptions): Promise<
    boolean
  > => {
    setOpen(true);
    setModalOptions(modalOptions);
    return new Promise((res) => {
      resolve = res;
    });
  }, []);

  return (
    <ConfirmDialogContext.Provider value={{ open, handleClose, openDialog }}>
      {open && modalOptions && (
        <ConfirmationDialog open={open} modalOptions={modalOptions} />
      )}
      {children}
    </ConfirmDialogContext.Provider>
  );
};

function useConfirmDialog(): ConfirmDialogContextData {
  const context = useContext(ConfirmDialogContext);

  if (!context) {
    throw new Error(
      'useConfirmDialog must be used within an ConfirmDialogProvider',
    );
  }

  return context;
}

export { ConfirmDialogProvider, useConfirmDialog };

import React from 'react';

import Header from '../../components/Header';
import { Container } from './styles';

const SelectStoreLayout: React.FC = ({ children }) => {
  return (
    <div>
      <Header></Header>
      <Container className="w-100 h-100">{children}</Container>
    </div>
  );
};

export default SelectStoreLayout;

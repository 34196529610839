import React, { useCallback, useState } from 'react';

import {
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { User } from '../../../../types/user';

const useStyles = makeStyles(() =>
  createStyles({
    btn: {
      padding: 0,
      minWidth: 0,
    },
  }),
);

interface UsersListMenuOptionsProps {
  onSeeProfileClick(user: User): void;
  onEditUserDataClick(user: User): void;
  onDeleteClick(user: User): void;
  onEnableClick(user: User): void;
  onDisableClick(user: User): void;
  user: User;
}

const MenuOptions: React.FC<UsersListMenuOptionsProps> = ({
  onSeeProfileClick,
  onEditUserDataClick,
  onDeleteClick,
  onEnableClick,
  onDisableClick,
  user
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openOptions = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const closeOptions = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton className={classes.btn} onClick={openOptions}>
        <MoreVert />
      </IconButton>
      <Menu
        id={`admin-list-users-options-menu-${user.id}`}
        anchorEl={anchorEl}
        closeAfterTransition
        open={Boolean(anchorEl)}
        onClose={closeOptions}
      >
        <MenuItem onClick={() => onSeeProfileClick(user)}>Ver perfil</MenuItem>
        <MenuItem onClick={() => onEditUserDataClick(user)}>Editar dados</MenuItem>
        <MenuItem disabled={!user.isDisabled} onClick={() => onEnableClick(user)}>Ativar</MenuItem>
        <MenuItem disabled={user.isDisabled} onClick={() => onDisableClick(user)}>Desativar</MenuItem>
        <MenuItem onClick={() => onDeleteClick(user)}>Deletar</MenuItem>
      </Menu>
    </>
  );
};

export default MenuOptions;

import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import FallbackSpinner from '../components/FallbackSpinner';
import AuthLayout from '../layouts/AuthLayout';
import ForgotPasswordLayout from '../layouts/ForgotPasswordLayout';
import SelectStoreLayout from '../layouts/SelectStoreLayout';
import SignUpStoreLayout from '../layouts/SignUpStoreLayout';
import ClerkPoppySignup from '../pages/ClerkPoppy';
import AdminRoutes from './AdminRoutes';
import StoreRoutes from './StoreRoutes';
import { AuthenticatedRouteWrapper } from './wrappers/AuthenticatedRouteWrapper';
import { RouteWrapper } from './wrappers/RouteWrapper';

const TermsOfUse = lazy(() => import('../pages/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const BePartner = lazy(() => import('../pages/BePartner'));
const ConfirmAccount = lazy(() => import('../pages/ConfirmAccount'));
const ContributorSignup = lazy(() => import('../pages/ContributorSignup'));
const FinancialSignup = lazy(() => import('../pages/FinancialPoppy'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const Login = lazy(() => import('../pages/Login'));
const NotFoundPage = lazy(() => import('../pages/NotFound'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const SelectStore = lazy(() => import('../pages/SelectStore'));
const SignUpStore = lazy(() => import('../pages/SignUpStore/FirstStep'));
const SignUpUserStore = lazy(() => import('../pages/SignUpStore/SecondStep'));
const SignUpComplete = lazy(
  () => import('../pages/SignUpStore/SignUpComplete'),
);

export interface IRoute {
  component: any;
  path: string;
  exact?: boolean;
}

const Routes = () => {
  return (
    <Suspense fallback={<FallbackSpinner />}>
      <BrowserRouter>
        <Switch>
          {/* Super Root route */}
          <Route path="/admin" component={AdminRoutes} />

          {/* Store Routes */}
          <Route path="/painel-estabelecimento" component={StoreRoutes} />

          {/* Auth Store Routes */}
          <AuthenticatedRouteWrapper
            path="/selecionar-conveniado"
            layout={SelectStoreLayout}
            page={SelectStore}
          />

          {/* Open Routes */}
          <RouteWrapper
            path="/seja-parceiro"
            layout={AuthLayout}
            page={BePartner}
          />

          <RouteWrapper
            path="/esqueci-minha-senha"
            layout={ForgotPasswordLayout}
            page={ForgotPassword}
          />

          <RouteWrapper
            path="/redefinir-senha/:token"
            layout={ForgotPasswordLayout}
            page={ResetPassword}
          />

          <RouteWrapper
            path="/"
            exact={true}
            layout={AuthLayout}
            page={Login}
          />
          <RouteWrapper
            path="/registrar-parceiro/:token"
            layout={SignUpStoreLayout}
            page={SignUpStore}
          />
          <RouteWrapper
            path="/politica-privacidade"
            layout={SignUpStoreLayout}
            page={PrivacyPolicy}
          />
          <RouteWrapper
            path="/termos-uso"
            layout={SignUpStoreLayout}
            page={TermsOfUse}
          />
          <RouteWrapper
            path="/registrar-usuario"
            layout={SignUpStoreLayout}
            page={SignUpUserStore}
          />
          <RouteWrapper
            path="/registro-concluido"
            layout={SignUpStoreLayout}
            page={SignUpComplete}
          />
          <RouteWrapper
            path="/cadastro-colaborador/:token"
            layout={SignUpStoreLayout}
            page={ContributorSignup}
          />

          <RouteWrapper
            path="/cadastro-financeiro-poppy/:token"
            layout={SignUpStoreLayout}
            page={FinancialSignup}
          />

          <RouteWrapper
            path="/cadastro-atendente-poppy/:token"
            layout={SignUpStoreLayout}
            page={ClerkPoppySignup}
          />

          <RouteWrapper
            path="/confirmacao-conta/:token"
            layout={SignUpStoreLayout}
            page={ConfirmAccount}
          />

          {/* Not Found */}
          <RouteWrapper path="*" component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default Routes;

import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import AdminLayout from '../layouts/AdminLayout';
import CreateCharity from '../pages/Admin/Charities/CreateCharity';
import ListStores from '../pages/Admin/ListStores';
import ListUsers from '../pages/Admin/ListUsers';
import PrivacyPolicy from '../pages/Admin/PrivacyPolicy';
import Faq from '../pages/Admin/FAQ';
import {
  AuthenticatedRouteWrapper,
  UserRoles,
} from './wrappers/AuthenticatedRouteWrapper';

const Transactions = lazy(() => import('../pages/Admin/Transactions'));
const Contacts = lazy(() => import('../pages/Admin/Contacts'));
const Dashboard = lazy(() => import('../pages/Admin/Dashboard'));
const Charities = lazy(() => import('../pages/Admin/Charities'));
const CharityProfile = lazy(
  () => import('../pages/Admin/Charities/CharityProfile'),
);
const UserDetails = lazy(() => import('../pages/Admin/Users/UserDetails'));

const AdminRoutes: React.FC<{ match: { url: string } }> = ({
  match: { url },
}) => {
  return (
    <Switch>
      <AuthenticatedRouteWrapper
        path={`${url}/`}
        exact
        layout={AdminLayout}
        page={Dashboard}
        redirectUrl="/admin/transacoes"
        roles={[UserRoles.ADMIN, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/transacoes`}
        exact
        layout={AdminLayout}
        page={Transactions}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/instituicoes`}
        exact
        layout={AdminLayout}
        page={Charities}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/instituicao/:id`}
        exact
        layout={AdminLayout}
        page={CharityProfile}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/criar-instituicao`}
        exact
        layout={AdminLayout}
        page={CreateCharity}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/instituicao/:id/editar`}
        layout={AdminLayout}
        page={CreateCharity}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/contatos`}
        layout={AdminLayout}
        page={Contacts}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/usuarios/:cpf`}
        layout={AdminLayout}
        page={UserDetails}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/usuarios`}
        layout={AdminLayout}
        page={ListUsers}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      {/* <AuthenticatedRouteWrapper
        exact
        path={`${url}/colaboradores`}
        layout={AdminLayout}
        page={Associates}
        roles={[UserRoles.ADMIN]}
      /> */}

      <AuthenticatedRouteWrapper
        path={`${url}/conveniados`}
        layout={AdminLayout}
        page={ListStores}
        roles={[UserRoles.ADMIN, UserRoles.CLERK_POPPY, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/politica-privacidade`}
        layout={AdminLayout}
        page={PrivacyPolicy}
        roles={[UserRoles.ADMIN, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/termos-uso`}
        layout={AdminLayout}
        page={PrivacyPolicy}
        roles={[UserRoles.ADMIN, UserRoles.FINANCIAL_POPPY]}
      />
      <AuthenticatedRouteWrapper
        path={`${url}/faq`}
        layout={AdminLayout}
        page={Faq}
        roles={[UserRoles.ADMIN, UserRoles.FINANCIAL_POPPY]}
      />
    </Switch>
  );
};

export default AdminRoutes;

import React, { useState } from 'react';

import { Button } from '@material-ui/core';

import { useAuth } from '../../../../../contexts/Auth';
import LocationMapDialog from '../../Dialogs/LocationMapDialog';
import Map from './Map';

function Location() {
  const {
    user: {
      selectedUserStore: {
        store: { address },
      },
    },
  } = useAuth();

  const point = {
    lat: address.latitude ? parseFloat(address.latitude) : 0,
    lng: address.longitude ? parseFloat(address.longitude) : 0,
  };

  const [editValue, setEditValue] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <div style={{ width: '100%', height: '370px' }}>
          <Map readonly point={point} />
        </div>
        <Button onClick={() => setEditValue(true)}>Editar</Button>
      </div>
      <LocationMapDialog
        onCancel={() => setEditValue(false)}
        onConfirm={() => setEditValue(false)}
        open={editValue}
      ></LocationMapDialog>
    </>
  );
}

export default Location;

import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const useStyles = makeStyles(() =>
  createStyles({
    mapContainer: {
      width: '100%',
      height: '370px',
      '& a': {
        display: 'none !important',
      },
      '& .gm-style-cc': {
        display: 'none',
      },
    },
  }),
);

interface MapProps {
  readonly?: boolean;
  point: google.maps.LatLng | google.maps.LatLngLiteral;
  onChangeLocation?(point: google.maps.LatLngLiteral): void;
}

const readonlyOptions = {
  draggable: false,
  draggableCursor: 'default',
};

const defaultOptions = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  scaleControl: false,
  zoomControl: false,
  panControl: true,
};

function Map({ point, onChangeLocation, readonly = false }: MapProps) {
  const classes = useStyles();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY ?? '',
  });

  const options = readonly
    ? {
        ...defaultOptions,
        ...readonlyOptions,
      }
    : defaultOptions;

  const renderMap = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <GoogleMap
          mapContainerClassName={classes.mapContainer}
          center={point}
          clickableIcons={false}
          options={options}
          zoom={12}
          onClick={(e) => {
            if (onChangeLocation) onChangeLocation(e.latLng.toJSON());
          }}
        >
          <Marker clickable={false} position={point}></Marker>
        </GoogleMap>
      </div>
    );
  };

  if (loadError) {
    return <div>Ocorreu um erro ao carregar o mapa.</div>;
  }

  return isLoaded ? renderMap() : <div>Carregando...</div>;
}

export default Map;

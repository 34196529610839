import React from 'react';

interface CurrencyValueProps {
  children: number;
}

const CurrencyValue: React.FC<CurrencyValueProps> = ({ children }) => (
  <>
    {children
      ? children.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
      : 'R$ 0,00'}
  </>
);

export default CurrencyValue;

import React from 'react';

import {
  Avatar,
  AvatarProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    big: {
      width: '140px',
      height: '140px',
    },
    medium: {
      width: '84px',
      height: '84px',
    },
    small: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }),
);

interface ProfileAvatarProps extends AvatarProps {
  size?: 'big' | 'medium' | 'small';
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  size = 'big',
  ...props
}) => {
  const classes = useStyles();

  return <Avatar {...props} className={classes[size]} />;
};

export default ProfileAvatar;

import React, { useEffect, useState } from 'react';
import { MdMenu } from 'react-icons/md';

import {
  createStyles,
  IconButton,
  isWidthUp,
  makeStyles,
  Theme,
  withWidth,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { useAuth } from '../../../contexts/Auth';
import { Container } from './styles';
import UserMenu from '../../UserMenu';
import BellNotification from '../../BellNotification';
import { UserRoles } from '../../../routes/wrappers/AuthenticatedRouteWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
);

const Header: React.FC<{
  handleDrawerToggle: any;
  drawerWidth: number;
  width: Breakpoint;
}> = ({ handleDrawerToggle, drawerWidth, width }) => {
  const classes = useStyles();
  const { signed, user } = useAuth();
  const [logoLink, setLogoLink] = useState('/');

  useEffect(() => {
    signed ? setLogoLink('/admin') : setLogoLink('/');
  }, [logoLink, signed]);

  return (
    <Container
      drawerwidth={drawerWidth}
      style={{
        justifyContent: isWidthUp('sm', width) ? 'flex-end' : 'space-between',
      }}
    >
      {!isWidthUp('sm', width) && (
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MdMenu size={24} color="#000" style={{ marginLeft: '16px' }} />
        </IconButton>
      )}
      {/* <BellNotification data={[
        { title: 'string', message: 'string', route: 'string' },
        { title: 'string', message: 'string', route: 'string' }]}></BellNotification> */}
      ({!user.roles.includes(UserRoles.FINANCIAL_POPPY) ? <BellNotification /> : null})
      <UserMenu></UserMenu>
    </Container>
  );
};

export default withWidth()(Header);

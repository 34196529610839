import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '980px',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '95%',
      },
    },
    headContainer: {
      backgroundColor: theme.palette.background.paper,
      marginBottom: '24px',
    },
    head: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '24px',
      paddingBottom: '30px',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '24px',
      },
    },
    heading: {
      color: theme.palette.primary.main,
      fontSize: '18px',
      textAlign: 'left',
    },
    menuItemSelected: {
      "&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "5px 5px 5px 5px",
        boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
        fontSize: '20px',
        textAlign: 'center',
        color: '#ffff',
      }
    },
    menuItem: {
      borderRadius: "5px 5px 5px 5px",
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '5px',
      '&:hover': {
        borderRadius: "5px 5px 5px 5px",
        fontSize: '20px',
        textAlign: 'center',
      },
    },
    expand_icon: {
      color: theme.palette.primary.main,
    }
  })
);

export const Container = styled.div`
  display: flex;
  padding: 12px;
  max-width: 1000px;
  margin: 0 auto;
  max-height: calc(100vh - 230px);
`;

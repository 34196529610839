import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
  background: var(--green-bg);
  @media (max-width: 767px) {
    padding-top: 42px;
    align-items: flex-start;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 900px) {
    align-items: flex-start;
  }
`;

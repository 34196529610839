import React, { useCallback } from 'react';

import UserTransactions from '../../../components/UserTransactions';
import { UserTransactionsParams } from '../../../components/UserTransactions/types';
import useClient from '../../../hooks/useClient';
import {
  ClientTransactionsData,
  getClientTransactions,
} from '../../../services/StoreAdmin/Transactions';
import { Transaction } from '../../../types/transactions';
import { getUserTransactionsAvailableDates } from '../../../services/Store';
import { useAuth } from '../../../contexts/Auth';

const ClientTransactions: React.FC = () => {
  const client = useClient();
  const { user } = useAuth();

  const fetchClientTransactions = useCallback(
    async ({ actor, ...params }: UserTransactionsParams) => {
      const body: ClientTransactionsData = {
        ...params,
        clientId: actor.id,
      };

      return getClientTransactions(body);
    },
    [],
  );

  const fetchAvailableDates = useCallback(async () => {
    if (client?.id)
      return getUserTransactionsAvailableDates(
        user.selectedUserStore.store.id,
        client.id,
      );
    return [];
  }, [user.selectedUserStore, client]);

  if (!client) {
    return <div />;
  }

  return (
    <UserTransactions
      fetchAvailableDate={fetchAvailableDates}
      fetchTransactions={fetchClientTransactions}
      actor={client}
      tableOptions={{
        tableHeadersLabel: {
          first: 'Colaborador',
        },
        firstTableDataHandler: (transaction: Transaction) => {
          const contributor = transaction.userStore.user;
          return `${contributor.firstName} ${contributor.surname}`;
        },
      }}
    ></UserTransactions>
  );
};

export default ClientTransactions;

import { ApiResponse } from './../../types/api-response';
import { ContributorInvite, StoreInvite } from './../../types/store';
import api from '../api';
import { parseMaskedPhone } from '../../utils/parseMaskedPhone';

export const GetStoreTypes = async () => {
  try {
    const { data } = await api.get('/store/types');
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const CreateStore = async (storeData: any) => {
  try {
    const { data } = await api.post('/store', storeData);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const CreateUser = async (user: any) => {
  try {
    user.phone = parseMaskedPhone(user.phone);
    user.confirmationType = 'e';
    const { data } = await api.post('/authentication/register', user);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const CreateClerk = async (user: any) => {
  try {
    user.phone = parseMaskedPhone(user.phone);
    user.confirmationType = 'e';
    const { data } = await api.post('/authentication/register-clerk', user);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const CreateFinancial = async (user: any) => {
  try {
    user.phone = parseMaskedPhone(user.phone);
    user.confirmationType = 'e';
    const { data } = await api.post('/authentication/register-financial', user);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const FetchContributorInvite = async (
  token: string,
): Promise<ApiResponse<ContributorInvite>> => {
  try {
    const { data } = await api.get(`/store/contributor/${token}`);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const FetchInvite = async (token: string): Promise<StoreInvite> => {
  try {
    const { data } = await api.get<ApiResponse<StoreInvite>>(`/invite/${token}`);
    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';

import { CircularProgress, Paper, TablePagination } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';

import coin from '../../../assets/images/coin.svg';
import { Pagination } from '../../../types/api-response';
import YearMonthPicker from '../../Admin/YearMonthPicker';
import SearchField from '../../Forms/SearchField';
import EmptyTable, { EmptyTableProps } from '../EmptyTable';
import useStyles from './styles';

type NotClickableEmptyTableOptions = Pick<
  EmptyTableProps,
  'title' | 'actionText' | 'subtitle' | 'imageSrc' | 'style'
>;

export interface TableDataTypes {
  sideHeader?: React.ReactNode;
  shouldShowSideHeader?: boolean;
  hasTableData: boolean;
  pagination?: Pagination;
  loadingData: boolean;
  searchable?: boolean;
  dateFiltrable?: boolean;
  title?: string;
  searchableName?: string;
  filtrableDates?: { month: number; year: number }[];
  page: number;
  onSearchChange?(event: any): void;
  onChangePage(page: number): void;
  onSelectedDateChange?(date: Date): void;
  emtpyTableOptions?: Partial<NotClickableEmptyTableOptions>;
  onEmptyTableActionClick?(): void;
}

const defaultEmptyTableOptions: NotClickableEmptyTableOptions = {
  style: {
    // All view heigth - header - calendar filters - cards - margins
    height: 'calc(100vh - 64px - 137px - 132px - 92px)',
  },
  imageSrc: coin,
  title: 'Ainda não foi adicionando nenhum troco',
  subtitle: 'Está sem troco? Indique Poppy aos seus clientes',
  actionText: 'Adicionar',
};

const FiltrableTableContainer: React.FC<TableDataTypes> = ({
  hasTableData,
  pagination,
  loadingData,
  searchable = false,
  dateFiltrable = false,
  title = 'Últimos trocos',
  onChangePage,
  onSearchChange,
  page,
  searchableName,
  filtrableDates = [],
  onSelectedDateChange,
  emtpyTableOptions = {},
  children,
  sideHeader,
  shouldShowSideHeader = false,
  onEmptyTableActionClick,
}) => {
  const classes = useStyles();

  const [dateFiltrableMinDate, setDateFiltrableMinDate] = useState<Date>();
  const [dateFiltrableMaxDate, setDateFiltrableMaxDate] = useState<Date>();

  const emtpyTableProps: NotClickableEmptyTableOptions = {
    ...defaultEmptyTableOptions,
    ...emtpyTableOptions,
  };

  const handleEmptyTableActionClick = useCallback(() => {
    if (onEmptyTableActionClick) {
      onEmptyTableActionClick();
    }
  }, [onEmptyTableActionClick]);

  useEffect(() => {
    const [minDate] = filtrableDates;
    const maxDate = filtrableDates[filtrableDates.length - 1];

    if (minDate) {
      setDateFiltrableMinDate(new Date(minDate.year, minDate.month - 1));
      setDateFiltrableMaxDate(new Date(maxDate.year, maxDate.month - 1));
    }
  }, [filtrableDates]);

  if (loadingData) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          paddingTop: '64px',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {/* Can see the table if has data or has some search or has any date to filter */}
      {(hasTableData || searchableName || filtrableDates.length > 0) && (
        <>
          {title.length > 0 && <h3 className={classes.title}>{title}</h3>}
          <TableContainer component={Paper} className={classes.tableContainer}>
            {(searchable || shouldShowSideHeader || dateFiltrable) && (
              <div className={clsx(classes.tableSearchHeader)}>
                <div className={classes.tableHeaderFilters}>
                  {dateFiltrable && (
                    <YearMonthPicker
                      maxDate={dateFiltrableMaxDate}
                      minDate={dateFiltrableMinDate}
                      onSelectedDateChange={onSelectedDateChange}
                    ></YearMonthPicker>
                  )}
                  {searchable && (
                    <div className={classes.searchFieldContainer}>
                      <SearchField
                        fullWidth
                        onChange={onSearchChange}
                        placeholder="Buscar cliente"
                      ></SearchField>
                    </div>
                  )}
                </div>
                {!!sideHeader && sideHeader}
              </div>
            )}
            {children}
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={pagination?.totalItems || 0}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              rowsPerPage={10}
              page={page || 0}
              onChangePage={(_, page) => onChangePage(page)}
            />
          </TableContainer>
        </>
      )}
      {/* Data is empty, has no one search and has no date to filter  */}
      {!hasTableData &&
        !searchableName &&
        filtrableDates.length === 0 &&
        !loadingData && (
          <EmptyTable
            {...emtpyTableProps}
            onActionClick={handleEmptyTableActionClick}
          ></EmptyTable>
        )}
    </div>
  );
};

export default FiltrableTableContainer;

import { getMonth, getYear } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../../contexts/Auth';
import { Pagination } from '../../types/api-response';
import { IAvailableDate, Transaction } from '../../types/transactions';
import { transactionsWithFormattedDate } from '../../utils/transactionsWithFormattedDate';
import FiltrableTransactions from '../FiltrableTransactions';
import TableTransactions from '../TableTransactions';
import TotalChangeCard from '../TotalChangeCard';
import UserCard from '../UserCard';
import { UserTransactionsProps } from './types';

const UserTransactions: React.FC<UserTransactionsProps> = ({
  actor,
  fetchAvailableDate,
  fetchTransactions,
  tableOptions,
  breadcrumbsOptions = {
    data: [
      {
        name: 'Transações',
        route: '/painel-estabelecimento/transacoes',
      },
      {
        name: `${actor?.firstName} ${actor?.surname}`,
      },
    ],
  },
}) => {
  const { isUserStoreAdmin, user } = useAuth();

  // const { setLoading, isLoading } = useLoading();
  const [page, setPage] = useState(0);
  const [availableDates, setAvailableDates] = useState<IAvailableDate[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [month, setMonth] = useState(getMonth(new Date()));
  const [year, setYear] = useState(getYear(new Date()));

  const [pagination, setPagination] = useState<Pagination>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [totalChange, setTotalChange] = useState(0);

  useEffect(() => {
    async function fetchStoreTransactions() {
      setLoading(true);
      try {
        const storeId = user.selectedUserStore.store.id;

        const data = await fetchTransactions({
          actor,
          storeId,
          month: month + 1,
          year,
          page: page + 1,
        });

        if (data.items) {
          setPagination({ ...data.meta, currentPage: page });
          setTotalChange(data.totalChange);
          setTransactions(transactionsWithFormattedDate(data.items));
        }
      } finally {
        setLoading(false);
      }
    }
    fetchStoreTransactions();
  }, [
    actor,
    fetchTransactions,
    month,
    page,
    setLoading,
    user.selectedUserStore.store.id,
    year,
  ]);

  useEffect(() => {
    async function getAvailableDates() {
      const data = await fetchAvailableDate();
      setAvailableDates(data);
    }
    getAvailableDates();
  }, [fetchAvailableDate]);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleMonthChange = (e: any, value: any) => {
    setMonth(value);
  };

  const handleYearChange = (date: Date) => {
    const months = availableDates.filter(
      (dates) => dates.year === getYear(date),
    );
    if (!!months.length) {
      setMonth(months[months.length - 1].month - 1);
    }
    setSelectedDate(date);
    setYear(getYear(new Date(date)));
  };

  return (
    <FiltrableTransactions
      availableDates={availableDates}
      selectedDate={selectedDate}
      selectedMonth={month}
      onMonthChange={handleMonthChange}
      onYearChange={handleYearChange}
      breadcrumbsOptions={breadcrumbsOptions}
      infoCards={
        <>
          {actor && <UserCard user={actor} />}
          <TotalChangeCard
            style={!isUserStoreAdmin() ? { alignItems: 'center' } : {}}
            totalChange={totalChange}
          />
        </>
      }
    >
      <TableTransactions
        loadingData={isLoading}
        tableData={transactions}
        showAddChange={false}
        {...tableOptions}
        showMenuOptions={false}
        pagination={pagination}
        onChangePage={handleChangePage}
        page={page}
      />
    </FiltrableTransactions>
  );
};

export default UserTransactions;

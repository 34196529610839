import React, { useState } from 'react';
import { MdSearch } from 'react-icons/md';

import {
  createStyles,
  makeStyles,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelOffset: {
      transform: 'translate(44px, 20px) scale(1)',
    },
    searchIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

const SearchField: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles();
  const [shrink, setShrink] = useState(false);

  return (
    <TextField
      {...props}
      variant="outlined"
      onFocus={() => setShrink(true)}
      onBlur={(e) => (!!e.target.value ? setShrink(true) : setShrink(false))}
      InputLabelProps={{
        classes: { root: classes.labelOffset },
        shrink,
      }}
      InputProps={{
        startAdornment: <MdSearch className={classes.searchIcon} size={24} />,
      }}
    />
  );
};

export default SearchField;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@material-ui/core';

export const LinkContainer = styled(Link)`
  text-decoration: none;
  text-align: center;
`;

export const Container = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px 8px;
  height: 64px;
  width: 136px;
  color: var(--white);
  @media (max-width: 600px) {
    height: 64px;
    width: 120px;
  }

  span {
    display: block;
    text-transform: capitalize;
    line-height: 1.2;
  }

  &:hover,
  &.is-active {
    background: var(--green-bg);
    color: var(--menu-bg);
  }
`;

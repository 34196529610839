import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface BadgeTabLabelProps {
  count?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    badgeContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20px',
      width: '20px',
      background: '#FF8264',
      borderRadius: '50%',
      color: '#FFF',
      marginLeft: '4px',
    },
    badge: { fontSize: '11px', fontWeight: 600 },
  }),
);

const BadgeTabLabel: React.FC<BadgeTabLabelProps> = ({ children, count }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span>{children}</span>
      {count !== undefined && count > 0 && (
        <div className={classes.badgeContainer}>
          <span className={classes.badge}>{count}</span>
        </div>
      )}
    </div>
  );
};

export default BadgeTabLabel;

export function parseMaskedPhone(phone: string) {
  let areaCodeMatch = phone.match(/\([\d]+\)/);
  let numberMatch = phone.match(/\d{4,5}-\d{4}/);

  if (!areaCodeMatch || !numberMatch) {
    return {
      areaCode: null,
      number: null,
    };
  }

  const areaCode = areaCodeMatch[0].replace('(', '').replace(')', '');
  const number = numberMatch[0].replace('-', '');

  return {
    areaCode,
    number,
  };
}

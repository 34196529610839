import React from 'react';

import logoGreen from '../../assets/images/logo.png';
import logoBlack from '../../assets/images/logo-black.png';

interface LogoProps {
  color: 'black' | 'green';
}

const Logo: React.FC<LogoProps> = ({ color }) => {
  return (
    <img src={color === 'black' ? logoBlack : logoGreen} alt="poppy logo" />
  );
};

export default Logo;

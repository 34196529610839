import React from 'react';

import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  text: string;
  loadingText: string;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  text,
  loadingText,
  ...rest
}) => {
  return (
    <Button {...rest} disabled={rest.disabled ?? loading}>
      {loading && <CircularProgress color="inherit" size={20} />}
      <span style={loading ? { marginLeft: 8 } : {}}>
        {loading ? loadingText : text}
      </span>
    </Button>
  );
};

export default LoadingButton;

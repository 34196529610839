import React from 'react';

import { PaperProps } from '@material-ui/core';

import ProfileCard from '../ProfileCard';
import CpfValue from '../CpfValue';

interface UserCardProps extends PaperProps {
  user: {
    firstName: string;
    surname: string;
    cpf?: string;
    avatar?: string;
  };
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
  return (
    <ProfileCard
      avatar={user.avatar}
      name={`${user.firstName} ${user.surname}`}
      document={user.cpf ? <CpfValue>{user.cpf}</CpfValue> : undefined}
    ></ProfileCard>
  );
};

export default UserCard;

import React from 'react';

import { Container, LinkContainer } from './styles';

const MenuItem: React.FC<{ label: string; to: string; isActive?: boolean }> = ({
  children,
  label,
  isActive = false,
  to,
}) => {
  return (
    <LinkContainer to={to}>
      <Container className={isActive ? 'is-active' : ''}>
        {children}
        <span>{label}</span>
      </Container>
    </LinkContainer>
  );
};

export default MenuItem;

import React from 'react';

import { createStyles, makeStyles, PaperProps, Theme } from '@material-ui/core';

import InfoCard from '../InfoCard';
import ProfileAvatar from '../ProfileAvatar';
import { downloadFileUrl } from '../../services/File';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: `${theme.spacing(3)}px`,
    },
    name: {
      marginBlockStart: 0,
      marginBlockEnd: '3px',
      fontSize: `${theme.spacing(3)}px`,
      fontWeight: 500,
    },
    document: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      fontSize: `${theme.spacing(2)}px`,
      color: 'var(--gray)',
    },
  }),
);

interface ProfileCardProps extends PaperProps {
  name: string;
  avatar?: string;
  document?: string | React.ReactNode;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  avatar,
  document,
  ...props
}) => {
  const classes = useStyles();
  return (
    <InfoCard className={classes.container} {...props}>
      <ProfileAvatar
        size="medium"
        alt={name}
        src={avatar ? downloadFileUrl(avatar) : undefined}
      />
      <div>
        <h2 className={classes.name}>{name}</h2>
        {!!document && <p className={classes.document}>{document}</p>}
      </div>
    </InfoCard>
  );
};

export default ProfileCard;

import React, { useCallback } from 'react';

import {
  Backdrop,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Form } from '@unform/web';

import Input from '../../components/Forms/Input';
import { PhoneMask } from '../../utils/Masks/Phone';
import { useToast } from '../../contexts/toast';
import useFormValidation from '../../hooks/useFormValidation';
import { UpdateUser } from '../../services/User';
import Yup from '../../validators/Yup';
import useStyles from './styles';
import { Phone, User } from '../../types/user';
import MaskedInput from '../../components/Forms/MaskedInput';
import { parseMaskedPhone } from '../../utils/parseMaskedPhone';
import { useHistory } from 'react-router-dom';

const schema = Yup.object().shape({
  firstName: Yup.string().required('O campo é obrigatório'),
  surname: Yup.string().required('O campo é obrigatório'),
  email: Yup.string().email('Email inválido').required('O campo é obrigatório'),
  phone: Yup.string().required('O campo é obrigatório'),
});

interface EditUserDialogProps {
  onUpdateUser(user: User): void;
  open: boolean;
  onClose(): void;
  user: User | undefined;
}

const EditUserDialog: React.FC<EditUserDialogProps> = ({
  onUpdateUser,
  open,
  onClose,
  user,
}) => {
  const classes = useStyles();

  const { showSuccess, showError } = useToast();

  const {
    formRef,
    handleBlur,
    validateForm,
    handleValidationErrors,
  } = useFormValidation(schema);
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        await validateForm(data, {
          abortEarly: false,
        });

        const userToUpdate = user as User;
        const parsedPhone = parseMaskedPhone(data.phone) as Phone;

        userToUpdate.email = data.email;
        userToUpdate.firstName = data.firstName;
        userToUpdate.surname = data.surname;
        userToUpdate.email = data.email;
        if (
          `${parsedPhone.areaCode}${parsedPhone.number}` !==
          `${userToUpdate.phone.areaCode}${userToUpdate.phone.number}`
        ) {
          userToUpdate.phone = parseMaskedPhone(data.phone) as Phone;
        }
        const response = await UpdateUser(userToUpdate);
        onUpdateUser(response.data);

        showSuccess(response.message ?? '');
      } catch (err) {
        handleValidationErrors(err);

        if (err.response) {
          showError(err.response.data.message);
        }
      } 
    },
    [validateForm, showSuccess, handleValidationErrors, showError],
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <IconButton disableFocusRipple disabled disableTouchRipple>
              <Close className={classes.workAround} />
            </IconButton>
            <h2 className={classes.modalTitle} id="transition-modal-title">
              Atualizar usuário {user?.firstName}
            </h2>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.content}>
            <Form
              ref={formRef}
              initialData={{
                firstName: user?.firstName,
                surname: user?.surname,
                email: user?.email,
                phone: `${user?.phone.areaCode}${user?.phone.number}`,
                cpf: user?.cpf,
              }}
              className="w-100 be-partner-form"
              onSubmit={handleSubmit}
            >
              <h3>Informações</h3>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    name="firstName"
                    type="text"
                    label="Nome"
                    onBlur={() => handleBlur('firstName')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="surname"
                    type="text"
                    label="Sobrenome"
                    onBlur={() => handleBlur('surname')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="email"
                    type="text"
                    label="E-mail"
                    onBlur={() => handleBlur('email')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MaskedInput
                    name="phone"
                    mask={PhoneMask}
                    label="Telefone"
                    onBlur={() => handleBlur('phone')}
                  />
                </Grid>
              </Grid>
              <div className={classes.actionButtons}>
                <Button className={classes.button} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Atualizar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EditUserDialog;

import React from 'react';

import {
  createStyles,
  makeStyles,
  Table as MaterialTable,
  Theme,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHead: {
      background: '#F5F5F5',
      borderBottom: '#F5F5F5',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);

interface TableContainerProps {
  header: React.ReactNode;
}

const TableContainer: React.FC<TableContainerProps> = ({
  header,
  children,
}) => {
  const classes = useStyles();

  return (
    <MaterialTable>
      <TableHead className={classes.tableHead}>{header}</TableHead>
      <TableBody>{children}</TableBody>
    </MaterialTable>
  );
};

export default TableContainer;

import { ApiResponse, PageableApiResponse, Pagination } from '../../types/api-response';
import { Invite } from '../../types/invite';
import { Transaction } from '../../types/transactions';
import { User } from '../../types/user';
import api from '../api';

export const SearchUserByCpf = async (cpf: string): Promise<User> => {
  const parsedCpf = cpf.replace(/\D/g, '');
  const { data } = await api.get<User>(`/user/${parsedCpf}`);

  return data;
};

export interface AddUserData {
  name: string;
  email: string;
  role: string;
}

export const AddUser = async (
  body: AddUserData
): Promise<ApiResponse<Invite>> => {
  try {
    const { data } = await api.post<ApiResponse<Invite>>(
      `/authentication/pre-register`,
      {
        ...body,
        role: parseInt(body.role),
      },
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const UpdateUser = async (
  user: User
): Promise<ApiResponse<User>> => {
  try {
    const { data } = await api.put<ApiResponse<User>>(
      `/user/${user.id}`,
      {
        ...user
      },
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const DeleteUser = async (
  id: number
): Promise<ApiResponse<User>> => {
  try {
    const { data } = await api.delete<ApiResponse<User>>(
      `/user/${id}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const PatchUser = async (
  user: User
): Promise<ApiResponse<User>> => {
  try {
    const { data } = await api.patch<ApiResponse<User>>(
      `/user/${user.id}`,
      user
    );

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const listUsers = async (
  page?: number,
  limit?: number,
  name?: string,
  partialCpf?: string,
): Promise<PageableApiResponse<User>> => {
  const { data } = await api.get<PageableApiResponse<User>>(`/user`, {
    params: {
      page,
      limit,
      name,
      partialCpf
    },
  });

  return data;
};

interface GetUserTransactionsBody {
  user: User;
  month: number;
  year: number;
  page?: number;
  limit?: number;
  companyName?: string;
}

interface UserTransactionsResponse {
  items: Transaction[];
  meta: Pagination;
  totalChange: number;
}

interface AvailableDate {
  month: number;
  year: number;
}

export const getUserTransactions = async (
  body: GetUserTransactionsBody,
): Promise<UserTransactionsResponse> => {
  const { user, ...params } = body;
  const { data } = await api.get<UserTransactionsResponse>(
    `/user/${user.id}/transactions`,
    { params: { ...params, limit: 10 } },
  );

  return data;
};

export const getUserAvailableDates = async (
  user: User,
): Promise<AvailableDate[]> => {
  const { data } = await api.get<AvailableDate[]>(
    `/user/${user.id}/transactions/available-dates`,
  );

  return data;
};

interface GetUserTotalChangeResponse {
  totalChange: number;
}

export const getUserTotalChange = async (
  user: User,
): Promise<GetUserTotalChangeResponse> => {
  const { data } = await api.get<GetUserTotalChangeResponse>(
    `/user/${user.id}/total-change`,
  );

  return data;
};
import React, { useEffect, useState } from 'react';
import { MdStoreMallDirectory } from 'react-icons/md';

import TabPageContainer from '../../../components/Admin/TabPageContainer';
import BadgeTabLabel from '../../../components/Admin/TabPageContainer/BadgeTabLabel';
import ListAvailableStores from '../../../components/Store/ListAvailableStores';
import ListPendingStores from '../../../components/Store/ListPendingStores';
import { countPendingStores } from '../../../services/Store';

const PendingLabel: React.FC<{ count: number }> = ({ count }) => {
  return <BadgeTabLabel count={count}>Pendentes</BadgeTabLabel>;
};

const ListStores: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [pendingCount, setPendingCount] = useState(0);

  useEffect(() => {
    async function fetchPendingStores() {
      try {
        setLoading(true);
        const { pendingInvites } = await countPendingStores();

        setPendingCount(pendingInvites);
      } finally {
        setLoading(false);
      }
    }
    fetchPendingStores();
  }, []);

  if (isLoading) {
    return <h1 style={{ textAlign: 'center' }}>Carregando conveniados...</h1>;
  }

  return (
    <TabPageContainer
      icon={MdStoreMallDirectory}
      tabs={[<ListAvailableStores />, <ListPendingStores />]}
      tabsLabel={['Todas', <PendingLabel count={pendingCount} />]}
      title="Conveniados"
    ></TabPageContainer>
  );
};

export default ListStores;

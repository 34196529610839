import React, { useCallback, useState } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import ChangeableAvatar from '../../../components/ChangeableAvatar';
import CnpjValue from '../../../components/CnpjValue';
import EditProfileContainer from '../../../components/EditProfileContainer';
import { useAuth } from '../../../contexts/Auth';
import { useToast } from '../../../contexts/toast';
import { updateStore } from '../../../services/Store';
import { File } from '../../../types/file';
import AddressComplementField from './EditableFields/AddressComplement';
import AddressNumberField from './EditableFields/AddressNumber';
import FantasyNameField from './EditableFields/FantasyName';
import Location from './EditableFields/Location';
import PhoneField from './EditableFields/Phone';
import WebsiteField from './EditableFields/Website';
import ZipCodeField from './EditableFields/ZipCode';
import Fieldset from './Fieldset';
import LabelValue from './LabelValue';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
  }),
);

const Profile: React.FC = () => {
  const {
    user: {
      selectedUserStore: { store },
    },
    updateSelectedStore,
  } = useAuth();

  const [updatingAvatar, setUpdatingAvatar] = useState(false);

  const { showError, showSuccess } = useToast();
  const classes = useStyles();

  const handleChangeAvatar = useCallback(
    async (avatar: File) => {
      try {
        setUpdatingAvatar(true);
        const updatedStore = await updateStore(store.id, {
          photo: {
            id: avatar.id,
          },
        });

        updateSelectedStore(updatedStore);

        showSuccess('Foto de perfil atualizada com sucesso!');
      } catch (err) {
        if (err.response) {
          showError(err.response.data.message);
        }
      } finally {
        setUpdatingAvatar(false);
      }
    },
    [showError, showSuccess, store.id, updateSelectedStore],
  );

  return (
    <EditProfileContainer contentStyle={classes.container} title="Perfil Conveniado">
      <ChangeableAvatar
        imageHash={store.avatar}
        onChangeAvatar={handleChangeAvatar}
        showSuccessMessage={false}
        loading={updatingAvatar}
      />
      <Fieldset title="Conveniado">
        <LabelValue label="CNPJ">
          <CnpjValue>{store.cnpj}</CnpjValue>
        </LabelValue>
        <LabelValue label="Razão social">{store.companyName}</LabelValue>
        <FantasyNameField />
        <PhoneField />
        <WebsiteField />
      </Fieldset>
      <Fieldset title="Endereço">
        <ZipCodeField />
        <LabelValue label="Cidade">{store.address.city}</LabelValue>
        <LabelValue label="Estado">
          {store.address.state.description}
        </LabelValue>
        <LabelValue label="Bairro">{store.address.neighborhood}</LabelValue>
        <LabelValue label="Endereço">{store.address.address}</LabelValue>
        <AddressNumberField />
        <AddressComplementField />
      </Fieldset>
      <Fieldset title="Localização">
        <Location />
      </Fieldset>
    </EditProfileContainer>
  );
};

export default Profile;

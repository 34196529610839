import { TextField, TextFieldProps } from '@material-ui/core';
import React, { useEffect, useRef, useState, ChangeEvent } from 'react';

import { useField } from '@unform/core';

type InputProps = TextFieldProps & {
  name: string;
  label?: string;
  showError?: boolean;
};

const Input: React.FC<InputProps> = ({
  name,
  defaultValue,
  id,
  label,
  showError = true,
  onChange,
  onBlur,
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    fieldName,
    defaultValue: defaultFieldValue,
    registerField,
    error,
  } = useField(name);

  const defaultInputValue = defaultValue ?? defaultFieldValue;
  const [shrink, setShrink] = useState<boolean>(!!defaultInputValue);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        clearValue(ref, resetValue: string) {
          const newValue = resetValue ?? defaultInputValue ?? '';
          ref.value = newValue;
          setShrink(!!newValue);
        },
        setValue(ref: HTMLInputElement, value: string) {
          if (ref) {
            const newValue = value ?? '';
            ref.value = newValue;
            setShrink(!!newValue);
          }
        },
      });
    }

    if (defaultInputValue && inputRef?.current) {
      inputRef.current.value = defaultInputValue;
      setShrink(!!defaultInputValue);
    }
  }, [fieldName, registerField, defaultInputValue, shrink]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.value) {
      setShrink(true);
    } else {
      setShrink(false);
    }

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <TextField
      {...restProps}
      style={{ marginBottom: '16px' }}
      InputProps={{
        ...restProps.InputProps,
        notched: shrink,
      }}
      InputLabelProps={{
        ...restProps.InputLabelProps,
        shrink,
      }}
      variant="outlined"
      defaultValue={defaultFieldValue}
      inputRef={inputRef}
      fullWidth
      onChange={handleChange}
      disabled={restProps.disabled}
      label={label}
      name={name}
      error={!!error && showError}
      onFocus={() => setShrink(true)}
      onBlur={(e) => {
        if (!inputRef.current?.value) {
          setShrink(false);
        }
        if (onBlur) {
          onBlur(e);
        }
      }}
      helperText={!!error && showError ? error : null}
    />
  );
};

export default React.memo(Input);

import React, { useCallback, useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { Form } from '@unform/web';

import DialogContainer from '../../../../../components/DialogContainer';
import MaskedInput from '../../../../../components/Forms/MaskedInput';
import { useAuth } from '../../../../../contexts/Auth';
import { useToast } from '../../../../../contexts/toast';
import useFormValidation from '../../../../../hooks/useFormValidation';
import { updateStore } from '../../../../../services/Store';
import { searchAddressByZipCode } from '../../../../../services/ZipCode';
import { Address } from '../../../../../types/common';
import { CEPMask } from '../../../../../utils/Masks/CEP';
import Yup from '../../../../../validators/Yup';
import AddressText from '../../../../../components/AddressText';

interface FormData {
  zipCode: string;
}

interface ZipCodeDialogProps {
  open: boolean;
  onCancel(): void;
  onConfirm(address: Address): void;
}

const schema = Yup.object().shape({
  zipCode: Yup.string()
    // Counting min and max with mask. E.g.: 72603-212
    .length(9, 'CEP inválido.')
    .required('O CEP é obrigatório.'),
});

function ZipCodeDialog({ onConfirm, ...rest }: ZipCodeDialogProps) {
  const {
    user: {
      selectedUserStore: { store },
    },
    updateSelectedStore,
  } = useAuth();

  const { showError, showSuccess } = useToast();
  const {
    formRef,
    validateField,
    handleBlur,
    validateForm,
    handleValidationErrors,
  } = useFormValidation(schema);

  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(store.address.zipCode);

  const [updatedAddress, setUpdatedAddress] = useState<Address>(store.address);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setUpdating(true);
        setDirty(false);

        await validateForm(data, { abortEarly: false });

        const updatedStore = await updateStore(store.id, {
          address: updatedAddress,
        });

        updateSelectedStore(updatedStore);

        onConfirm(updatedAddress);
        showSuccess('Endereço atualizado com sucesso!');
      } catch (err) {
        const hasErrors = handleValidationErrors(err);
        setDirty(true);
        console.log('Err', hasErrors);
        if (err.response) {
          showError(err.response.data.message);
        }
      } finally {
        setUpdating(false);
      }
    },
    [
      handleValidationErrors,
      onConfirm,
      showError,
      showSuccess,
      store.id,
      updateSelectedStore,
      updatedAddress,
      validateForm,
    ],
  );

  const handleBlurCep = useCallback(
    async (field: string) => {
      const invalid = await validateField(field);
      if (!invalid) {
        try {
          setLoading(true);
          const cep = formRef?.current?.getFieldValue(field);
          const data = await searchAddressByZipCode(cep);

          setUpdatedAddress({
            zipCode: cep,
            address: data.street,
            city: data.city,
            neighborhood: data.neighborhood,
            state: {
              uf: data.state,
            },
          } as Address);

          setInvalid(false);
        } catch (error) {
          setInvalid(true);
          formRef.current?.setFieldError(field, 'CEP Inválido');
        } finally {
          setLoading(false);
        }
      }
    },
    [validateField, formRef, setLoading],
  );

  useEffect(() => {
    setDirty(updatedValue.replace('-', '') !== store.address.zipCode);
  }, [store.address.zipCode, updatedValue]);

  useEffect(() => {
    if (updatedValue.length === 9) {
      formRef.current?.setErrors({});
      if (updatedValue.replace('-', '') !== store.address.zipCode) {
        handleBlurCep('zipCode');
      }
    }
  }, [formRef, handleBlurCep, store.address.zipCode, updatedValue]);

  return (
    <DialogContainer
      {...rest}
      onConfirm={() => formRef.current?.submitForm()}
      disabledConfirmBtn={!dirty || invalid}
      isLoading={updating}
      title="Alterar endereço"
    >
      <Form
        ref={formRef}
        initialData={{ zipCode: store.address.zipCode }}
        onSubmit={handleSubmit}
      >
        <MaskedInput
          name="zipCode"
          mask={CEPMask}
          label="CEP"
          fullWidth
          onBlur={() => {
            handleBlur('zipCode');
          }}
          onChange={(e) => {
            setUpdatedValue(e.target.value);
          }}
          endAdornment={loading ? <CircularProgress size={24} /> : undefined}
        />
      </Form>
      <div>
        <AddressText style={{ marginBlockStart: 0 }}>
          {updatedAddress}
        </AddressText>
      </div>
    </DialogContainer>
  );
}

export default ZipCodeDialog;

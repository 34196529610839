import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core';
import clsx, { ClassValue } from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    containerContent: {
      maxWidth: 530,
      width: '100%',
    },
    title: {
      color: '#323C3C',
      fontSize: 24,
      fontWeight: 400,
    },
    containerForm: {
      background: '#fff',
      boxShadow: '0px 2px 2px #00000029',
      borderRadius: 6,
      padding: 32,
      width: '100%',
    },
  }),
);

interface EditProfileContainerProps {
  title: string;
  contentStyle?: ClassValue;
}

const EditProfileContainer: React.FC<EditProfileContainerProps> = ({
  title,
  children,
  contentStyle,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.containerContent}>
        <h1 className={classes.title}>{title}</h1>
        <div className={clsx(classes.containerForm, contentStyle)}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default EditProfileContainer;

import styled from 'styled-components';
import { AppBar, Button } from '@material-ui/core';

export const Container = styled(AppBar)<{ drawerwidth: number }>`
  display: flex;
  flex-direction: row;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  opacity: 1;
  height: 64px;
  padding-right: 0;
  width: calc(100% - ${(props) => props.drawerwidth}px);
  a {
    display: flex;
    align-items: center;
    img {
      height: 32px;
      @media (max-width: 600px) {
        height: 24px;
      }
    }
  }
`;

export const MenuButton = styled(Button)`
  height: 100%;
  justify-content: flex-end;
  display: flex;
  align-self: flex-end;
  align-items: center;
  .username {
    display: inline-block;
    margin-right: 8px;
    margin-left: 16px;
    text-transform: capitalize;
    font-weight: 600;
    color: #212121;
  }
`;

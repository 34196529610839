import React, { useCallback } from 'react';

import { Backdrop, Button, Fade, IconButton, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Form } from '@unform/web';

import Input from '../../components/Forms/Input';
import RadioInput from '../../components/Forms/Radio';
import { useToast } from '../../contexts/toast';
import useFormValidation from '../../hooks/useFormValidation';
import {
  AddUser,
  AddUserData,
} from '../../services/User';
import Yup from '../../validators/Yup';
import useStyles from './styles';
import { Invite } from '../../types/invite';

const schema = Yup.object().shape({
  name: Yup.string().required('O campo é obrigatório'),
  email: Yup.string().email('Email inválido').required('O campo é obrigatório'),
});

interface AddUserDialogProps {
  onAddUser(invite: Invite): void;
  open: boolean;
  onClose(): void;
  roles: { id: number, name: string, description: string }[];

}

const AddUserDialog: React.FC<AddUserDialogProps> = ({
  onAddUser,
  open,
  onClose,
  roles
}) => {
  const classes = useStyles();

  const { showSuccess, showError } = useToast();

  const {
    formRef,
    handleBlur,
    handleChangeText,
    validateForm,
    handleValidationErrors,
  } = useFormValidation(schema);

  const handleSubmit = useCallback(
    async (data: AddUserData) => {
      try {
        await validateForm(data, {
          abortEarly: false,
        });

        const response = await AddUser(data);

        onAddUser(response.data);

        showSuccess(response.message ?? '');
      } catch (err) {
        handleValidationErrors(err);

        if (err.response) {
          showError(err.response.data.message);
        }
      }
    },
    [
      validateForm,
      showSuccess,
      handleValidationErrors,
      showError,
    ],
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <IconButton disableFocusRipple disabled disableTouchRipple>
              <Close className={classes.workAround} />
            </IconButton>
            <h2 className={classes.modalTitle} id="transition-modal-title">
              Adicionar Usuário
            </h2>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.content}>
            <Form
              ref={formRef}
              initialData={{
                role: roles && roles.length ? roles[0].id : undefined,
              }}
              className="w-100 be-partner-form"
              onSubmit={handleSubmit}
            >
              <h3>Informações</h3>
              <Input
                className="be-partner-field"
                name="name"
                onBlur={() => handleBlur('name')}
                onChange={() => handleChangeText('name')}
                id="name"
                label="Nome"
                showError={true}
              />
              <Input
                className="be-partner-field"
                name="email"
                onBlur={() => handleBlur('email')}
                onChange={() => handleChangeText('email')}
                label="E-mail"
                showError={true}
              />
              <RadioInput
                defaultValue={(roles && roles.length ? roles[0].id : undefined)}
                label={(item) => (
                  <span
                    className={classes.rolesLabel}
                  >
                    {item.name}
                    <small className={classes.rolesLabelDescription}>
                      {' '}
                      ({item.description})
                    </small>
                  </span>
                )}
                name="role"
                items={roles}
              >
                <h3 className={classes.rolesGroupLabel}>Perfil</h3>
              </RadioInput>
              <div className={classes.actionButtons}>
                <Button className={classes.button} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Enviar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddUserDialog;

import React, { useState } from 'react';

import AddressText from '../../../../../components/AddressText';
import { useAuth } from '../../../../../contexts/Auth';
import Yup from '../../../../../validators/Yup';
import TextFieldDialog from '../../Dialogs/TextFieldDialog';
import LabelValue from '../../LabelValue';

const schema = Yup.object().shape({
  number: Yup.string().required('O número é obrigatório'),
});

function AddressNumberField() {
  const {
    user: {
      selectedUserStore: {
        store: { address },
      },
    },
  } = useAuth();

  const number = address.number;

  const [editValue, setEditValue] = useState(false);

  return (
    <>
      <LabelValue
        label="Número do endereço"
        editable
        onEdit={() => setEditValue(true)}
      >
        {number}
      </LabelValue>
      <TextFieldDialog
        onConfirm={() => {
          setEditValue(false);
        }}
        open={editValue}
        onCancel={() => setEditValue(false)}
        input={{
          label: 'Número',
          name: 'number',
        }}
        previousValue={number}
        title="Alterar número do endereço"
        successMessage="Número do endereço alterado com sucesso!"
        validationSchema={schema}
        payloadHandler={(data) => ({
          address: data,
        })}
      >
        <AddressText style={{ marginBlockStart: 0 }}>{address}</AddressText>
      </TextFieldDialog>
    </>
  );
}

export default AddressNumberField;

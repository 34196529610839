import React from 'react';
import { Grid } from '@material-ui/core';
import Input from '../../../../../components/Forms/Input';
import MaskedInput from '../../../../../components/Forms/MaskedInput';
import { CEPMask } from '../../../../../utils/Masks/CEP';

export default function ThirdStep({ style, handleBlurCep, handleBlur }: any) {
  return (
    <div>
      <h3 className={style.titleForm}>Endereço</h3>
      <span className={style.subtitleForm}>
        Informe a localização da Instituição
      </span>
      <Grid container spacing={2} className={style.gridContainer}>
        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={6} style={{ paddingRight: '8px' }}>
            <MaskedInput
              name="charity.address.zipCode"
              mask={CEPMask}
              label="CEP"
              fullWidth
              onBlur={() => handleBlurCep('charity.address.zipCode')}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input name="charity.address.city" label="Cidade" disabled={true} />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Input
            name="charity.address.state.uf"
            label="Estado"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="charity.address.neighborhood"
            label="Bairro"
            onBlur={() => handleBlur('charity.address.neighborhood')}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Endereço"
            name="charity.address.address"
            onBlur={() => handleBlur('charity.address.address')}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="charity.address.number"
            type="number"
            label="Número"
            onBlur={() => handleBlur('charity.address.number')}
          />
        </Grid>
      </Grid>
    </div>
  );
}

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.defaults.headers['Content-Type'] = 'application/json';
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const networkError = error.response === undefined;
    const unauthorizedError = error.response?.status === 401;

    if (networkError || unauthorizedError) {
      // TODO: Check best way to do it. Duplicated code on Auth context
      localStorage.removeItem('@Poppy:token');
      localStorage.removeItem('@Poppy:user');

      window.location.replace('/');
    }

    return Promise.reject(error);
  },
);

export default api;

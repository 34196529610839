import React, {  useRef, useState } from 'react';

import { Badge, Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import ProfileAvatar from '../ProfileAvatar';
import { downloadFileUrl } from '../../services/File';
import placeholder from '../../assets/images/image-placeholder.png';
import { File } from '../../types/file';
import { saveImageFile } from '../../services/Charities';
import { useToast } from '../../contexts/toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    changeAvatarBtn: {
      cursor: 'pointer',
      background: '#000',
      opacity: 0.5,
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '50%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(2),
      borderRadius: '0 0 70px 70px',
      color: '#fff',
      fontSize: theme.spacing(2),
      fontWeight: 500,
      '&:hover, &:focus': {
        transition: 'opacity 0.2s',
        opacity: 0.7,
      },
    },
    avatarBadgeContainer: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      background: '#fff',
      padding: theme.spacing(1),
      borderRadius: '50%',
      boxShadow: '0px 3px 6px #00000029',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    avatarBadge: {
      color: theme.palette.primary.main,
    },
  }),
);

interface ChangeableAvatarProps {
  onChangeAvatar(avatar: File): void;
  imageHash?: string;
  showSuccessMessage?: boolean;
  loading?: boolean;
}

function ChangeableAvatar({
  onChangeAvatar,
  imageHash,
  showSuccessMessage = true,
  loading = false,
}: ChangeableAvatarProps) {
  const classes = useStyles();

  const [creatingAvatar, setCreatingAvatar] = useState(false);

  const [internalImageHash, setInternalImageHash] = useState<string>();

  const inputImageRef = useRef<any>();

  const { showSuccess, showError } = useToast();

  const handleImageUploadAction = () => inputImageRef?.current?.click();

  const handleUploadImage = async (e: any) => {
    const [file] = e.target.files;
    let reader = new FileReader();
    if (file) {
      reader.onload = (e) => {
        if (e?.target?.result && typeof e?.target?.result === 'string')
          setInternalImageHash(e?.target?.result);
      };
    }
    reader.readAsDataURL(file);

    try {
      setCreatingAvatar(true);

      const avatar = await saveImageFile(file);

      onChangeAvatar(avatar);

      if (showSuccessMessage) {
        showSuccess('Imagem adicionada!');
      }
    } catch (err) {
      showError('Algo deu errado!');
    } finally {
      setCreatingAvatar(false);
    }
  };

  return (
    <div>
      <Badge
        style={{ width: '140px', opacity: loading || creatingAvatar ? 0.3 : 1 }}
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <Box boxShadow={2} className={classes.avatarBadgeContainer}>
            <CameraAlt className={classes.avatarBadge} />
          </Box>
        }
      >
        <div>
          <input
            type="file"
            accept="image/*"
            ref={inputImageRef}
            onChange={handleUploadImage}
            hidden
          />
          <ProfileAvatar
            src={
              !!internalImageHash
                ? internalImageHash
                : !!imageHash
                ? downloadFileUrl(imageHash)
                : placeholder
            }
          />
          <div
            onClick={handleImageUploadAction}
            className={classes.changeAvatarBtn}
          >
            <span>Alterar foto</span>
          </div>
        </div>
      </Badge>
    </div>
  );
}

export default ChangeableAvatar;

import React, { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import useStyles from './styles';
import LoadingButton from '../Forms/LoadingButton';
import clsx from 'clsx';

interface DialogContainerProps {
  title: string;
  open: boolean;

  // Optional props
  isLoading?: boolean;
  onCancel?(): void;
  onConfirm?(): void;
  hideCancelBtn?: boolean;
  cancelBtnText?: string;
  hideConfirmBtn?: boolean;
  confirmBtnText?: string;
  confirmBtnLoadingText?: string;
  conformBtnClassName?: string;
  disabledConfirmBtn?: boolean;
}

const DialogContainer: React.FC<DialogContainerProps> = ({
  title,
  open,
  onCancel,
  onConfirm,
  children,
  isLoading = false,
  hideCancelBtn = false,
  cancelBtnText = 'Cancelar',
  hideConfirmBtn = false,
  confirmBtnText = 'Confirmar',
  confirmBtnLoadingText = 'Confirmando...',
  conformBtnClassName = {},
  disabledConfirmBtn = false,
}) => {
  const classes = useStyles();

  const handleClose = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        <div className={classes.header}>
          <IconButton disableFocusRipple disabled disableTouchRipple>
            <Close className={classes.workAround} />
          </IconButton>
          <h1 className={classes.modalTitle} id="transition-modal-title">
            {title}
          </h1>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {!hideCancelBtn && (
          <Button
            disabled={isLoading}
            className={classes.button}
            onClick={handleClose}
          >
            {cancelBtnText}
          </Button>
        )}
        {!hideConfirmBtn && (
          <LoadingButton
            disabled={disabledConfirmBtn}
            className={clsx(classes.button, conformBtnClassName)}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onConfirm}
            loading={isLoading}
            text={confirmBtnText}
            loadingText={confirmBtnLoadingText}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogContainer;

export enum PrivacyPolicyType {
  PRIVACY_POLICY,
  USE_TERMS,
}

export interface PrivacyPolicy {
  id: number;
  description: string;
  type: PrivacyPolicyType;
}

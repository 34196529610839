import { useSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext } from 'react';

interface ToastContextData {
  showSuccess(message: string): void;
  showError(message: string | string[]): void;
  showWarning(message: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const showSuccess = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  const showError = useCallback(
    (message: string | string[]) => {
      enqueueSnackbar(Array.isArray(message) ? message[0] : message, {
        variant: 'error',
      });
    },
    [enqueueSnackbar],
  );

  const showWarning = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'warning' });
    },
    [enqueueSnackbar],
  );

  return (
    <ToastContext.Provider value={{ showSuccess, showError, showWarning }}>
      {children}
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };

import React, { useState } from 'react';
import { IconType } from 'react-icons';

import { Container, Tab, Tabs } from '@material-ui/core';

import useStyles from './styles';
import clsx from 'clsx';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style?: React.CSSProperties;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Container>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type TabLabel = string | React.ReactNode;

interface TabPageContainerProps {
  tabsLabel: TabLabel[];
  headerContainerStyle?: React.CSSProperties;
  icon: IconType;
  title: string;
  tabs: React.ReactNode[];
  containerClassName?: string;
}

const TabPageContainer: React.FC<TabPageContainerProps> = ({
  tabsLabel,
  tabs,
  icon: Icon,
  title,
  containerClassName,
  headerContainerStyle,
  children,
}) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <div className={classes.headContainer} style={headerContainerStyle}>
        <div className={`${classes.container} ${classes.head}`}>
          <div className={classes.headTitle}>
            {<Icon size={32} />}
            <h2 style={{ marginLeft: '0.5rem' }}>{title}</h2>
          </div>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="store tabs"
            indicatorColor="primary"
          >
            {tabsLabel.map((tabLabel, index) => (
              <Tab key={Math.random()} label={tabLabel} {...a11yProps(index)} />
            ))}
          </Tabs>
        </div>
      </div>
      <div
        className={clsx(
          classes.container,
          containerClassName ?? `${classes.table}`,
        )}
      >
        {children}
        {tabs.map((tab, index) => (
          <TabPanel
            style={{ padding: 0 }}
            key={Math.random()}
            value={selectedTab}
            index={index}
          >
            {tab}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default TabPageContainer;

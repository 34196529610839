import styled from 'styled-components';

import { Card } from '@material-ui/core';

export const Container = styled(Card)<{ maxWidth?: number; padding?: number }>`
  box-sizing: border-box;
  width: 100%;
  max-width: ${(props) => props.maxWidth ?? 541}px;
  padding: ${(props) => props.padding ?? 32}px;
  border-radius: 10px !important;
  margin: 0 8px;
`;

import React, { CSSProperties } from 'react';

import { Address } from '../../types/common';

interface AddressTextProps {
  children: Address;
  withNumber?: boolean;
  style?: CSSProperties;
}

function AddressText({
  children: address,
  withNumber = false,
  style,
}: AddressTextProps) {
  return (
    <p style={style}>{`${address.address}${
      withNumber ? `, Nº ${address.number}` : ''
    } - ${address.neighborhood}, ${address.city} - ${address.state.uf}`}</p>
  );
}

export default AddressText;

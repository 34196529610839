import React, { useState, useEffect } from 'react';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import {
    Avatar,
    Badge,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    Typography
} from "@material-ui/core";
import { useStyles } from './styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import api from '../../services/api';
import { Pagination, Links } from '../../types/api-response';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Moment from 'react-moment';

interface Notification {
    id: number;
    title: string;
    message: string;
    url: string;
    read: boolean;
    updatedAt: Date;
}

interface NotificationResponse {
    success: boolean;
    message: string;
    data: {
        items: Notification[];
        meta: Pagination;
        links: Links;
    };
}

const BellNotification = () => {
    const classes = useStyles();
    const history = useHistory();
    const [items, setItems] = useState<Notification[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [alerts, setAlerts] = React.useState(items.length);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAlerts(0);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClick = async (id: number, route: string) => {
        await api.put<NotificationResponse>(`notification/confirmation-read/${id}`);
        return history.push(route);
    }

    const fecthData = async () => {
        await api.get<NotificationResponse>('notification', {
            params: {
                limit: 10,
                page: page,
                read: false
            },
        }).then(res => {
            const result = res.data.data.items;
            setItems(prev => [...prev, ...result]);
            setAlerts(res.data.data.meta.totalItems);
            setPage(prev => {
                if (prev !== res.data.data.meta.totalPages) {
                    return prev + 1;
                }
                setHasMore(false);
                return prev;
            });
        });
    };

    useEffect(() => {
        fecthData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <IconButton
                aria-describedby={id}
                classes={{ root: classes.customIconButton }}
                onClick={handleClick}
            >
                <Badge
                    badgeContent={alerts}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    classes={{ badge: classes.customBadge }}
                >
                    <NotificationsNoneIcon fontSize='large' color={anchorEl === null ? "inherit" : "primary"} />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.paper
                }}
            >
                <span className={classes.arrow} />

                <List className={classes.root}>
                    <InfiniteScroll
                        dataLength={items.length} //This is important field to render the next data
                        next={() => fecthData()}
                        hasMore={hasMore}
                        loader={<ListItem><Typography>Loading...</Typography></ListItem>}
                        endMessage={
                            <ListItem><Typography>Você visualizou todas as mensagens</Typography></ListItem>
                        }
                        height={400}
                    >
                        {items.length > 0 && items.map((item, index) =>
                            <>
                                <ListItem
                                    key={`listbell-${index}`}
                                    button
                                    alignItems="flex-start"
                                    onClick={() => onClick(item.id, item.url)}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt="Poppy Notification" src="/logo192.png" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.title}
                                        secondary={item.message}
                                    />
                                    <ListItemText
                                        style={{ textAlign: "right" }}
                                        secondary={<Moment key={`moment-${index}`} fromNow>{item.updatedAt}</Moment>}
                                    // secondary={` ${moment(item.updatedAt).fromNow()}`}
                                    />
                                </ListItem>
                                <Divider variant="middle" />
                            </>
                        )}
                    </InfiniteScroll>
                </List>
            </Popover>
        </>
    );
};

export default BellNotification;

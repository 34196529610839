import { makeStyles, Theme, createStyles } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        maxWidth: '557px',
        width: '100%',
      },
    },
    dialogTitle: {
      padding: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    dialogContent: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    dialogActions: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: '20px',
    },
    header: {
      display: 'flex',
      borderBottom: '1px solid #00000020',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0),
    },
    modalTitle: {
      fontSize: theme.spacing(3),
      fontWeight: 500,
    },
    workAround: {
      color: theme.palette.background.paper,
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: theme.spacing(3),
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),
    },
    button: {
      width: '140px',
      height: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
  }),
);

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Fab } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';

import { Pagination } from '../../types/api-response';
import { Transaction } from '../../types/transactions';
import CurrencyValue from '../CurrencyValue';
import { EmptyTableProps } from '../Table/EmptyTable';
import ActionButton from '../Table/EmptyTable/ActionButton';
import FiltrableTableContainer from '../Table/FiltrableTableContainer';
import TableContainer from '../Table/TableContainer';
import MenuOptions from './MenuOptions';
import useStyles from './styles';
import TableCell from './TableCell';

type NotClickableEmptyTableOptions = Pick<
  EmptyTableProps,
  'title' | 'actionText' | 'subtitle' | 'imageSrc' | 'style'
>;

export interface TableDataTypes {
  tableData: Transaction[];
  pagination?: Pagination;
  loadingData: boolean;
  searchable?: boolean;
  dateFiltrable?: boolean;
  showAddChange?: boolean;
  title?: string;
  searchableName?: string;
  filtrableDates?: { month: number; year: number }[];
  page: number;
  tableHeadersLabel?: {
    first: string;
  };
  firstTableDataHandler?(transaction: Transaction): string;
  onAddChangeClick?(): void;
  onEditChangeClick?(transaction: Transaction): void;
  onSearchChange?(event: any): void;
  onChangePage(page: number): void;
  onSelectedDateChange?(date: Date): void;
  /**
   * @default true
   */
  showMenuOptions?: boolean;
  monthlyTotalChange?: number;
  emtpyTableOptions?: Partial<NotClickableEmptyTableOptions>;
}

const Table: React.FC<TableDataTypes> = ({
  tableData,
  pagination,
  tableHeadersLabel,
  onAddChangeClick,
  onEditChangeClick,
  loadingData,
  searchable = false,
  dateFiltrable = false,
  showAddChange = true,
  title = 'Últimos trocos',
  onChangePage,
  onSearchChange,
  page,
  searchableName,
  filtrableDates = [],
  showMenuOptions = true,
  firstTableDataHandler = (transaction: Transaction) =>
    `${transaction.user.firstName} ${transaction.user.surname}`,
  monthlyTotalChange,
  onSelectedDateChange,
  emtpyTableOptions = {},
}) => {
  const classes = useStyles();

  const history = useHistory();

  const handleSeeClientProfile = useCallback(
    (data: Transaction) => {
      history.push(
        `/painel-estabelecimento/transacoes/cliente/${data.user.cpf}`,
      );
    },
    [history],
  );

  const handleClickAddChange = useCallback(() => {
    if (onAddChangeClick) {
      onAddChangeClick();
    }
  }, [onAddChangeClick]);

  const handleChangeTransaction = useCallback(
    (transaction: Transaction) => {
      if (onEditChangeClick) {
        onEditChangeClick(transaction);
      }
    },
    [onEditChangeClick],
  );

  return (
    <FiltrableTableContainer
      hasTableData={tableData.length > 0}
      loadingData={loadingData}
      onChangePage={onChangePage}
      page={page}
      dateFiltrable={dateFiltrable}
      emtpyTableOptions={emtpyTableOptions}
      filtrableDates={filtrableDates}
      pagination={pagination}
      onSearchChange={onSearchChange}
      onSelectedDateChange={onSelectedDateChange}
      onEmptyTableActionClick={handleClickAddChange}
      searchable={searchable}
      searchableName={searchableName}
      title={title}
      shouldShowSideHeader={showAddChange}
      sideHeader={
        <>
          {monthlyTotalChange !== undefined && (
            <div className={classes.totalMonthContainer}>
              <span className={classes.totalMonthLabel}>Total mensal</span>
              <span className={classes.totalMonthValue}>
                <CurrencyValue>{monthlyTotalChange}</CurrencyValue>
              </span>
            </div>
          )}

          {showAddChange && (
            <ActionButton hiddable onClick={handleClickAddChange}>
              Adicionar troco
            </ActionButton>
          )}
        </>
      }
    >
      <TableContainer
        header={
          <TableRow>
            <TableCell th>{tableHeadersLabel?.first ?? 'Cliente'}</TableCell>
            <TableCell th>Data do troco</TableCell>
            <TableCell th>Valor pago</TableCell>
            <TableCell th>Troco</TableCell>
            {showMenuOptions && (
              <TableCell width={8} th align="right">
                {''}
              </TableCell>
            )}
          </TableRow>
        }
      >
        {tableData.map((transaction) => (
          <TableRow key={transaction.id}>
            <TableCell component="th" scope="row">
              <span>{firstTableDataHandler(transaction)}</span>
              <span className={classes.createdAt}>
                {transaction.formattedCreatedAt}
              </span>
            </TableCell>
            <TableCell className={classes.hideOnMobile} align="left">
              {transaction.formattedCreatedAt}
            </TableCell>
            <TableCell className={classes.hideOnMobile} align="left">
              <CurrencyValue>{transaction.paidValue}</CurrencyValue>
            </TableCell>
            <TableCell align="left" style={{ color: '#66B423' }}>
              <CurrencyValue>{transaction.change}</CurrencyValue>
            </TableCell>
            {showMenuOptions && (
              <TableCell align="right">
                <MenuOptions
                  transaction={transaction}
                  onChangeTransaction={handleChangeTransaction}
                  onSeeProfileClick={handleSeeClientProfile}
                />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableContainer>
      {showAddChange && (
        <Fab
          onClick={handleClickAddChange}
          className={classes.fab}
          aria-label="Add"
          color="primary"
        >
          <AddIcon />
        </Fab>
      )}
    </FiltrableTableContainer>
  );
};

export default Table;

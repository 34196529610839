import React from 'react';
import { RouteProps } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';
import { StoreRolesEnum } from '../../types/user';
import { AuthenticatedRouteWrapper } from './AuthenticatedRouteWrapper';

interface Props extends RouteProps {
  roles: StoreRolesEnum[];
  page: any;
  layout: any;
}

export const SelectedStoreRouteWrapper: React.FC<Props> = ({
  page: Page,
  layout: Layout,
  roles,
  ...rest
}) => {
  const { user } = useAuth();

  const hasRoles =
    user?.selectedUserStore && roles.includes(user.selectedUserStore.role.id);

  return (
    <AuthenticatedRouteWrapper
      {...rest}
      layout={Layout}
      page={Page}
      canActivate={hasRoles}
      redirectUrl={!user?.selectedUserStore ? '/' : '/painel-estabelecimento'}
    ></AuthenticatedRouteWrapper>
  );
};

import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import { useAuth } from '../../contexts/Auth';
import { UserRoles } from '../../routes/wrappers/AuthenticatedRouteWrapper';
import { User } from '../../types/user';
import { AuthCard, CardContainer, Container, QrcodeCard, IconSore, ContainerStore } from './styles';
import QrCodeImage from '../../assets/images/qr-code.png';
import IconAppleStore from '../../assets/images/icon-app-store.svg';
import IconGoogleStore from '../../assets/images/icon-google-play.svg';

const AuthLayout: React.FC = ({ children }) => {
  const { user } = useAuth();
  const history = useHistory();

  const googleLink = process.env.REACT_APP_PLAY_STORE_LINK || '';

  const appleLink = process.env.REACT_APP_APPLE_STORE_LINK || '';

  const handleNavigate = useCallback(
    (user: User) => {
      if (user.roles.includes(UserRoles.ADMIN)) {
        return history.push('/admin');
      }

      if (user.stores.length > 1) {
        return history.push('/selecionar-conveniado');
      }

      if (user.stores.length) {
        return history.push('/painel-estabelecimento');
      }
    },
    [history],
  );

  useEffect(() => {
    if (user && user.isActive) {
      handleNavigate(user);
    }
  }, [user, handleNavigate]);

  return (
    <div>
      <Header></Header>
      <Container className="w-100 h-100">
        <h1>Uma nova solução para poupanças de crianças em PIX.</h1>
        <CardContainer>
          <QrcodeCard>
            <h2>Baixe o aplicativo de sua loja de preferência.</h2>
            <img src={QrCodeImage} alt="QrCodeImage" />
            <ContainerStore>
              <a href={googleLink}>
                <IconSore alt="Google Play" src={IconGoogleStore}></IconSore>
              </a>
              <a href={appleLink}>
                <IconSore alt="Apple Store" src={IconAppleStore}></IconSore>
              </a>
            </ContainerStore>
          </QrcodeCard>
          <AuthCard>{children}</AuthCard>
        </CardContainer>
      </Container>
    </div>
  );
};

export default AuthLayout;

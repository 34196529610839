import MUIRichTextEditor from 'mui-rte';
import React, { useCallback, useEffect, useState } from 'react';
import { MdPanTool } from 'react-icons/md';
import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertToRaw,
} from 'draft-js';
import { convertToHTML } from 'draft-convert';

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import api from '../../../services/api';
import { useLoading } from '../../../contexts/loading';
import { useToast } from '../../../contexts/toast';
import { PrivacyPolicyType } from '../../../types/privacy-policy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '980px',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '95%',
      },
    },
    headContainer: {
      backgroundColor: theme.palette.background.paper,
      marginBottom: '24px',
    },
    head: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '24px',
      paddingBottom: '30px',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '24px',
      },
    },
    richTextHeader: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #eee',
      borderRadius: '5px',
      padding: '8px',
    },
    richTextEditor: {
      backgroundColor: theme.palette.background.paper,
      height: '480px',
      overflowY: 'auto',
      padding: '4px',
    },
    placeholder: {
      backgroundColor: theme.palette.background.paper,
      padding: '8px',
      marginLeft: '-8px',
      marginTop: '-2px',
      minHeight: '500px',
    },
  }),
);

const PrivacyPolicy = ({ location }: any) => {
  const { setLoading } = useLoading();
  const { showSuccess, showError } = useToast();
  const classes = useStyles();
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const [initialEditorState, setInitialEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await api.get(
          `/privacy-policy/${
            location.pathname === '/admin/politica-privacidade'
              ? PrivacyPolicyType.PRIVACY_POLICY
              : PrivacyPolicyType.USE_TERMS
          }`,
        );
        const html = data.data.description;
        const blocksFromHTML = convertFromHTML(html);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        setEditorState(EditorState.createWithContent(state));
        setInitialEditorState(EditorState.createWithContent(state));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetchData();
    return () => {
      setEditorState(EditorState.createEmpty());
      setInitialEditorState(EditorState.createEmpty());
    };
  }, [setLoading, location]);

  const handleSave = async (data: any) => {
    if (!editorState.getCurrentContent().hasText()) {
      return showError(
        location.pathname === '/admin/politica-privacidade'
          ? 'Não é possível salvar a política de privavidade vazia.'
          : 'Não é possível salvar os termos de uso vazio.',
      );
    }
    try {
      setLoading(true);
      const html = convertToHTML({})(editorState.getCurrentContent());
      const response = await api.post('/privacy-policy', {
        description: html,
        type:
          location.pathname === '/admin/politica-privacidade'
            ? PrivacyPolicyType.PRIVACY_POLICY
            : PrivacyPolicyType.USE_TERMS,
      });
      if (response.data) {
        showSuccess('Dados salvos com sucesso!');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleChange = useCallback((state: EditorState) => {
    setEditorState(state);
  }, []);

  return (
    <div>
      <div className={classes.headContainer}>
        <div className={`${classes.container} ${classes.head}`}>
          <MdPanTool size={32} style={{ marginRight: '8px' }} />
          <h2>
            {location.pathname === '/admin/politica-privacidade'
              ? 'Política de privacidade'
              : 'Termos de uso'}
          </h2>
        </div>
      </div>

      <div className={classes.container}>
        <MUIRichTextEditor
          defaultValue={JSON.stringify(
            convertToRaw(initialEditorState.getCurrentContent()),
          )}
          onChange={handleChange}
          classes={{
            container: classes.richTextHeader,
            editorContainer: classes.richTextEditor,
            placeHolder: classes.placeholder,
          }}
          label={
            location.pathname === '/admin/politica-privacidade'
              ? 'Digite aqui o termo de política e privacidade...'
              : 'Digite aqui o termo de uso...'
          }
          onSave={handleSave}
        />
      </div>
    </div>
  );
};

export default PrivacyPolicy;

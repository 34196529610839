import React, { useCallback, useEffect, useState } from 'react';

import { createStyles, makeStyles } from '@material-ui/core';
import { Form } from '@unform/web';

import { useToast } from '../../../../contexts/toast';
import useFormValidation from '../../../../hooks/useFormValidation';
import useIsMounted from '../../../../hooks/useIsMounted';
import LabelValue from '../../../../pages/Store/Profile/LabelValue';
import { resendEmail } from '../../../../services/Store';
import { StoreInvite } from '../../../../types/store';
import Yup from '../../../../validators/Yup';
import DialogContainer from '../../../DialogContainer';
import Input from '../../../Forms/Input';

interface ResendEmailDialogProps {
  storeInvite: StoreInvite;
  onCancel(): void;
  onResentEmail(storeInvite: StoreInvite): void;
}

interface FormData {
  email: string;
}

const schema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('O campo é obrigatório'),
});

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      marginBlockStart: 0,
      color: '#757575DE',
      fontSize: '20px',
    },
  }),
);

const ResendEmailDialog: React.FC<ResendEmailDialogProps> = ({
  onCancel,
  onResentEmail,
  storeInvite,
}) => {
  const {
    formRef,
    handleBlur,
    validateForm,
    handleValidationErrors,
  } = useFormValidation(schema);

  const isMounted = useIsMounted();

  const classes = useStyles();

  const [showEdittingField, setShowEdittingField] = useState(false);

  const [updatedValue, setUpdatedValue] = useState(storeInvite.email);
  const [dirty, setDirty] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { showError, showSuccess } = useToast();

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setUpdating(true);
        setDirty(false);

        await validateForm(data, { abortEarly: false });

        const response = await resendEmail(storeInvite, data.email);

        if (response.message) {
          showSuccess(response.message);
        }

        onResentEmail(response.data);
      } catch (err) {
        setDirty(true);
        handleValidationErrors(err);
        if (err.response) {
          showError(err.response.data.message);
        }
      } finally {
        if (isMounted()) setUpdating(false);
      }
    },
    [
      handleValidationErrors,
      isMounted,
      onResentEmail,
      showError,
      showSuccess,
      storeInvite,
      validateForm,
    ],
  );

  useEffect(() => {
    setDirty(storeInvite.email !== updatedValue);
  }, [storeInvite, updatedValue]);

  const handleCancel = useCallback(() => {
    if (showEdittingField) {
      return setShowEdittingField(false);
    }
    onCancel();
  }, [onCancel, showEdittingField]);

  const handleConfirm = useCallback(async () => {
    if (showEdittingField) {
      formRef.current?.submitForm();
      return;
    }

    try {
      setUpdating(true);
      const response = await resendEmail(storeInvite, storeInvite.email);

      if (response.message) {
        showSuccess(response.message);
      }
      onResentEmail(response.data);
    } catch (err) {
      if (err.response) {
        showError(err.response.data.message);
      }
    } finally {
      if (isMounted()) setUpdating(false);
    }
  }, [
    formRef,
    isMounted,
    onResentEmail,
    showEdittingField,
    showError,
    showSuccess,
    storeInvite,
  ]);

  return (
    <DialogContainer
      title="Reenviar e-mail"
      open={!!storeInvite}
      onCancel={handleCancel}
      cancelBtnText="Cancelar"
      confirmBtnText="Enviar"
      isLoading={updating}
      disabledConfirmBtn={!dirty && showEdittingField}
      onConfirm={handleConfirm}
    >
      {storeInvite && !showEdittingField && (
        <LabelValue
          editable
          onEdit={() => setShowEdittingField(true)}
          label="E-mail"
        >
          {storeInvite.email}
        </LabelValue>
      )}
      {showEdittingField && (
        <Form ref={formRef} onSubmit={handleSubmit}>
          <p className={classes.inputLabel}>Alterar e-mail de envio</p>
          <Input
            defaultValue={storeInvite?.email}
            name="email"
            type="text"
            value={updatedValue}
            label="E-mail"
            onBlur={() => handleBlur('email')}
            onChange={(e) => {
              setUpdatedValue(e.target.value);
            }}
          />
        </Form>
      )}
    </DialogContainer>
  );
};

export default ResendEmailDialog;

import React, { useState } from 'react';

import PhoneValue from '../../../../../components/PhoneValue';
import { useAuth } from '../../../../../contexts/Auth';
import PhoneDialog from '../../Dialogs/PhoneDialog';
import LabelValue from '../../LabelValue';

function PhoneField() {
  const {
    user: {
      selectedUserStore: {
        store: { phone },
      },
    },
  } = useAuth();

  const [editPhone, setEditPhone] = useState(false);

  return (
    <>
      <LabelValue label="Telefone" editable onEdit={() => setEditPhone(true)}>
        <PhoneValue>{phone}</PhoneValue>
      </LabelValue>
      <PhoneDialog
        onConfirm={(phone) => {
          setEditPhone(false);
        }}
        open={editPhone}
        onCancel={() => setEditPhone(false)}
      ></PhoneDialog>
    </>
  );
}

export default PhoneField;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TransactionsHeader = styled.div`
  display: flex;
  height: 120px;
  background: var(--white);
`;

export default makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    headerContent: { maxWidth: '1000px', width: '100%' },
    contentContainer: {
      display: 'flex',
      maxWidth: '1000px',
      width: '100%',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    infoCards: {
      display: 'flex',
      width: '100%',
      gap: `${theme.spacing(3)}px`,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    breadcrumbs: {
      padding: 0,
      paddingBottom: theme.spacing(4),
    },
  }),
);

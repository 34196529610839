import { Card } from '@material-ui/core';
import styled from 'styled-components';
import bgPig from '../../assets/images/man-holding-a-pig.svg';
import bgRobot from '../../assets/images/robot.png';
import bubble from '../../assets/images/bubble-green.svg';
import coins from '../../assets/images/coins.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  min-height: calc(100vh - 64px);
  background-image: url(${bgRobot}), url(${bubble}), url(${coins});
  background-position: left bottom, left top, right bottom;
  background-repeat: no-repeat;
  background-size: 350px, 800px;

  @media (min-height: 1000px) {
    background-image: url(${bgRobot}), url(${bubble}), url(${coins});
    background-position: left bottom, left top, right bottom;
    background-repeat: no-repeat;
    background-size: 600px, 900px;
  }

  h1 {
    font-size: 3vw;
    font-weight: normal;
    max-width: 700px; 
    width: available;
    color: var(--white);
    align-self: start;
    margin: 50px;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  @media (max-width: 1000px) {
    background-image: url(${coins});
    background-size: contain;
    grid-template-columns: 1fr;
    h1 {
      font-size: 40px;
      margin: 40px;
      display: none;
    }
  }
  @media (max-width: 600px) {
    background-image: url(${coins});
    background-size: contain;
    grid-template-columns: 1fr;
    h1 {
      display: none;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const QrcodeCard = styled(Card)`
  background: none;
  border-color: transparent;
  box-shadow: none;
  width: 300px;
  height: auto;
  padding: 0;
  margin: 40px;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 900px) {
    width: 90%;
    height: auto;
    margin: 40px 8px;
    align-items: center;
    justify-content: center;
  }
`;

export const AuthCard = styled(Card)`
  width: 500px;
  border-radius: 10px !important;
  padding: 0;
  @media (max-width: 900px) {
    width: 90%;
    margin: 40px 8px;
  }
`;

export const IconSore = styled.img`
  width: 130px;
`;

export const ContainerStore = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

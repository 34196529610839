import React from 'react';
import { conformToMask } from 'react-text-mask';
import { CEPMask } from '../../utils/Masks/CEP';

interface ZipCodeValueProps {
  children: string;
}

const ZipCodeValue: React.FC<ZipCodeValueProps> = ({ children }) => (
  <>{conformToMask(children, CEPMask, {}).conformedValue}</>
);

export default ZipCodeValue;

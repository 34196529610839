import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(4),
    },
    tableContainer: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      fontSize: theme.spacing(3),
      fontWeight: 500,
      marginBlockStart: 0,
    },
    tableHead: {
      background: '#F5F5F5',
      borderBottom: '#F5F5F5',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    tableSearchHeader: {
      display: 'flex',
      gap: `${theme.spacing(3)}px`,
      marginBottom: theme.spacing(3),
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    tableHeaderFilters: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '381px',
      },
      width: '100%',
    },
    searchFieldContainer: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '381px',
      },
      width: '100%',
    },
    hideOnMobile: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    createdAt: {
      display: 'none',
      color: 'var(--gray)',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    menuOptionsBtn: {
      padding: 0,
      minWidth: 0,
    },
    fab: {
      display: 'none',
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
    totalMonthContainer: {
      width: 290,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      border: '1px solid #C8C8C8',
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
        // maxWidth: '284px',
      },
    },
    totalMonthLabel: {
      fontSize: '20px',
      color: '#0000008A',
    },
    totalMonthValue: {
      fontSize: `${theme.spacing(3)}px`,
      color: '#000000DE',
    },
  }),
);

import React from 'react';

import { Container } from './styles';

interface CenterCardProps {
  maxWidth?: number;
  padding?: number;
}

const CenterCard: React.FC<CenterCardProps> = ({
  children,
  maxWidth,
  padding,
}) => (
  <Container padding={padding} maxWidth={maxWidth}>
    {children}
  </Container>
);

export default CenterCard;

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

import Input from '../../../../../components/Forms/Input';
import MaskedInput from '../../../../../components/Forms/MaskedInput';
import { PhoneMask } from '../../../../../utils/Masks/Phone';
import { CNPJMask } from '../../../../../utils/Masks/CNPJ';
import ChangeableAvatar from '../../../../../components/ChangeableAvatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerAvatar: {
      display: 'flex',
      justifyContent: 'center',
      padding: 24,
      position: 'relative',
    },
    avatarContainer: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: `${theme.spacing(2)}px`,
      paddingBottom: `${theme.spacing(2)}px`,
    },
    input: {
      maxWidth: 478,
    },
    titleForm: {
      color: '#212121',
      fontSize: 24,
      marginBottom: 8,
    },
    subtitleForm: {
      color: '#212121',
      fontSize: 16,
    },
    setAvatar: {
      opacity: '0%',
      position: 'absolute',
      width: '140px',
      height: '70px',
      bottom: '24px',
      zIndex: 2,
      borderRadius: '0 0 100px 100px',
      '&:hover': {
        background: '#212121',
        color: 'white',
        opacity: '58%',
      },
    },
    camera: {
      width: 37,
      background: '#fff',
      position: 'absolute',
      color: '#77DC56',
      bottom: '22px',
      right: '160px',
      boxShadow: '0px 3px 6px #00000029',
      zIndex: 3,
    },
  }),
);

interface FirstStepProps {
  handleBlur(field: string): void;
  setAvatarId(id: number): void;
  imageHash: string;
}

export default function FirstStep({
  handleBlur,
  setAvatarId,
  imageHash,
}: FirstStepProps) {
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.titleForm}>Sobre a instituição</h1>
      <span className={classes.subtitleForm}>
        Preencha abaixo as informações sobre a instituição.
      </span>
      <div className={classes.avatarContainer}>
        <ChangeableAvatar
          imageHash={imageHash}
          onChangeAvatar={(avatar) => {
            setAvatarId(avatar.id);
          }}
        />
      </div>
      <Input
        name="charity.name"
        label="Nome da Instituição"
        onBlur={() => handleBlur('charity.name')}
        className={classes.input}
      />
      <MaskedInput
        name="charity.cnpj"
        label="CNPJ"
        mask={CNPJMask}
        onBlur={() => handleBlur('store.cnpj')}
        className={classes.input}
      />
      <Input
        name="charity.site"
        label="Site"
        onBlur={() => handleBlur('charity.site')}
        className={classes.input}
      />
      <MaskedInput
        name="charity.phone"
        mask={PhoneMask}
        label="Telefone da instituição"
        onBlur={() => handleBlur('charity.phone')}
      />
      <Input
        name="charity.description"
        id="outlined-multiline-static"
        label="Descrição*"
        multiline
        rows={6}
        variant="outlined"
        onBlur={() => handleBlur('charity.description')}
        style={{ width: '100%' }}
        className={classes.input}
      />
    </>
  );
}

import { ptBR } from 'date-fns/locale';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { IconButton, InputAdornment } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import useStyles from './styles';
import { format } from 'date-fns';

interface YearMonthPickerProps {
  onSelectedDateChange?(date: Date): void;
  defaultSelectedDate?: Date | null;
  label?: string,
  datePickerClass?: any;
  maxDate?: Date;
  minDate?: Date;
  id?: string;
}

const YearMonthPicker: React.FC<YearMonthPickerProps> = ({
  defaultSelectedDate,
  label,
  maxDate,
  minDate,
  datePickerClass,
  onSelectedDateChange,
  id
}) => {
  const classes = useStyles();

  const datePickerInputRef = useRef<HTMLInputElement>();

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(defaultSelectedDate as Date | null);

  const resizeDatePickerInputFieldAccordingToValueLength = useCallback(() => {
    if (selectedDate && datePickerInputRef.current) {
      const formattedDateLength = format(selectedDate, 'MMMM yyyy', {
        locale: ptBR,
      }).length;
      datePickerInputRef.current.style.width = `calc((${formattedDateLength} * 1ch) + 8px)`;
    }
  }, [selectedDate]);

  useEffect(() => {
    resizeDatePickerInputFieldAccordingToValueLength();
  }, [resizeDatePickerInputFieldAccordingToValueLength]);

  useEffect(() => {
    const date = defaultSelectedDate || maxDate || null;
    setSelectedDate(date);
  }, [maxDate]);

  const handleOpenDatePicker = useCallback(() => {
    setDatePickerOpen(true);
  }, []);

  const handleCloseDatePicker = useCallback(() => {
    setDatePickerOpen(false);
  }, []);

  const handleDateChange = useCallback(
    (date: Date | null) => {
      setSelectedDate(date);
      if (date && onSelectedDateChange) {
        onSelectedDateChange(date);
      }
    },
    [onSelectedDateChange],
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <KeyboardDatePicker
        label={label}
        style={{ marginTop: 0 }}
        inputRef={datePickerInputRef}
        className={classes.datePicker}
        views={['year', 'month']}
        onChange={handleDateChange}
        onOpen={handleOpenDatePicker}
        onClose={handleCloseDatePicker}
        open={isDatePickerOpen}
        value={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        minDateMessage="Data nāo deve ser maior que a data minima."
        maxDateMessage="Data nāo deve ser menor que a data maxima."
        disableToolbar
        InputProps={{
          disableUnderline: true,
          className: datePickerClass || classes.datePickerInput,
          disabled: true,
          onClick: handleOpenDatePicker,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleOpenDatePicker}>
                <KeyboardArrowDown />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="dialog"
        format="MMMM yyyy"
        InputAdornmentProps={{ position: 'start' }}
        margin="normal"
        id={id ? id : "date-picker-inline"}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default YearMonthPicker;

import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';

import Input from '../../../../../components/Forms/Input';
import RadioInput from '../../../../../components/Forms/Radio';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      maxWidth: 478,
    },
    select: {
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(3),
      width: '100%',
    },
    titleForm: {
      color: '#212121',
      fontSize: 24,
      marginTop: 40,
      marginBottom: 8,
    },
    subtitleForm: {
      color: '#212121',
      fontSize: 16,
    },
  }),
);

export default function SecondStep({
  handleBlur,
  banks,
  bankValue,
  handleChangeBankValue,
}: any) {
  const classes = useStyles();
  const [accountType] = useState([
    {
      id: 'C',
      description: 'Conta Corrente',
    },
    {
      id: 'P',
      description: 'Conta Poupança',
    },
  ]);

  return (
    <>
      <h1 className={classes.titleForm}>Conta bancária</h1>
      <span className={classes.subtitleForm}>
        Informe os dados para deposito da doação
      </span>
      <FormControl
        variant="outlined"
        className={classes.select}
        onBlur={() => handleBlur('charity.bankAccount.bank')}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Selecione um banco
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="charity.bankAccount.bank"
          defaultValue={0}
          value={bankValue}
          onChange={handleChangeBankValue}
          label="Selecione um banco"
        >
          {banks?.map((v: any, i: number) => {
            return (
              <MenuItem
                value={i}
                key={v.id}
              >{`${v.code} ${v.description}`}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Input
        name="charity.bankAccount.agency"
        label="Agência"
        onBlur={() => handleBlur('charity.bankAccount.agency')}
        className={classes.input}
        type="number"
        placeholder="Agência com dígito"
      />
      <Input
        name="charity.bankAccount.account"
        label="Conta"
        onBlur={() => handleBlur('charity.bankAccount.account')}
        className={classes.input}
        type="number"
        placeholder="Conta com dígito"
      />

      <RadioInput
        defaultValue={accountType[0].id}
        name="charity.bankAccount.type"
        items={accountType}
      >
        <h3>Qual tipo de conta?</h3>
      </RadioInput>
    </>
  );
}

import {
  Menu,
  MenuItem,
  makeStyles,
  Theme,
  createStyles,
  ListItemText,
  ListItemIcon,
  Avatar,
} from '@material-ui/core';
import React, { useState, forwardRef, useCallback } from 'react';
import { MdArrowDropDown, MdPerson, MdExitToApp } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { MenuButton } from '../Admin/Header/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      padding: theme.spacing(2),
    },
    menuIcon: {
      minWidth: '0',
      marginRight: '15px',
    },
  }),
);

interface UserMenuProps {
  hideProfile?: boolean;
  hideMyAccount?: boolean;
}

const UserMenu: React.FC<UserMenuProps> = forwardRef(
  ({ hideMyAccount = false, hideProfile = false }, ref) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const history = useHistory();
    const { signOut, user, isUserStoreAdmin } = useAuth();

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleLogout = () => {
      setAnchorEl(null);
      signOut();
      history.push('/');
    };

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSeeStoreProfile = useCallback(() => {
      history.push('/painel-estabelecimento/perfil');
    }, [history]);

    return (
      <>
        {user && user.isActive && (
          <MenuButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Avatar alt={user.firstName} style={{ maxWidth: '40px' }} />
            <span className="username">Olá, {user.firstName}</span>
            <MdArrowDropDown size={24} />
          </MenuButton>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              marginTop: '45px',
              marginLeft: '15px',
              right: '0',
              width: '200px',
            },
          }}
        >
          {isUserStoreAdmin() && !hideProfile && (
            <MenuItem
              className={classes.menuItem}
              onClick={handleSeeStoreProfile}
            >
              <ListItemIcon className={classes.menuIcon}>
                <MdPerson size={24} />
              </ListItemIcon>
              <ListItemText primary="Perfil do conveniado" />
            </MenuItem>
          )}
          {/* {!hideMyAccount && (
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              <ListItemIcon className={classes.menuIcon}>
                <MdPerson size={24} />
              </ListItemIcon>
              <ListItemText primary="Minha Conta" />
            </MenuItem>
          )} */}
          <MenuItem className={classes.menuItem} onClick={handleLogout}>
            <ListItemIcon className={classes.menuIcon}>
              <MdExitToApp size={24} />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </MenuItem>
        </Menu>
      </>
    );
  },
);

export default UserMenu;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppBar } from '@material-ui/core';

import bubble from '../../assets/images/bubble-green.svg';
import coins from '../../assets/images/coins.svg';

export const Container = styled.div`
  min-height: calc(100vh - 64px);
  margin-top: 64px;
  background-image: url(${bubble}), url(${coins});
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: 60%, 40%;
  display: flex;

  @media (max-width: 600px) {
    background-size: 80%, 70%;
  }
`;

export const Header = styled(AppBar)`
  height: 64px;
  background: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0px 1px 3px #00000029;
`;

export const HeaderLogo = styled(Link)`
  display: block;
  text-decoration: none;
  margin: 0 auto;
  max-height: 25px;
  img {
    max-height: 25px;
  }
`;

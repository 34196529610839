import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, createStyles, makeStyles } from '@material-ui/core';
import { Form } from '@unform/web';

import EditProfileContainer from '../../../../components/EditProfileContainer';
import ActionButton from '../../../../components/Table/EmptyTable/ActionButton';
import { useLoading } from '../../../../contexts/loading';
import { useToast } from '../../../../contexts/toast';
import useFormValidation from '../../../../hooks/useFormValidation';
import {
  createCharity,
  getBanks,
  getCharityById,
  updateCharity,
} from '../../../../services/Charities';
import { searchAddressByZipCode } from '../../../../services/ZipCode';
import { parseMaskedPhone } from '../../../../utils/parseMaskedPhone';
import Yup from '../../../../validators/Yup';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';

const useStyles = makeStyles(() =>
  createStyles({
    containerActions: {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: 65,
    },
  }),
);

const schema = Yup.object().shape({
  charity: Yup.object().shape({
    cnpj: Yup.string().isCNPJ('CNPJ inválido').required('O CNPJ é obrigatório'),
    name: Yup.string().required('O nome é obrigatório'),
    site: Yup.string().required('O site é obrigatório'),
    phone: Yup.string().required('O telefone é obrigatório'),
    description: Yup.string().required('O campo é obrigatório'),
    bankAccount: Yup.object().shape({
      agency: Yup.string()
        .required('A agência é obrigatório')
        .matches(/^[0-9]+$/, 'A agência deve conter apenas números')
        .min(1, 'A agência deve ter no mínimo 1 número')
        .max(7, 'A agência deve ter no máximo 7 números'),
      account: Yup.string()
        .required('A conta é obrigatório')
        .matches(/^[0-9]+$/, 'A conta deve conter apenas números')
        .min(1, 'A conta deve ter no mínimo 1 número')
        .max(12, 'A conta deve ter no máximo 14 números'),
      type: Yup.string().nullable().required('O tipo é obrigatório'),
    }),
    address: Yup.object().shape({
      zipCode: Yup.string().required('O CEP é obrigatório'),
      city: Yup.string().required('O campo é obrigatório'),
      neighborhood: Yup.string().required('O campo é obrigatório'),
      address: Yup.string().required('O campo é obrigatório'),
      number: Yup.string().required('O campo é obrigatório'),
      state: Yup.object().shape({
        uf: Yup.string().required('O campo é obrigatório'),
      }),
    }),
  }),
});

export default function CreateCharity() {
  const classes = useStyles();
  const {
    validateField,
    formRef,
    validateForm,
    handleValidationErrors,
    handleBlur,
  } = useFormValidation(schema);
  const history = useHistory();
  const { setLoading } = useLoading();
  const { showError, showSuccess } = useToast();
  const [banks, setBanksValues] = useState<any>();
  const [avatarId, setAvatarId] = useState<any>();
  const [avatarHash, setAvatarHash] = useState<any>(null);
  const [bankValue, setBank] = useState(0);
  const params = useParams<{ id?: string }>();

  useEffect(() => {
    async function fetchBankValues() {
      setLoading(true);
      const banksValues = await getBanks();
      if (banksValues) {
        setLoading(false);
        setBanksValues(banksValues);
      }
    }
    fetchBankValues();
  }, [setLoading]);

  useEffect(() => {
    async function fetchById() {
      if (params.id) {
        const { data } = await getCharityById(+params.id);
        data.phone = `${data.phone.areaCode}${data.phone.number}`;
        setBank(data.bankAccount.bank.id - 1);
        if (data.photo) {
          setAvatarHash(data.photo.hash);
        }
        formRef.current?.setData({
          charity: data,
        });
      }
    }
    fetchById();
  }, [formRef, params.id]);

  const handleSubmit = async (charityData: any) => {
    try {
      setLoading(true);

      const data = {
        ...charityData.charity,
        bankAccount: {
          name: charityData.charity.name,
          type: charityData.charity.bankAccount.type,
          bank: banks[bankValue],
          agency: charityData.charity.bankAccount.agency,
          account: charityData.charity.bankAccount.account,
        },
        photo: {
          id: avatarId,
        },
        address: {
          zipCode: charityData.charity.address.zipCode.replace('-', ''),
          city: charityData.charity.address.city,
          address: charityData.charity.address.address,
          neighborhood: charityData.charity.address.neighborhood,
          number: charityData.charity.address.number,
          state: {
            uf: charityData.charity.address.state.uf,
          },
        },
        cnpj: charityData.charity.cnpj.replace(/[^\d]+/g, ''),
        phone: parseMaskedPhone(charityData.charity.phone),
      };

      await validateForm(charityData, {
        abortEarly: false,
      });

      if (params.id) {
        const updated = await updateCharity(+params.id, data);
        if (updated) {
          showSuccess('Instiuição atualizada com sucesso!');
          return history.push('/admin/instituicoes');
        }
      } else {
        const createdCharity = await createCharity(data);
        if (createdCharity) {
          showSuccess('Instiuição criado com sucesso!');
          return history.push('/admin/instituicoes');
        }
      }
    } catch (err) {
      handleValidationErrors(err);
      if (err.response) {
        return showError(err.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBlurCep = useCallback(
    async (field: string) => {
      const invalid = await validateField(field);
      if (!invalid) {
        const cep = formRef?.current?.getFieldValue('charity.address.zipCode');

        try {
          const data = await searchAddressByZipCode(cep);

          formRef.current?.setFieldValue('charity.address.city', data.city);
          validateField('charity.address.city');
          formRef.current?.setFieldValue(
            'charity.address.state.uf',
            data.state,
          );
          validateField('charity.address.state.uf');
          formRef.current?.setFieldValue(
            'charity.address.neighborhood',
            data.neighborhood,
          );
          formRef.current?.setFieldValue(
            'charity.address.address',
            data.street,
          );
          validateField('charity.address.neighborhood');
        } catch (err) {
          showError('CEP Inválido!');
        }
      }
    },
    [validateField, formRef, showError],
  );

  const handleChangBankValue = (
    event: React.ChangeEvent<{ value: number }>,
  ) => {
    setBank(event.target.value);
    handleBlur('charity.bankAccount.bank');
  };

  return (
    <EditProfileContainer
      title={!!params.id ? 'Editar instiuição' : 'Adicionar instiuição'}
    >
      <Form ref={formRef} onSubmit={handleSubmit} initialData={{}}>
        <FirstStep
          handleBlur={handleBlur}
          setAvatarId={setAvatarId}
          imageHash={avatarHash}
        />
        <SecondStep
          handleBlur={handleBlur}
          formRef={formRef}
          banks={banks}
          handleForm={handleSubmit}
          bankValue={bankValue}
          handleChangeBankValue={handleChangBankValue}
        />
        <ThirdStep
          handleBlur={handleBlur}
          formRef={formRef}
          handleForm={handleSubmit}
          handleBlurCep={handleBlurCep}
          style={classes}
        />
        <div className={classes.containerActions}>
          <Button
            variant="text"
            size="large"
            className={classes.cancelBtn}
            onClick={() => history.push('/admin/instituicoes')}
          >
            Cancelar
          </Button>
          <ActionButton type="submit">Salvar</ActionButton>
        </div>
      </Form>
    </EditProfileContainer>
  );
}

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '../services/api';
import { Store } from '../types/store';
import { Phone, StoreRolesEnum, User, UserStore } from '../types/user';

interface AuthState {
  token: string;
  user: User;
}

interface SignInResponse {
  data: AuthState;
}

interface GetMeResponse {
  data: User;
}

interface SignInCredentials {
  username: string;
  password: string;
}

export interface UpdateUserParams {
  email?: string;
  phone?: Phone;
}

interface AuthContextData {
  user: User;
  loading: boolean;
  signed: boolean;
  signIn(credentials: SignInCredentials): Promise<User>;
  signOut(): void;
  getMe(): Promise<User>;
  updateMe(data: UpdateUserParams): Promise<User>;
  selectUserStore(store: UserStore): void;
  isUserStoreAdmin(): boolean;
  updateSelectedStore(store: Store): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadStoredData(): Promise<void> {
      const token = localStorage.getItem('@Poppy:token');
      const user = localStorage.getItem('@Poppy:user');

      if (token && user) {
        api.defaults.headers.authorization = `Bearer ${token}`;
        setData({ token: token, user: JSON.parse(user) });
      }
      setLoading(false);
    }

    loadStoredData();
  }, []);

  useEffect(() => {
    if (data.user) {
      localStorage.setItem('@Poppy:user', JSON.stringify(data.user));
    }
  }, [data.user]);

  const signIn = useCallback(async ({ username, password }) => {
    try {
      const response = await api.post<SignInResponse>('authentication/login', {
        username,
        password,
      });

      const {
        data: { token, user },
      } = response.data;

      if (user.userStores.length === 1) {
        user.selectedUserStore = user.userStores[0];
      }

      localStorage.setItem('@Poppy:token', token);
      localStorage.setItem('@Poppy:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });
      return user;
    } catch (error) {
      throw error;
    }
  }, []);

  const getMe = useCallback(async (): Promise<User> => {
    const response = await api.get<GetMeResponse>('authentication/me');

    const { data: updatedUser } = response.data;

    setData({ ...data, user: updatedUser });

    return updatedUser;
  }, [data]);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@Poppy:token');
    localStorage.removeItem('@Poppy:user');
    setData({} as AuthState);
  }, []);

  const updateMe = useCallback(
    async (params: UpdateUserParams): Promise<User> => {
      const response = await api.put<{ data: User }>('user', params);

      const user = response.data.data;

      setData({ ...data, user });

      return user;
    },
    [data],
  );

  const selectUserStore = useCallback(
    (selectedUserStore: UserStore) => {
      setData({
        ...data,
        user: {
          ...data.user,
          selectedUserStore,
        },
      });
    },
    [data],
  );

  const updateSelectedStore = useCallback((store: Store) => {
    setData((prevData) => ({
      ...prevData,
      user: {
        ...prevData.user,
        selectedUserStore: {
          ...prevData.user.selectedUserStore,
          store,
        },
      },
    }));
  }, []);

  const isUserStoreAdmin = useCallback(
    () => data.user?.selectedUserStore?.role.id === StoreRolesEnum.ADMIN,
    [data.user],
  );

  return (
    <AuthContext.Provider
      value={{
        updateMe,
        signed: !!data.user,
        user: data.user,
        signIn,
        signOut,
        loading,
        getMe,
        selectUserStore,
        isUserStoreAdmin,
        updateSelectedStore,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };

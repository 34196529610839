import { ZipCodeResponse } from '../../types/zip-code';
import api from '../api';

export const searchAddressByZipCode = async (
  zipCode: string,
): Promise<ZipCodeResponse> => {
  const { data } = await api.get<ZipCodeResponse>(
    `/address/cep/${zipCode.replace('-', '')}`,
  );

  return data;
};

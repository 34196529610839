import { Store } from './store';

export interface Phone {
  areaCode: string;
  number: string;
}

export enum RolesEnum {
  USER = 1,
  ADMIN,
  CONTRIBUTOR,
  ADMIN_STORE,
}

export enum StoreRolesEnum {
  CONTRIBUTOR = RolesEnum.CONTRIBUTOR,
  ADMIN = RolesEnum.ADMIN_STORE,
}

export interface Role {
  id: number;
  name: string;
}

export interface UserStore {
  id: number;
  role: Role;
  store: Store;
  active: boolean;
  user: User;
}

export interface User {
  id: number;
  firstName: string;
  surname: string;
  email: string;
  phone: Phone;
  password: string;
  cpf: string;
  confirmationType: ConfirmationType;
  isActive: boolean;
  sawTutorial: boolean;
  createdAt: Date;
  roles: string[];
  stores: Store[];
  selectedUserStore: UserStore;
  userStores: UserStore[];
  isDisabled: boolean;
  isDeleted: boolean;
}

export enum ConfirmationType {
  PHONE = 't',
  EMAIL = 'e',
}

export enum FileTypeEnum {
  AVATAR_USER = '1',
  AVATAR_STORE = '2',
}

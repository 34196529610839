import clsx from 'clsx';
import React from 'react';

import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      minWidth: '173px',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      height: '50px',
      boxShadow: 'none',
    },
    hiddable: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);

interface ActionButtonProps extends ButtonProps {
  hiddable?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  className,
  children,
  hiddable = false,
  ...props
}) => {
  const classes = useButtonStyles();

  return (
    <Button
      className={clsx(classes.btn, className, hiddable ? classes.hiddable : {})}
      variant="contained"
      color="primary"
      {...props}
    >
      {children}
    </Button>
  );
};

export default ActionButton;

import React from 'react';
import { Phone } from '../../types/user';
import { PhoneMask } from '../../utils/Masks/Phone';
import { conformToMask } from 'react-text-mask';

interface PhoneValueProps {
  children: Phone;
}

const PhoneValue: React.FC<PhoneValueProps> = ({ children }) => (
  <>
    {
      conformToMask(children.areaCode + children.number, PhoneMask, {})
        .conformedValue
    }
  </>
);

export default PhoneValue;

import React from 'react';

import {
  createStyles,
  makeStyles,
  Paper,
  TableContainer,
  TableContainerProps,
  Theme,
  Typography,
} from '@material-ui/core';

import ActionButton from './ActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    title: {
      fontSize: '20px',
      fontWeight: 600,
      color: 'var(--menu-bg)',
      lineHeight: 1.7,
    },
    subtitle: {
      fontSize: `${theme.spacing(2)}px`,
      fontWeight: 500,
      color: 'var(--menu-bg)',
    },
    image: {
      marginBottom: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(4),
    },
  }),
);

export interface EmptyTableProps extends TableContainerProps {
  imageSrc: string;
  title: string;
  actionText?: string;
  onActionClick(): void;
  subtitle?: string;
}

const EmptyTable: React.FC<EmptyTableProps> = ({
  imageSrc,
  title,
  actionText,
  onActionClick,
  subtitle,
  ...props
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} {...props} className={classes.container}>
      {!!imageSrc && (
        <img src={imageSrc} alt="Imagem" className={classes.image} />
      )}
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h2" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
      {actionText && (
        <ActionButton className={classes.button} onClick={onActionClick}>
          {actionText}
        </ActionButton>
      )}
    </TableContainer>
  );
};

export default EmptyTable;

import React from 'react';

import logo from '../../assets/images/logo.png';
import { MdArrowBack } from 'react-icons/md';
import { Header, HeaderLogo, Container } from './styles';
import { Button } from '@material-ui/core';
import { useSignUpStore } from '../../contexts/store-signup';
import { useHistory, useRouteMatch } from 'react-router-dom';

const SignUpStoreLayout: React.FC = ({ children }) => {
  const { signUpToken } = useSignUpStore();
  const history = useHistory();
  const route = useRouteMatch();

  const handleClick = () => {
    history.push(`/registrar-parceiro/${signUpToken}`);
  };

  return (
    <div>
      <Header>
        {route.url === '/registrar-usuario' && (
          <Button onClick={() => handleClick()}>
            <MdArrowBack size={24} color="#A7A7A7" />
          </Button>
        )}
        <HeaderLogo to="/">
          <img src={logo} alt="poppy logo" />
        </HeaderLogo>
      </Header>
      <Container>{children}</Container>
    </div>
  );
};

export default SignUpStoreLayout;

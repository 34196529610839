import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none',
      borderRadius: '5px',
      width: '557px',
    },
    header: {
      display: 'flex',
      borderBottom: '1px solid #00000020',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    modalTitle: {
      padding: theme.spacing(1),
    },
    workAround: {
      color: theme.palette.background.paper,
    },
    content: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(2),
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: theme.spacing(3),
      marginTop: theme.spacing(7),
    },
    button: {
      width: '140px',
      height: '40px',
      marginRight: '8px',
    },
    rolesLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    rolesLabelDescription: {
      color: '#0000008A',
    },
    rolesGroupLabel: {
      marginBlockStart: 0,
    },
  }),
);

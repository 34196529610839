import React, { forwardRef, useState } from 'react';

import {
  IconButton,
  InputAdornment,
  TextFieldProps,
  useTheme,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '../Input';
import { PasswordStrengthContainer, PasswordStrengthItem } from './styles';
import { MdCheckCircle, MdRadioButtonUnchecked } from 'react-icons/md';

type PasswordInputProps = TextFieldProps & {
  label?: string;
  showError?: boolean;
  valid?: any;
  showPasswordStrength?: boolean;
};

const validations: { message: string; key: 'min' | 'upper' }[] = [
  { message: 'Tem no mínimo de 8 caracteres', key: 'min' },
  { message: 'Tem no mínimo 1 letra maiúscula', key: 'upper' },
];

const PasswordInput: React.ForwardRefRenderFunction<any, PasswordInputProps> = (
  { label, showError = true, showPasswordStrength = false, valid, ...rest },
  ref,
) => {
  const [showPassword, setShowPassword] = useState(false);

  const { palette } = useTheme();

  return (
    <div>
      <Input
        InputProps={{
          ...rest.InputProps,
          onChange: rest.onChange,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ ...rest.InputLabelProps }}
        label={label}
        name="password"
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        {...rest}
      />
      {showPasswordStrength && (
        <PasswordStrengthContainer>
          {validations.map((validation) => (
            <PasswordStrengthItem key={validation.key}>
              {valid[validation.key] ? (
                <MdCheckCircle
                  size={24}
                  color={palette.primary.main}
                  style={{ marginRight: '8px' }}
                />
              ) : (
                <MdRadioButtonUnchecked
                  size={24}
                  color={palette.primary.main}
                  style={{ marginRight: '8px' }}
                />
              )}
              <span>{validation.message}</span>
            </PasswordStrengthItem>
          ))}
        </PasswordStrengthContainer>
      )}
    </div>
  );
};

export default forwardRef(PasswordInput);

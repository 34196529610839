import React from 'react';

import Header from '../../components/Header';
import { CardContainer, Container } from './styles';

const ForgotPasswordLayout: React.FC = ({ children }) => {
  return (
    <div>
      <Header></Header>
      <Container className="w-100 h-100">
        <CardContainer>{children}</CardContainer>
      </Container>
    </div>
  );
};

export default ForgotPasswordLayout;

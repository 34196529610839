import React, { useCallback, useEffect, useState } from 'react';

import DialogContainer from '../../../../../components/DialogContainer';
import { useAuth } from '../../../../../contexts/Auth';
import { useToast } from '../../../../../contexts/toast';
import { updateStore } from '../../../../../services/Store';
import Map from '../../EditableFields/Location/Map';
import { Address } from '../../../../../types/common';

interface LocationMapDialogProps {
  open: boolean;
  onCancel(): void;
  onConfirm(data: any): void;
}

function LocationMapDialog({
  onConfirm,
  onCancel,
  ...rest
}: LocationMapDialogProps) {
  const {
    user: {
      selectedUserStore: { store },
    },
    updateSelectedStore,
  } = useAuth();

  const point = {
    lat: store.address.latitude ? parseFloat(store.address.latitude) : 0,
    lng: store.address.longitude ? parseFloat(store.address.longitude) : 0,
  };

  const { showError, showSuccess } = useToast();

  const [updating, setUpdating] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(point);

  const handleSubmit = useCallback(async () => {
    try {
      setUpdating(true);
      setDirty(false);

      const data = {
        address: {
          latitude: updatedValue.lat.toString(),
          longitude: updatedValue.lng.toString(),
        } as Address,
      };
      const updatedStore = await updateStore(store.id, data);

      updateSelectedStore(updatedStore);

      onConfirm(data);
      showSuccess('Localização alterada com sucesso!');
    } catch (err) {
      setDirty(true);
      if (err.response) {
        showError(err.response.data.message);
      }
    } finally {
      setUpdating(false);
    }
  }, [
    onConfirm,
    showError,
    showSuccess,
    store.id,
    updateSelectedStore,
    updatedValue.lat,
    updatedValue.lng,
  ]);

  useEffect(() => {
    setDirty(point.lat !== updatedValue.lat || point.lng !== updatedValue.lng);
  }, [point.lat, point.lng, updatedValue]);

  const handleChangeLocation = useCallback((newPoint) => {
    setUpdatedValue(newPoint);
  }, []);

  return (
    <DialogContainer
      {...rest}
      onCancel={() => {
        setUpdatedValue(point);
        onCancel();
      }}
      onConfirm={handleSubmit}
      disabledConfirmBtn={!dirty}
      isLoading={updating}
      title="Alterar localização"
    >
      <Map onChangeLocation={handleChangeLocation} point={updatedValue} />
    </DialogContainer>
  );
}

export default LocationMapDialog;

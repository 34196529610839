import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '284px',
      },
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    datePickerInput: {
      fontSize: `${theme.spacing(3)}px`,
      fontWeight: 'bold',
      '& input': {
        textTransform: 'capitalize',
        textAlign: 'center',
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: '#212121',
      },
      '& .MuiInputAdornment-root .MuiSvgIcon-root': {
        color: '#212121',
        fontSize: `${theme.spacing(4)}px`,
      },
      '& .MuiInputAdornment-root .MuiIconButton-root': {
        padding: 0,
      },
    },
  }),
);

import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import AdminLayout from '../layouts/AdminLayout';
import ClientTransactions from '../pages/Store/ClientTransactions';
import Profile from '../pages/Store/Profile';
import { StoreRolesEnum } from '../types/user';
import { SelectedStoreRouteWrapper } from './wrappers/SelectedStoreRouteWrapper';

const Associates = lazy(() => import('../pages/Store/Associates'));
const StoreTransactions = lazy(
  () => import('../pages/Store/StoreTransactions'),
);
const EditAssociateProfile = lazy(
  () => import('../pages/Store/Associates/EditAssociateProfile'),
);

const AssociateProfile = lazy(
  () => import('../pages/Store/Associates/AssociateProfile'),
);

const StoreRoutes: React.FC<{ match: { url: string } }> = ({
  match: { url },
}) => {
  return (
    <Switch>
      <SelectedStoreRouteWrapper
        path={`${url}`}
        exact
        layout={AdminLayout}
        page={StoreTransactions}
        roles={[StoreRolesEnum.ADMIN, StoreRolesEnum.CONTRIBUTOR]}
      />
      <SelectedStoreRouteWrapper
        path={`${url}/perfil`}
        exact
        layout={AdminLayout}
        page={Profile}
        roles={[StoreRolesEnum.ADMIN]}
      />
      <SelectedStoreRouteWrapper
        path={`${url}/transacoes`}
        exact
        layout={AdminLayout}
        page={StoreTransactions}
        roles={[StoreRolesEnum.ADMIN, StoreRolesEnum.CONTRIBUTOR]}
      />
      <SelectedStoreRouteWrapper
        path={`${url}/transacoes/cliente/:cpf`}
        exact
        layout={AdminLayout}
        page={ClientTransactions}
        roles={[StoreRolesEnum.ADMIN, StoreRolesEnum.CONTRIBUTOR]}
      />
      <SelectedStoreRouteWrapper
        exact
        path={`${url}/colaboradores`}
        layout={AdminLayout}
        page={Associates}
        roles={[StoreRolesEnum.ADMIN]}
      />
      <SelectedStoreRouteWrapper
        exact
        path={`${url}/colaboradores/:userId`}
        layout={AdminLayout}
        page={AssociateProfile}
        roles={[StoreRolesEnum.ADMIN]}
      />
      <SelectedStoreRouteWrapper
        path={`${url}/colaboradores/:userId/editar`}
        layout={AdminLayout}
        page={EditAssociateProfile}
        roles={[StoreRolesEnum.ADMIN]}
      />
    </Switch>
  );
};

export default StoreRoutes;

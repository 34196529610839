import React from 'react';
import * as cpf from '@fnando/cpf';

interface CpfValueProps {
  children: string;
}

const CpfValue: React.FC<CpfValueProps> = ({ children }) => (
  <>{cpf.format(children)}</>
);

export default CpfValue;

import React from 'react';
import { MdErrorOutline } from 'react-icons/md';

import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';

import { useConfirmDialog } from '../../contexts/confirm-dialog';
import DialogContainer from '../DialogContainer';

export interface IModalOptions {
  title: string;
  confirmTitle: string;
  confirmDescription: string;
  variant: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.down('md')]: {
        '& .MuiDialog-paper': {
          margin: '8px',
        },
      },
    },
    dialogContainer: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    btn: {
      width: '140px',
      height: '40px',
    },
    title: {
      fontSize: '1.5rem',
    },
    iconContainer: {
      textAlign: 'center',
    },
    icon: {
      color: 'var(--danger)',
    },
    btnDanger: {
      color: 'var(--white)',
      background: 'var(--danger)',
      '&:hover': {
        background: 'var(--danger)',
        opacity: '0.9',
      },
    },
    btnGrayText: {
      color: 'var(--gray)',
    },
    confirmTitle: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textJustify: 'auto',
      marginBlockStart: 0,
    },
    confirmDescription: {
      fontSize: '1rem',
      textJustify: 'auto',
    },
  }),
);

export const ConfirmationDialog: React.FC<{
  open: boolean;
  modalOptions: IModalOptions;
}> = ({ open, modalOptions }) => {
  const classes = useStyles();
  const { handleClose } = useConfirmDialog();

  const handleAction = (val: boolean) => {
    handleClose(val);
  };

  return (
    <DialogContainer
      title={modalOptions.title}
      open={open}
      confirmBtnText="Excluir"
      confirmBtnLoadingText="Excluindo..."
      conformBtnClassName={classes.btnDanger}
      onCancel={() => handleAction(false)}
      onConfirm={() => handleAction(true)}
    >
      <Grid container justify="center" alignItems="center">
        <Grid xs={12} md={3} lg={3} className={classes.iconContainer}>
          <MdErrorOutline size={60} className={classes.icon} />
        </Grid>
        <Grid xs={12} md={9} lg={9}>
          <div className={classes.dialogContainer}>
            <p className={classes.confirmTitle}>{modalOptions.confirmTitle}</p>
            <p className={classes.confirmDescription}>
              {modalOptions.confirmDescription}
            </p>
          </div>
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Transaction } from '../types/transactions';

export const transactionsWithFormattedDate = (transactions: Transaction[]) =>
  transactions.map((transaction: Transaction) => ({
    ...transaction,
    formattedCreatedAt: format(new Date(transaction.createdAt), 'd MMMM yyyy', {
      locale: ptBR,
    }),
  }));

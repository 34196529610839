import './styles/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import 'moment/locale/pt-br';

// Sets the moment instance to use.
Moment.globalMoment = moment;
// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'pt-br';
// Set the timezone for every instance.
Moment.globalTimezone = 'America/Sao_Paulo';
// Set the output timezone for local for every instance.
Moment.globalLocal = true;

ReactDOM.render(<App />, document.getElementById('root'));
